import { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { Container, Dialog } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
});

class LoadingDialog extends Component {
  render() {
    const { classes } = this.props
    return (
      <Dialog
        open={this.props.processing !== null && this.props.processing !== undefined}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <Container className={ classes.root }>
          <ClipLoader />
          {this.props.processing &&
            <FormattedMessage id={this.props.processing} />}
        </Container>
      </Dialog>
    )
  }
}

export default withStyles(styles)(LoadingDialog)
