import { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import ClipLoader from "react-spinners/ClipLoader";

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(12),
  },
  body: {
    fontSize: '1.5em',
  },
});

class Loading extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root} data-testid='loading'>
        <ClipLoader />
        <div className={classes.body}>
          <FormattedMessage id='loading'/>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Loading)
