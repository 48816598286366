import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import humanize from 'humanize';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { DataGrid } from '@material-ui/data-grid';
import ClipLoader from "react-spinners/ClipLoader";
import NewToken from './NewToken';

const styles = (theme) => ({
  container: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'sans-serif',
    '> p': {
      fontSize: '1rem',
    },
    '> em': {
      fontSize: '.8rem',
    },
  },
  tool: {
    display: 'flex',
    padding: '0.5em',
  },
  deleteButton: {
    marginLeft: 'auto',
  },
});

class Tokens extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false,
      lastSelectedRowId: null,
      deleteConfirm: false,
    };
  }

  getDataGrid() {
    const { intl } = this.props;
    const rows = (this.props.tokens || []).map((param, index) => ({
      id: index,
      name: param.data.name,
      expiresAt: param.data.expiresAt === null ? intl.formatMessage({ id: 'rowNoTokenExpiration' }) : humanize.naturalDay(new Date(param.data.expiresAt)),
    }));
    const columns = [
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'columnTokenname' }),
        width: 300,
        editable: false,
      },
      {
        field: 'expiresAt',
        headerName: intl.formatMessage({ id: 'columnTokenexpires' }),
        editable: false,
        flex: 1,
      },
    ];
    return { rows, columns };
  }

  delete(items) {
    const { onTokenDelete, tokens } = this.props;
    if (!onTokenDelete) {
      return;
    }
    onTokenDelete(
      items.map((item) => tokens[item].id),
      () => {
        this.setState({ deleteConfirm: false });
      },
    );
  }

  selectionChange(newSelection) {
    this.setState({
      selectedItems: newSelection.selectionModel,
    });
  }

  renderToolButtons() {
    const { classes } = this.props;
    return <Container className={classes.tool}>
      {this.state.loading && <ClipLoader />}
      {this.props.editable && <>
          <Button
            className={classes.deleteButton}
            variant="contained"
            color="secondary"
            disabled={(this.state.selectedItems || []).length === 0}
            onClick={() => this.setState({ deleteConfirm: true })}
          >
            <DeleteIcon />
            <FormattedMessage id='deleteToken' />
          </Button>
        </>}
    </Container>
  }

  render() {
    const { classes } = this.props;
    const { rows, columns } = this.getDataGrid();
    const panelHeightPx = 200;
    return (
      <Container className={classes.root}>
        <NewToken
          onError={this.props.onError}
          onCreate={this.props.onTokenCreate}
        />
        {this.renderToolButtons()}
        <div style={{ height: `${panelHeightPx}px`, width: '100%' }}>
          <DataGrid
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => this.selectionChange(newSelection)}
            rows={rows}
            columns={columns}
          />
        </div>
        <Dialog
          fullWidth
          disableBackdropClick
          open={this.state.deleteConfirm}
          onClose={() => this.setState({ deleteConfirm: false })}
        >
          <DialogTitle>
            <FormattedMessage id='tokenDeleteConfirmTitle' />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id='tokenDeleteConfirmMessage' values={{
                tokenNames: (this.state.selectedItems || [])
                  .map((item) => ((this.props.tokens[item] || {}).data || {}).name).join(', '),
              }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => this.setState({ deleteConfirm: false })}
            >
              <FormattedMessage id='tokenDeleteConfirmCancel' />
            </Button>
            <Button
              color="primary"
              onClick={() => this.delete(this.state.selectedItems)}
            >
              <FormattedMessage id='tokenDeleteConfirmOK' />
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  }
}

export default withStyles(styles)(injectIntl(Tokens))
