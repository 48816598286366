import { Component } from "react";
import { injectIntl } from "react-intl";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import axios from "axios";

import Dashboard from "./components/Dashboard";
import TaskPanel from "./components/TaskPanel";
import Agreement from "./components/Agreement";
import Settings from "./components/Settings";
import TalkgroupSettings from "./components/TalkgroupSettings";
import Footer from "./components/Footer";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(12),
  },
});

async function copyFiles(sourceRef, destRef, resourceTaskId, currentTime) {
  const sourceFilesRef = sourceRef.collection('file');
  const destFilesRef = destRef.collection('file');
  const sourceFilesListRef = await sourceFilesRef.get();
  const destFilesListRef = await destFilesRef.get();
  const sourceIds = sourceFilesListRef.docs
    .filter((ref) => !ref.data().deleted && !ref.data().noContent)
    .map((ref) => ref.id);
  const destIds = destFilesListRef.docs.map((ref) => ref.id);
  const removedIds = destIds.filter((id) => !sourceIds.includes(id));

  await Promise.all(removedIds.map((id) => destFilesRef.doc(id).update({
    deleted: true,
  })));
  await Promise.all(sourceFilesListRef.docs
    .filter((ref) => !ref.data().deleted && !ref.data().noContent)
    .map((sourceFileRef) => destFilesRef.doc(sourceFileRef.id)
      .set(Object.assign(
        Object.assign({ resourceTaskId }, sourceFileRef.data()),
        { updated: currentTime.getTime() }
      ))
    )
  );
}

export async function createTask(user, firestore, intl, template) {
  const newData = template ? Object.assign({}, template.data) : {};
  const newTitle = intl.newTitle || intl.formatMessage({ id: 'newTitle' });
  const newTitlePrefix = intl.newTitlePrefix || intl.formatMessage({ id: 'newTitlePrefix' });
  newData.title = newData.title ? `${newTitlePrefix} ${newData.title}` : newTitle;
  newData.updated = Date.now();
  newData.taskId = null;
  newData.readyToDeploy = newData.updated;
  newData.trash = false;
  newData.public = false;
  newData.importable = false;
  newData.distributable = false;
  newData.creatorLogType = 'pseudonymized';
  newData.authorLogType = 'none';
  newData.creatorId = null;
  newData.shareId = null;
  newData.shares = [];
  newData.sharesWithLink = false;
  const added = await firestore
    .collection('user')
    .doc(user.uid)
    .collection('task')
    .add(newData);
  if (template && !(template.data || {}).base) {
    await copyFiles(
      firestore.collection('task').doc(template.id),
      firestore.collection('user').doc(user.uid).collection('task').doc(added.id),
      template.id,
      new Date(),
    );
  }
  const r = await axios.put(`/api/v1/users/${user.uid}/tasks/${added.id}`);
  return r.data;
}

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: null,
    }
  }

  showError(message, error) {
    if (!this.props.onError) {
      return;
    }
    this.props.onError(message, error);
  }

  addTask(template) {
    const { user, firestore, intl } = this.props;
    this.setState({
      processing: 'processingNewTask',
    }, () => {
      createTask(user, firestore, intl, template)
        .then((task) => {
          window.location.href = `/t/${task.taskId}`;
        }).catch((error) => {
          this.setState({
            processing: null,
          }, () => {
            this.showError('errorAddTask', error)
          });
        });
    });
  }

  taskLoaded(options) {
    if (!this.props.onTaskLoad) {
      return;
    }
    this.props.onTaskLoad(options);
  }

  dashboardLoaded(options) {
    if (!this.props.onDashboardLoad) {
      return;
    }
    this.props.onDashboardLoad(options);
  }

  permissionChanged() {
    if (!this.props.onPermissionChange) {
      return;
    }
    this.props.onPermissionChange();
  }

  renderTaskPanel() {
    return <TaskPanel
      user={this.props.user}
      permission={this.props.permission}
      firestore={this.props.firestore}
      storage={this.props.storage}
      processing={this.props.processing || this.state.processing}
      onTaskLoad={(options) => this.taskLoaded(options)}
      onError={(message, error) => this.showError(message, error)}
      onTitleSet={this.props.onTitleSet}
      onPermissionChange={() => this.permissionChanged()}
      onAppMenuHide={this.props.onAppMenuHide}
      onAppMenuShow={this.props.onAppMenuShow}
      onReSignIn={this.props.onReSignIn}
    />
  }

  renderAgreement() {
    return <Agreement
      user={this.props.user}
      firestore={this.props.firestore}
      storage={this.props.storage}
      onError={(message, error) => this.showError(message, error)}
      onAppMenuHide={this.props.onAppMenuHide}
      onAppMenuShow={this.props.onAppMenuShow}
    />
  }

  renderTalkgroup() {
    return <TalkgroupSettings
      user={this.props.user}
      firestore={this.props.firestore}
      onError={(message, error) => this.showError(message, error)}
    />
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Dashboard
              user={this.props.user}
              firestore={this.props.firestore}
              processing={this.props.processing || this.state.processing}
              onDashboardLoad={(options) => this.dashboardLoaded(options)}
              onTaskAdd={(template) => this.addTask(template)}
              onError={(message, error) => this.showError(message, error)}
            />
            <Footer />
          </Route>
          <Route exact path="/settings">
            <Settings
              user={this.props.user}
              firestore={this.props.firestore}
              onError={(message, error) => this.showError(message, error)}
            />
            <Footer />
          </Route>
          <Route exact path="/t/:taskId">
            {this.renderTaskPanel()}
          </Route>
          <Route exact path="/agreement/:taskId">
            {this.renderAgreement()}
          </Route>
          <Route exact path="/s/:shareId">
            {this.renderTaskPanel()}
          </Route>
          <Route exact path="/talkgroup/:talkgroupId">
            {this.renderTalkgroup()}
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default withStyles(styles)(injectIntl(Main));
