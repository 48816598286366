import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard';


const styles = (theme) => ({
  info: {
    textAlign: 'center',
  },
  description: {
    color: '#000',
  },
  studyURL: {
    color: '#000',
  },
  studyURLContent: {
    fontSize: '1em',
    color: '#888',
  },
  studyURLCopied: {
    color: '#000',
    fontSize: '1em',
  },
});


class AccessPanelBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frCopied: false,
    };
  }

  render() {
    const { intl, url, classes } = this.props;
    return (
      <div>
        <p className={classes.description}>
          {intl.formatMessage({ id: 'healthAccessAwareDescription' })}
        </p>
        <div className={classes.info}>
          <QRCode value={url} />
        </div>
        <div className={classes.studyURL}>
          {intl.formatMessage({ id: 'healthAccessAwareStudyURL' })}
          <Typography variant="caption" component="caption" className={classes.studyURLContent}>
            {url}
            <CopyToClipboard
              text={url}
              onCopy={() => this.setState({ frCopied: true })}
            >
              <FileCopyIcon />
            </CopyToClipboard>
          </Typography>
        </div>
        {this.state.frCopied && <div className={classes.studyURLCopied}>
            {intl.formatMessage({ id: 'healthAccessAwareCopied' })}
          </div>}
      </div>
    );
  }
}

const AccessPanel = withStyles(styles)(AccessPanelBase);

export class AwareHealthContext {
  constructor(firestore, user, taskId) {
    this.props = { firestore, user, taskId };
    if (!taskId) {
      throw new Error('taskId missing');
    }
  }

  showAccess(intl, awareSinkRef) {
    const { id } = awareSinkRef;
    const { secret } = awareSinkRef.data();
    const url = `${window.location.origin}/health/aware/${id}/${secret}`;
    return (
      <AccessPanel intl={intl} url={url} />
    );
  }

  async getHealthuserId() {
    const { user, firestore, taskId } = this.props;
    const oldAwaresinkRef = await firestore
      .collection('awaresink')
      .where('userId', '==', user.uid)
      .where('taskId', '==', taskId)
      .get();
    if (!oldAwaresinkRef.empty) {
      const healthuserId = `aware:${oldAwaresinkRef.docs[0].id}`;
      return {
        healthuserId,
        showAccess: (intl) => this.showAccess(intl, oldAwaresinkRef.docs[0]),
      };
    }
    const awaresinkRef = await firestore
      .collection('awaresink')
      .add({
        userId: user.uid,
        taskId,
        secret: uuidv4(),
      });
    const healthuserId = `aware:${awaresinkRef.id}`;
    return {
      healthuserId,
      showAccess: (intl) => this.showAccess(intl, awaresinkRef),
    };
  }

  async getLastData(healthuserId, query) {
    const { firestore } = this.props;
    const { target } = query;
    this.validateOptions(target);
    const awareId = healthuserId.match(/aware:(.+)/)[1];
    const since = Date.now() - 24 * 60 * 60 * 1000; // since 24 hours ago
    const awaresinkRef = await firestore
      .collection('awaresink')
      .doc(awareId)
      .collection('data')
      .where('category', '==', target)
      .where('pushed', '>=', since)
      .orderBy('pushed', 'desc')
      .get();
    if (awaresinkRef.empty) {
      return [];
    }
    return awaresinkRef.docs.map((r) => JSON.parse(r.data().data));
  }

  validateAwareSensorSetting(awareSensorSetting) {
    const { setting } = awareSensorSetting;
    if (typeof setting !== 'string') {
      throw new Error(`setting must be a string: ${JSON.stringify(awareSensorSetting)}`);
    }
    const { value } = awareSensorSetting;
    if (typeof value !== 'string') {
      throw new Error(`value must be a string: ${JSON.stringify(awareSensorSetting)}`);
    }
    if (value !== 'true' && value !== 'false') {
      throw new Error(`value must be either 'true' or 'false': ${JSON.stringify(awareSensorSetting)}`);
    }
    return awareSensorSetting;
  }

  validateAwareSensor(awareSensor) {
    if (typeof awareSensor === 'string') {
      return {
        setting: `status_${awareSensor}`,
        value: 'true'
      };
    }
    const { setting, value } = awareSensor;
    if (typeof setting !== 'string') {
      throw new Error(`setting must be a string: ${JSON.stringify(awareSensor)}`);
    }
    if (value === undefined) {
      throw new Error(`value missing: ${JSON.stringify(awareSensor)}`);
    }
    return {
      setting,
      value,
    };
  }

  validateAwareSensors(awareSensors) {
    if (!Array.isArray(awareSensors)) {
      throw new Error('awareSensors must be an array');
    }
    return awareSensors.map((awareSensor) => this.validateAwareSensor(awareSensor));
  }

  validateTarget(target) {
    if (!target) {
      throw new Error('target missing');
    }
    if (Array.isArray(target)) {
      if (!target.every((t) => typeof t === 'string')) {
        throw new Error(`target must be a string or an array of strings: ${target}`);
      }
      return;
    }
    if (typeof target !== 'string') {
      throw new Error(`target must be a string or an array of strings: ${target}`);
    }
  }

  validateOptions(options) {
    const { awareSensors, target } = options;
    this.validateTarget(target);
    let modifiedAwareSensors = undefined;
    if (awareSensors !== undefined) {
      modifiedAwareSensors = this.validateAwareSensors(awareSensors);
    }
    const optional = {};
    if (modifiedAwareSensors !== undefined) {
      optional.awareSensors = modifiedAwareSensors;
    }
    return Object.assign(options, optional);
  }
}