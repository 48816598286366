import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import queryString from 'query-string';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GoogleButton from 'react-google-button';
import ImageGallery from 'react-image-gallery';
import ClipLoader from "react-spinners/ClipLoader";

const styles = {
  header: {
    padding: '2em',
    backgroundColor: '#fff',
    marginTop: '5em',
    textAlign: 'left',
  },
  titleContainer: {
    padding: 0,
    display: 'flex',
  },
  avatar: {
    width: '2.8em',
    height: '2.8em',
    marginRight: 8,
  },
  title: {
    fontSize: '3em',
    fontWeight: 'bold',
  },
  login: {
    fontSize: '1.2em',
    padding: '2em',
  },
  loginButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2em',
    marginBottom: '2em',
  },
  notice: {
    paddingTop: '1em',
  },
}

const images = [
  {
    original: '/cog-pds/slide1.png',
    thumbnail: '/cog-pds/slide1.png',
  },
  {
    original: '/cog-pds/slide2.png',
    thumbnail: '/cog-pds/slide2.png',
  },
];


class AnonymousPage extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(window.location.search);
    this.state = {
      slideIndex: 0,
      talkuser: query['talkuser'],
      customToken: query['_ct'],
    };
  }

  signIn() {
    const auth = this.props.auth || firebase.auth;
    const provider = new auth.GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    if (!this.props.onSignIn) {
      return
    }
    this.props.onSignIn(provider)
  }

  render() {
    const { classes } = this.props;
    const { talkuser, customToken } = this.state;
    return (
      <Paper elevation={0} className={classes.header}>
        <Grid container>
          <Grid item sm={6} className={classes.login}>
            <Container className={classes.titleContainer}>
              <Avatar
                alt='GO-E-MON'
                src='/images/logo512.png'
                variant='square'
                className={classes.avatar}
              />
              <Typography variant="h1" component="h1" className={classes.title}>
                <FormattedMessage id={`greetingHeader${this.state.slideIndex || 0}`} />
              </Typography>
            </Container>
            <p>
              <FormattedMessage id={`greetingMessage${this.state.slideIndex || 0}`} />
            </p>
            <p>
              {!talkuser && <FormattedMessage id='loginDescription' />}
              {talkuser && <strong><FormattedMessage id='talkuserLoginDescription' /></strong>}
            </p>
            {customToken && <ClipLoader />}
            {!customToken && <GoogleButton
                className={classes.loginButton}
                onClick={() => this.signIn()}
              />}
            {!talkuser && <div className={classes.notice}>
                <FormattedMessage id='loginNotice' />
                <a href='https://scrapbox.io/cogtask-me/Terms_of_Use' target='_blank' rel='noreferrer'>
                  <FormattedMessage id='agreement' />
                </a>
              </div>}
          </Grid>
          <Grid item sm={6}>
            <ImageGallery
              items={images}
              showNav={false}
              showPlayButton={false}
              useBrowserFullscreen={false}
              showBullets={true}
              showThumbnails={false}
              onSlide={(currentIndex) => this.setState({ slideIndex: currentIndex })}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(AnonymousPage)
