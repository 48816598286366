import { Component } from "react";
import { withRouter } from "react-router";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';

import LoadingDialog from "./LoadingDialog";

import TaskCardList from "./TaskCardList";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(16),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabBar: {
    top: theme.spacing(7.5),
  },
  description: {
    padding: theme.spacing(2),
  },
  tasklist: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  taskheader: {
    fontSize: "2em",
  },
  task: {
    fontSize: "1.2em",
  },
  taskitem: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  addTasks: {
    padding: theme.spacing(2),
  },
  publicTasksOrder: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
});

const MAX_PUBLIC_TASKS = 50;
const DEFAULT_RECENT_TASKS = 4;
const DEFAULT_SHARED_TASKS = 4;
const EXPAND_RECENT_TASKS = DEFAULT_RECENT_TASKS * 3;
const EXPAND_SHARED_TASKS = DEFAULT_SHARED_TASKS * 3;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      template: null,
      selectTemplate: false,
      tasks: null,
      publicTasks: null,
      recentTasks: null,
      hasMoreRecentTasks: true,
      sharedTasks: null,
      hasMoreSharedTasks: true,
      moreTasks: false,
    };
  }

  componentDidMount() {
    this.ensureTemplatesLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureTemplatesLoaded();
  }

  ensureTemplatesLoaded() {
    if (this.state.templates !== undefined) {
      return;
    }
    if (!this.props.firestore || !this.props.user) {
      return;
    }
    setTimeout(() => {
      this.setState({
        templates: null,
      }, () => {
        this.loadResources(() => {
          this.dashboardLoaded({});
        })
      })
    }, 10);
  }

  loadResources(callback) {
    this.performLoadResources()
      .then((res) => {
        const { templates, tasks } = res;
        const defaultTemplates = templates.filter((template) => template.data.default);
        const template = defaultTemplates.length > 0 ? defaultTemplates[0].id : null;
        this.setState({ templates, template, tasks }, () => {
          this.loadRecentTasks(callback);
        });
      }).catch((error) => {
        this.showError('errorLoadTemplates', error)
      });
  }

  loadRecentTasks(callback) {
    this.performLoadRecentTasks(DEFAULT_RECENT_TASKS)
      .then((tasks) => {
        this.setState({ recentTasks: tasks }, () => {
          this.loadPublicTasks(callback);
        });
      }).catch((error) => {
        this.showError('errorLoadRecentTasks', error)
      });
  }

  loadPublicTasks(callback) {
    this.performLoadPublicTasks()
      .then((tasks) => {
        this.setState({ publicTasks: tasks }, () => {
          this.loadSharedTasks(callback);
        });
      }).catch((error) => {
        this.showError('errorLoadPublicTasks', error)
      });
  }

  loadSharedTasks(callback) {
    this.performLoadSharedTasks(DEFAULT_SHARED_TASKS)
      .then((tasks) => {
        this.setState({ sharedTasks: tasks }, () => {
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorLoadPublicTasks', error)
      });
  }

  expandRecentTasks(callback) {
    const { recentTasks } = this.state;
    const offset = (recentTasks || []).length > 0 ? (recentTasks[recentTasks.length - 1].data || {}).started : null;
    this.performLoadRecentTasks(EXPAND_RECENT_TASKS, offset)
      .then((tasks) => {
        this.setState({
          recentTasks: (recentTasks || []).concat(tasks),
          hasMoreRecentTasks: tasks.length > 0,
        }, callback);
      }).catch((error) => {
        this.showError('errorLoadRecentTasks', error)
      });
  }

  expandSharedTasks(callback) {
    const { sharedTasks } = this.state;
    const offset = (sharedTasks || []).length > 0 ? (sharedTasks[sharedTasks.length - 1].data || {}).shared : null;
    this.performLoadSharedTasks(EXPAND_SHARED_TASKS, offset)
      .then((tasks) => {
        this.setState({
          sharedTasks: (sharedTasks || []).concat(tasks),
          hasMoreSharedTasks: tasks.length > 0,
        }, callback);
      }).catch((error) => {
        this.showError('errorLoadSharedTasks', error)
      });
  }

  dashboardLoaded(options) {
    if (!this.props.onDashboardLoad) {
      return;
    }
    this.props.onDashboardLoad(options);
  }

  showError(message, error) {
    if (!this.props.onError) {
      return;
    }
    this.props.onError(message, error);
  }

  addTask(template) {
    if (template === null && this.state.templates && this.state.templates.length > 0) {
      this.setState({
        selectTemplate: true,
      });
      return;
    }
    if (!this.props.onTaskAdd) {
      return;
    }
    const templateObjs = (this.state.templates || []).filter((t) => t.id === template.id);
    this.props.onTaskAdd(templateObjs.length === 0 ? null : templateObjs[0]);
  }

  async performLoadResources() {
    const templates = await this.loadTemplates();
    const tasks = await this.loadTasks();
    return { templates, tasks };
  }

  async performLoadRecentTasks(limit, offset) {
    const { user, firestore } = this.props;
    let contextRefsQuery = firestore
      .collection('user')
      .doc(user.uid)
      .collection('context');
    if (offset) {
      contextRefsQuery = contextRefsQuery.where('started', '<', offset);
    }
    const contextRefs = await contextRefsQuery
      .orderBy('started', 'desc')
      .limit(limit)
      .get();
    if (contextRefs.empty) {
      return [];
    }
    const taskRefs = await Promise.all(contextRefs.docs.map((contextRef) => firestore
      .collection('task')
      .doc(contextRef.id)
      .get()));
    const hasThumbnails = await Promise.all(taskRefs
      .map((taskRef) => this.taskHasThumbnail(firestore, taskRef.id)));
    return taskRefs
      .map((taskRef, taskIndex) => (taskRef.exists ? {
        id: taskRef.id,
        data: Object.assign({
          started: contextRefs.docs[taskIndex].data().started,
        }, taskRef.data()),
        hasThumbnail: hasThumbnails[taskIndex],
      } : null))
      .filter((task) => task !== null)
      .filter((task) => !task.data.trash);
  }

  async performLoadSharedTasks(limit, offset) {
    const { user, firestore } = this.props;
    let sharedRefsQuery = firestore
      .collection('user')
      .doc(user.uid)
      .collection('shared');
    if (offset) {
      sharedRefsQuery = sharedRefsQuery.where('created', '<', offset);
    }
    const sharedRefs = await sharedRefsQuery
      .orderBy('created', 'desc')
      .limit(limit)
      .get();
    if (sharedRefs.empty) {
      return [];
    }
    const taskRefs = await Promise.all(sharedRefs.docs
      .map((sharedRef) => this.getSharedTask(firestore, sharedRef)));
    const hasThumbnails = await Promise.all(taskRefs
      .map((taskRef) => this.taskHasThumbnail(firestore, taskRef.id)));
    return taskRefs
      .map((taskRef, taskIndex) => (taskRef.exists ? {
        id: taskRef.id,
        sharedId: sharedRefs.docs[taskIndex].id,
        data: Object.assign({
          shared: sharedRefs.docs[taskIndex].data().created,
        }, taskRef.data()),
        hasThumbnail: hasThumbnails[taskIndex],
      } : null))
      .filter((task) => task !== null)
      .filter((task) => !task.data.trash);
  }

  async getSharedTask(firestore, sharedRef) {
    const { userId, taskId } = sharedRef.data();
    try {
      const userTaskRef = await firestore
        .collection('user')
        .doc(userId)
        .collection('task')
        .doc(taskId)
        .get();
      if (!userTaskRef.exists) {
        return {
          exists: false,
        };
      }
      return await firestore
        .collection('task')
        .doc(userTaskRef.data().taskId)
        .get();
    } catch(e) {
      console.warn('Cannot load shared user task. Ignored.', e);
      return {
        exists: false,
      };
    }
  }

  async loadTemplates() {
    const { firestore } = this.props;
    const templateRefs = await firestore
      .collection('task')
      .where('template', '==', true)
      .get();
    const templates = templateRefs.docs.map((templateRef) => ({
      id: templateRef.id,
      data: templateRef.data(),
    })).filter((task) => !task.data.trash);
    return templates;
  }

  async loadTasks() {
    const { user, firestore } = this.props;
    const taskRefs = await firestore
      .collection('user')
      .doc(user.uid)
      .collection('task')
      .orderBy('updated', 'desc')
      .get();
    if (taskRefs.empty) {
      return [];
    }
    const hasThumbnails = await Promise.all(taskRefs.docs
      .map((taskRef) => this.taskHasThumbnail(firestore, taskRef.data().taskId)));
    return taskRefs.docs.map((taskRef, taskRefIndex) => ({
      id: taskRef.id,
      data: taskRef.data(),
      hasThumbnail: hasThumbnails[taskRefIndex],
    })).filter((task) => !task.data.trash);
  }

  async performLoadPublicTasks(order) {
    const { firestore } = this.props;
    let taskRefsQuery = firestore
      .collection('task')
      .where('public', '==', true);
    if (!order || order === 'count') {
      taskRefsQuery = taskRefsQuery.orderBy('stats.count', 'desc');
    } else if (order === 'users') {
      taskRefsQuery = taskRefsQuery.orderBy('stats.users', 'desc');
    } else if (order === 'updated') {
      taskRefsQuery = taskRefsQuery.orderBy('updated', 'desc');
    }
    const taskRefs = await taskRefsQuery.limit(MAX_PUBLIC_TASKS).get();
    if (taskRefs.empty) {
      return [];
    }
    const hasThumbnails = await Promise.all(taskRefs.docs
      .map((taskRef) => this.taskHasThumbnail(firestore, taskRef.id)));
    return taskRefs.docs.map((taskRef, taskRefIndex) => ({
      id: taskRef.id,
      data: taskRef.data(),
      hasThumbnail: hasThumbnails[taskRefIndex],
    })).filter((task) => !task.data.trash);
  }

  async taskHasThumbnail(firestore, taskId) {
    if (!taskId) {
      return null;
    }
    const fileRefs = await firestore
      .collection('task')
      .doc(taskId)
      .collection('file')
      .where('name', 'in', ['thumbnail.png', 'thumbnail.jpeg', 'thumbnail.jpg'])
      .get();
    if (fileRefs.empty) {
      return null;
    }
    return `/t/${taskId}/thumbnail.jpg?w=192`;
  }

  sortPublicTasks(value) {
    this.setState({
      publicTasksOrder: value,
      publicTasks: null,
    }, () => {
      this.performLoadPublicTasks(value)
        .then((tasks) => {
          this.setState({ publicTasks: tasks });
        }).catch((error) => {
          this.showError('errorLoadPublicTasks', error)
        });
    });
  }

  editTask(item) {
    const { history } = this.props;
    history.push(`/t/${item.data.taskId}`);
  }

  openTask(task) {
    const { history } = this.props;
    history.push(`/t/${task.id}`);
  }

  editSharedTask(item) {
    const { history } = this.props;
    history.push(`/s/${item.sharedId}`);
  }

  renderRecentTasks() {
    const { classes } = this.props
    return <Container className={classes.tasklist}>
      <Typography variant="h2" component="h2" className={classes.taskheader}>
        <FormattedMessage id="recentTasks" />
      </Typography>
      <TaskCardList
        tasks={this.state.recentTasks}
        onTaskOpen={(task) => this.openTask(task)}
        onTaskExpand={(callback) => this.expandRecentTasks(callback)}
        hasMoreTasks={this.state.hasMoreRecentTasks}
        noTasksMessage='noRecentTasks'
      />
    </Container>
  }

  renderPublicTasks() {
    const { classes } = this.props
    return <Container className={classes.tasklist}>
      <Typography variant="h2" component="h2" className={classes.taskheader}>
        <FormattedMessage id="publicTasks" />
      </Typography>
      <TaskCardList
        tasks={this.state.publicTasks}
        onTaskOpen={(task) => this.openTask(task)}
        noTasksMessage='noPublicTasks'
      >
        <Container className={classes.publicTasksOrder}>
          <ToggleButtonGroup
            value={this.state.publicTasksOrder || 'count'}
            exclusive
            onChange={(event, newValue) => this.sortPublicTasks(newValue)}
            aria-label="publicTasksOrder"
          >
            <ToggleButton
              value="count"
              aria-label="count"
              disabled={this.state.publicTasks === null}
            >
              <EqualizerIcon />
              <FormattedMessage id='tasksOrderCount' />
            </ToggleButton>
            <ToggleButton value="users" aria-label="users">
              <PeopleIcon />
              <FormattedMessage id='tasksOrderUsers' />
            </ToggleButton>
            <ToggleButton value="updated" aria-label="updated">
              <EventIcon />
              <FormattedMessage id='tasksOrderUpdated' />
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
      </TaskCardList>
    </Container>
  }

  renderSharedTasks() {
    const { classes } = this.props
    return <Container className={classes.tasklist}>
      <Typography variant="h2" component="h2" className={classes.taskheader}>
        <FormattedMessage id="sharedTasks" />
      </Typography>
      <TaskCardList
        tasks={this.state.sharedTasks}
        editable={true}
        onTaskOpen={(task) => this.editSharedTask(task)}
        onTaskExpand={(callback) => this.expandSharedTasks(callback)}
        hasMoreTasks={this.state.hasMoreSharedTasks}
        noTasksMessage='noSharedTasks'
      />
    </Container>;
  }

  renderMyTasks() {
    const { classes } = this.props
    return <Container className={classes.tasklist}>
      <Typography variant="h2" component="h2" className={classes.taskheader}>
        <FormattedMessage id="myTasks" />
      </Typography>
      <TaskCardList
        editable={true}
        tasks={this.state.tasks}
        onTaskOpen={(task) => this.editTask(task)}
        noTasksMessage='noMyTasks'
      >
        <Container className={classes.addTasks}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.addTask(
              this.state.selectTemplate ? this.state.template : null,
            )}
          >
            <AddIcon />
            <FormattedMessage
              id={this.state.selectTemplate ? 'newTaskWithTemplate' : 'newTask'}
            />
          </Button>
        </Container>
        {this.state.selectTemplate &&
          <Paper elevation={1} className={classes.templates}>
            <RadioGroup
              aria-label="template"
              name="template"
              value={this.state.template}
              onChange={(event) => this.setState({
                template: event.target.value,
              })}>
              {(this.state.templates || []).map((template) =>
                <FormControlLabel
                  key={template.id}
                  value={template.id}
                  control={<Radio />}
                  label={template.data.title}
                />)}
            </RadioGroup>
          </Paper>}
      </TaskCardList>
    </Container>;
  }

  render() {
    const { classes } = this.props
    const { tabIndex } = this.state;
    return (
      <Container className={classes.root}>
        <AppBar position='absolute' color="default" className={classes.tabBar}>
          <Tabs
            value={tabIndex}
            onChange={(event, newValue) => this.setState({
              tabIndex: newValue,
            })}
          >
            <Tab icon={<CheckIcon />} label={<FormattedMessage id="viewTab"/>} />
            <Tab icon={<EditIcon />} label={<FormattedMessage id="editTab"/>} />
          </Tabs>
        </AppBar>
        {tabIndex === 0 && <>
            <Container className={classes.description}>
              <Alert variant="outlined" severity="info">
                <FormattedMessage id='viewTasksDescription' />
              </Alert>
            </Container>
            {this.renderRecentTasks()}
            {this.renderPublicTasks()}
          </>}
        {tabIndex === 1 && <>
            <Container className={classes.description}>
              <Alert variant="outlined" severity="info">
                <FormattedMessage id='editTasksDescription' />
              </Alert>
            </Container>
            {this.renderMyTasks()}
            {this.renderSharedTasks()}
          </>}
        <LoadingDialog processing={this.props.processing} />
      </Container>
    )
  }
}

export default withStyles(styles)(withRouter(Dashboard))
