export const messages = {
  lang: 'en',
  about: 'About GO-E-MON',
  loading: 'Loading...',
  needToLogin: 'Login required',
  loginTaskDescription: `GO-E-MON is an online experiment platform. Please read the following and log in to participate.

#### Experimental data is managed by the distributor
When you execute a task delivered by GO-E-MON, the result data will be saved in the storage managed by the distributor (or the creator of the experiment, if necessary).
After that, this data will be deleted from GO-E-MON.

#### You can also manage the data by yourself
By setting your personal storage to GO-E-MON, the data can be obtained and utilized by you as well.

For more information, please refer to [What is GO-E-MON](https://scrapbox.io/cogtask-me/What_is_GO-E-MON_-_for_participants).`,
  needToLoginAsAnotherAccount: 'Not authorized for use by this account',
  unauthorizedTaskDescription: `Use by the currently logged in account {currentUserEmail} is not allowed.

To use this task, please use one of the accounts in **{emailAllowlist}**.

Clicking the button below will log you out, please log back in with the correct account.`,
  loginDescription: 'To start use, please sign in.',
  loginNotice: 'By signing in, you agree to the Terms of Use.',
  agreement: 'Terms of Use',
  greetingHeader0: 'Deliver tasks easily',
  greetingMessage0: 'With just a web browser, you can write JavaScript and deliver your tasks.',
  greetingHeader1: 'Securely store logs',
  greetingMessage1: 'The logs recorded by the task are stored in the individual\'s PDS. Users can manage their own logs and utilize them as needed.',
  notLoggedIn: 'Not logged in',
  currentUser: 'User Settings',
  openDashboard: 'Dashboard',
  openInquiry: 'Contact Us',
  openUserManual: 'User Manual',
  openTermsOfUse: 'Terms of Use',
  signout: 'Logout',
  next: 'Next',
  previous: 'Previous',
  register: 'Registration Completed',
  submit: 'Submit',
  newTask: 'Add New Task',
  newTitlePrefix: 'Copy of',
  distributeTitlePrefix: 'For distribution - ',
  processingNewTask: 'Creating New Task...',
  processingCopiedTask: 'Copying Task...',
  processingDistributedTask: 'Creating distribution...',
  viewTab: 'Doing Tasks',
  editTab: 'Making Tasks',
  viewTasksDescription: "You can perform various tasks in GO-E-MON. Let's try a task!",
  editTasksDescription: 'GO-E-MON lets you create and deliver your own tasks!',
  myTasks: 'My Tasks',
  publicTasks: 'Public tasks',
  noPublicTasks: 'There are no public tasks.',
  recentTasks: 'Recently viewed tasks',
  noRecentTasks: 'There are no recently viewed tasks.',
  sharedTasks: 'Shared tasks',
  noSharedTasks: 'There are no shared tasks.',
  reloadTasks: 'More tasks',
  noMyTasks: 'There are no tasks.',
  openTask: 'Open',
  moreTasks: 'More tasks',
  lessTasks: 'Less tasks',
  tasksOrderCount: 'Number of executions',
  tasksOrderUsers: 'Number of participants',
  tasksOrderUpdated: 'Last Update',
  editTask: 'Edit',
  taskSave: 'Save',
  taskShare: 'Share',
  taskClose: 'Close',
  taskOpen: 'Open',
  fileDelete: 'Delete',
  taskFullscreen: 'Fullscreen',
  taskFullscreenExit: 'Exit Fullscreen',
  startTask: 'Start',
  startingTask: 'Starting...',
  restartTask: 'Restart',
  toAgreement: 'Go to Agreement Manager',
  alreadyDeleted: 'This task has already been deleted.',
  sendingResult: 'Sending your result...',
  scriptTab: 'Script',
  usageTab: 'Usage',
  deployTab: 'Deploy',
  descriptionTab: 'Description',
  paramTab: 'Parameter',
  fileTab: 'File',
  debugTab: 'Debug',
  taskFinished: 'Thank you!',
  taskFinishedDetail1: 'Thank you for performing the task. The following result will be sent to the task author. (We will never send your account ID, email address, etc.)',
  taskFinishedDetailPersonary: 'If you set up your own account to synchronize Personally, the result will also be stored to your own Personality.',
  taskFinishedNoSummary: '(Result)',
  taskAborted: 'Thank you!',
  taskAbortedDetail: 'The task has been aborted. Please try again!',
  nextLinkDefaultText: 'OK',
  preventUserLink: 'The URL link from the editing Task has been fired. When executed by the user, it will open the following URL: {url}',
  descriptionScriptTab: 'The behavior of the task can be defined by JavaScript.',
  descriptionDescriptionTab: 'You can define the message to be displayed at the start of the task. Markdown formatting is available.',
  descriptionParamTab: 'The behavior of the task can be defined by parameters.',
  descriptionFileTab: 'You can set the files to be attached to the task.(Up to 10MB total, See help on using large files.)',
  descriptionDeployTab: 'You can configure settings related to the deployment of tasks.',
  descriptionDebugTab: 'You can check debug output using `console.log(line)`.',
  addParam: 'Add',
  deleteParam: 'Delete',
  editSchema: 'Edit the schema',
  paramEditTitle: 'Edit parameter',
  paramEditSchemaTitle: 'Edit the schema',
  paramEditOK: 'OK',
  paramEditCancel: 'Cancel',
  columnParamname: 'Parameter name',
  columnParamvalue: 'Parameter value',
  paramName: 'Parameter name',
  paramValue: 'Parameter value',
  paramAnalyticsURL: 'Resource URLs that explain the analysis method (stored in a log, Optional)',
  paramGenerateIdForDistributor: 'true to generate a user ID that can only be identified within the distributor (Default is to generate user IDs that can only be identified within the task)',
  paramAutoStart: 'true to start automatically if it has already been started once',
  paramNoGuideForPersonary: 'true if you do not want the introduction about the Personary to be displayed automatically',
  paramGroupIds: 'To divide subjects into multiple groups, specify group identifiers separated by commas.',
  paramEmailAllowlist: 'If you want to restrict the available email addresses, you can use the allowed domains (@...) separated by a semicolon.',
  paramLTIProvider: 'Specify LTI provider information by JSON string',
  paramAgreement: 'When using the agreement, specify by JSON string',
  paramForceLogin: 'If you want to force the user to go to the login page when this task is opened and the user is not logged in, set the login method name.',
  paramInvalidNameError: 'The parameter name starts with an alphabetic character and can only contain letters, numbers, and underscores.',
  paramDuplicatedNameError: 'An item with the same name has already been defined.',
  paramInvalidIntegerError: 'Invalid integer value.',
  paramInvalidNumberError: 'Invalid numeric value.',
  paramInvalidBooleanError: 'The boolean value can be true or false.',
  paramInvalidURLError: 'Invalid URL string.',
  paramInvalidURLSchemeError: 'URL protocol is invalid. http or https can be specified.',
  paramInvalidJSONError: 'The JSON string is invalid: {error}',
  paramInvalidSchemaError: 'The JSON schema string is invalid: {error}',
  paramSchema: 'Specify the parameter schema as a JSON string',
  openPersonary: 'Settings of Personary',
  copyTask: 'Copy the task to my account',
  distributeTask: 'Distribute the task by my account',
  plrStartIntroduction: 'Test Logs can be managed using the Personary',
  plrStartNeedPLR: 'You can use Personary to manage the records of the tests you performed in GO-E-MON. All records of the execution of tasks, or tasks created by you, are saved to Personary.',
  plrStartInstallPLR: 'The Personary app can be installed and configured at any time. The execution record will be temporarily stored on the GO-E-MON server until you set up the Personary app.',
  plrStep0InstallPLR: 'Step 1. Install and configure Personary.',
  plrStep0InstallPersonary: 'If you want to use Personary to manage your test logs, please install the Personary application.',
  plrStep0SettingPersonary: 'Personary uses a cloud storage service to store your data. Set your data to be stored in Google Drive {email}.',
  plrStep0AboutPersonary: 'Personary application is a data management and operation application provided by Assemblogue, Inc. Please read the terms of use of the Personary app before using it.',
  plrStep0CautionForOldVersion: 'If you installed Personary from the Store before 2021/6, please upgrade to the new version. (Click for details)',
  plrStep1RegisterFriend: 'Step 2. Add GO-E-MON account to a friend list of Your Personary.',
  plrStep1QRCode: 'Launch the Personary app and select "Add friends" in Your Personary and scan the QR code below, or',
  plrStep1RequestString: 'Or, you can enter the following friend request string in the Personary app',
  plrFriendRequestCopied: 'The friend request string has been copied.',
  plrStep1PleaseRegister: 'When you have finished adding friends, click the "Registration Completed" button. (It may take a while for the data to start being saved to the Personary.)',
  placeholderScript: 'JavaScript',
  placeholderDescription: 'Markdown',
  deployPublish: 'Deploy this Task',
  descriptionPublish: 'To make this Task available to other users, you need to deploy it. Changes made after deployment will not be reflected to the user until redeployment.',
  hasUnpublishedChanges: 'There are changes that have not been published',
  taskImportable: 'Allow copying of this task',
  taskPublic: 'Publish this task (Users who do not know the URL can find this task.)',
  taskDistributable: 'Enable other users to distribute this task',
  taskShowCreator: 'Display the distributor\'s information',
  taskCreatorLogType: 'Type of log sent to the distributor',
  taskPseudonymizedLogType: 'Send a pseudonymized log to the distributor',
  taskNoneLogType: 'Do not send logs to the distributor',
  taskAuthorLogType: 'Type of log sent to the author',
  taskAuthorPseudonymizedLogType: 'Send a pseudonymized log to the author',
  taskAuthorNoneLogType: 'Do not send logs to the author',
  noticePseudonymizedLogType: 'The execution log will be sent to the distributor. Your name and email address will not be sent.',
  noticeGenerateIdForDistributor: 'The execution log will contain a user ID that can be identified within the distributor. This task may work in cooperation with the execution logs of another task of the same distributor.',
  noticeNoneLogType: 'Any information will not be sent to the distributor.',
  noticeAuthorPseudonymizedLogType: 'The execution log will be sent to the author. Your name and email address will not be sent.',
  noticeAuthorNoneLogType: 'Any information will not be sent to the author.',
  userInfoNotDefined: 'Valid distributor\'s Information is not defined - ',
  distributorInfoNotShown: 'Distributor\'s information must be displayed in order to distribute task.',
  authorInfoNotShown: 'Distributor\'s information must be displayed in order to allow other distributors to use it.',
  authorDescriptionNotDefined: 'You must fill in the task description in order to allow other distributors to use it.',
  taskPublish: 'Deploy',
  headerTaskReuse: 'Permission for use to other distributors',
  headerTaskCreator: 'Display of distributor information',
  deployLink: 'Participating in this Task',
  descriptionLink: 'In order to have other users perform the task, please distribute the following URL or QR code.',
  downloadQRCode: 'Download QR Code',
  urlCopied: 'Copied to clipboard',
  manageTask: 'Manage this Task',
  descriptionDeleteTask: 'Delete this task. This operation cannot be undone.',
  deleteTask: 'Delete this task',
  deleteConfirmTitle: 'Deleting this task',
  deleteConfirmContent: 'Deletes the task {title}. This operation is irreversible.',
  deleteConfirmCancel: 'Cancel',
  deleteConfirmOK: 'Delete',
  dropZoneArea: 'Drag \'n\' drop some files here, or click to select files',
  columnPreload: 'Preload',
  columnFilename: 'Filename',
  columnSize: 'Size',
  columnModified: 'Modified',
  columnImportType: 'Import As',
  columnPriority: 'Priority',
  columnDownload: 'DL',
  importTypeJavaScript: 'JavaScript',
  importTypeCSS: 'CSS',
  importTypeImage: 'Image',
  importTypeGeneric: 'Generic',
  uploadNotPermittedTitle: 'Cannot Upload',
  uploadNotPermittedContent: 'Uploading files is not allowed. Uploading is allowed up to {capacity}. Please delete unnecessary files. (Currently {current})',
  uploadNotPermittedOK: 'OK',
  inquirySending: 'Sending...',
  inquirySent: 'Transmitted.',
  inquiryDescription: 'Please fill the form below to inquire about this application. We will reply to you by e-mail later.',
  inquiryLabelUserID: 'User ID',
  inquiryHelperTextUserID: 'This information will be used to confirm the details of your inquiry.',
  inquiryLabelMail: 'Contact e-mail address',
  inquiryHelperTextMail: 'We will use the information only for the purpose of replying to your inquiry.',
  inquiryPlaceholderMail: 'Please enter your contact e-mail address.',
  inquiryLabelText: 'Contents of inquiry',
  inquiryPlaceholderText: 'Please enter the details of your inquiry.',
  inquiryHelperTextText: 'Please fill in the question, including the problem\'s situation. Please do not include your name or other personal information as much as possible.',
  noDebugMessages: 'No debug messages',
  debugStart: 'Script started',
  debugFinish: 'Script finished',
  debugError: 'Script error',
  debugGlobalError: 'Error occurred in Browser',
  debugLog: 'Log recorded',
  debugPreloadStarting: 'Preloading...',
  debugPreloadFinished: 'Loaded',
  debugPreloadFailed: 'Failed to load',
  logLevelDebug: 'DEBUG',
  logLevelInfo: 'INFO',
  logLevelError: 'ERROR',
  logLevelWarning: 'WARN',
  authorHeader: 'Author',
  creatorHeader: 'Distributor',
  shareTitle: 'Share with users',
  shareType: 'Sharing',
  sharesDisabled: 'Disabled',
  sharesWithMembers: 'Only members can edit',
  sharesWithLink: 'Editable by anyone who knows the link',
  shareMembers: 'Members to share (email address)',
  shareURL: 'Shareable URL',
  copyShareLink: 'Copy the link',
  shareClose: 'Close',
  requestingShareId: 'Sharing...',
  settingsUserHeader: 'Author\'s information',
  settingsUserDescription: 'You can display the creator\'s information in the task.',
  settingsUserNicknameEN: 'Author\'s display name (in English)',
  settingsUserNicknameJA: 'Author\'s display name (in Japanese, Optional)',
  settingsUserAffiliationEN: 'Author\'s affiliation (in English)',
  settingsUserAffiliationJA: 'Author\'s affiliation (in Japanese, Optional)',
  settingsUserContact: 'Contact Information',
  settingsUserNoContacts: 'You need to specify at least one valid contact.',
  settingsUserNoNicknames: 'You need to enter the display name.',
  settingsUserNoAffiliations: 'You need to enter the name of your affiliation.',
  settingsUserContactMail: 'Use email address as contact information - ',
  settingsUserContactTwitter: 'Twitter Account',
  placeholderUserContactTwitter: '@your_twitter_account',
  settingsUserContactFacebook: 'Facebook Profile URL',
  placeholderUserContactFacebook: 'https://www.facebook.com/your_facebook_account',
  settingsTokenHeader: 'Developer Tokens',
  settingsNewTokenLabel: 'Token Name',
  settingsNewTokenPlaceholder: 'Name of new token to be created',
  settingsNewTokenExpirationLabel: 'Expiration Date',
  settingsNewTokenExpirationNever: 'No time limit is set',
  settingsNewTokenExpiration1Hour: 'A hour',
  settingsNewTokenExpiration1Day: 'A day',
  settingsNewTokenExpiration1Week: 'A week',
  settingsNewTokenExpiration30Days: '30 days',
  newToken: 'New token',
  columnTokenname: 'Token name',
  columnTokenexpires: 'Expiration',
  rowNoTokenExpiration: 'No expiration',
  deleteToken: 'Revoke',
  tokenDeleteConfirmTitle: 'Token revocation',
  tokenDeleteConfirmMessage: 'Revoke the token {tokenNames}. Are you sure?',
  tokenDeleteConfirmCancel: 'Cancel',
  tokenDeleteConfirmOK: 'Revoke',
  cantReadToken: 'Token can be confirmed only this time.',
  tokenCopied: 'Copied to clipboard.',
  copyToken: 'Copy',
  settingsOptionalHeader: 'Other settings',
  authorDefaultNickname: 'Name Not specified',
  authorDefaultAffiliation: 'Affiliation Not specified',
  settingsAuthorExample: 'Example of Author',
  langEN: 'English',
  langJA: 'Japanese',
  saveUserInfo: 'Save',
  noSettings: 'There are no configurable items.',
  garminConnected: 'Already connected to Garmin Connect.',
  garminNotConnected: 'Not connected to Garmin Connect.',
  startGarminAuth: 'Start connecting with Garmin Connect',
  disconnectGarminAuth: 'Disconnect from Garmin Connect',
  agreementNoAgreements: 'No agreement obtaining settings are defined for this task.',
  agreementDisagree: 'Revoke Agreement',
  agreementAgreedDescription: 'Agreement to participate in the experiment can be revoked at any time.',
  agreementAgreedHeader: 'Agreed to participate in experiment "{title}"',
  agreementDisagreeConfirmTitle: 'Confirmation of Revocation',
  agreementDisagreeConfirmDescription: `
Are you sure you want to revoke your agreement?
If you revoke your agreement, your data will not be analyzed.

Agreement can be given again at any time.
`,
  agreementDisagreeConfirmCancel: 'Cancel',
  agreementDisagreeProcessing: 'Revoking Agreement...',
  agreementDisagreeConfirmed: 'Revoke',
  agreementNeedAgreeHeader: 'You need to complete the participation process for the experiment "{title}".',
  agreementNeedAgreeDescription: 'In order to use this content, you must review the experimental description and fill out the agreement form.',
  agreementAgree: 'Agree',
  agreementAgreeResultTitle: 'Thank you!',
  agreementAgreeResultClose: 'Close',
  agreementAgreeResultDescription: `
Agreement accepted!
Keep on performing your tasks!
`,
  agreementConfirmProgress: 'Sending...',
  agreementRequirementConfirmed: 'Confirmed.',
  agreementVideoDescription: 'Once you have reviewed the video, click the "Confirmed" button.',
  agreementRequirementConfirm: 'Confirmed',
  agreementPDFDescription: 'After reviewing the description, click the "Confirmed" button.',
  agreementSignDescription: 'If you are sure you want to do this, please press the submit button.',
  agreementSignTouchDescription: 'Please read the following and sign your name(s) on the touch screen.',
  agreementSignTabTouch: 'Signature by touch',
  agreementSignTabPhoto: 'Signature and taking a camera shot.',
  agreementSignWriteByTouchpanel: 'Input by touch panel',
  agreementSignTouchInputDescription: 'Sign your signature on the touch screen. Please write on the screen in a direction that is easy to write. ({subject})',
  agreementSignTouchInputCancel: 'Cancel',
  agreementSignTouchInputComplete: 'Complete',
  agreementSignImage: 'Sign',
  agreementSignPhotoDescription: 'Please download and print the [form]({src}) and fill in the signature of {subjects} in the signature field. Once completed, please take a picture of the consent form using the button below and upload it.',
  agreementSignUploadButton: 'Click to upload photos',
  agreementSignCancelButton: 'Delete',
  agreementReturn: 'Return to Task',
  agreementLogSummaryAgreed: 'Agreed.',
  agreementLogSummaryAgreementRevoked: 'Agreement Revoked.',
  talkgroupSettings: 'Using the messages',
  settingsTalkgroupDescription: 'Communicate with the GO-E-MON task via the group "{name}"!',
  activateTalkgroup: 'Activate the messages',
  selectTask: 'Select',
  activatedTalkuserTitle: 'Messaging service linked',
  activatedTalkuserDescription: 'Messaging service {type} user {name} has been linked. Please close this window.',
  activatedTalkuserConfirm: 'OK',
  activatedHealthuserTitle: 'Sensor service linked',
  activatedHealthuserDescription: 'Sensor service {type} has been linked. Please close this window.',
  activatedHealthuserConfirm: 'OK',
  settingsTalkuserHeader: 'Messaging Services',
  settingsNoTalkusers: 'There are no messaging services that are linked.',
  settingsDisconnectTalkuser: 'Disconnect',
  disconnectTalkuserConfirmTitle: 'Disconnect messaging service',
  disconnectTalkuserConfirmDescription: 'Disconnect the user {name} from the messaging service {type}. Are you sure?',
  disconnectTalkuserConfirmCancel: 'Cancel',
  disconnectTalkuserConfirmOK: 'Disconnect',
  talkuserLoginDescription: 'Link your messaging service to your GO-E-MON account. Please log in.',
  messagingRequestTitle: 'Connecting to Messaging Service',
  messagingRequestContent: 'This task sends a message to you via the messaging service {type}.',
  messagingRequestCancel: 'Not connecting now',
  messagingLineRequest: 'Open the link below or scan the QR code below with your smartphone and register the GO-E-MON account as a friend on LINE.',
  messagingAddFriendOr: 'Or scan the QR code below with your smartphone.',
  messagingAddFriendLink: '(For smartphone) Add GO-E-MON as a friend',
  messagingLineRequest1: '1. Register GO-E-MON as a friend',
  messagingLineRequest2: '2. Associate your GO-E-MON with your LINE account',
  messagingLineRequest2Description1: 'Open the URL sent to you to LINE or enter the code sent to LINE below.',
  messagingLineRequest2Description2: 'This dialog box will close automatically when you open the URL sent to LINE.',
  messagingLineCodeLabel: 'Confirmation Code',
  messagingLineCodePlaceholder: 'XXXXXXXXXX, Hyphens are ignored',
  messagingMissingCode: 'Confirmation code not found. Please recheck the code and if it still fails, reissue the code.',
  messagingLineGroupRequest1: '1. Register GO-E-MON as a friend',
  messagingLineGroupRequest2: '2. Add GO-E-MON to the group talk',
  messagingLineGroupRequest2Description: 'Create a group on LINE and add your GO-E-MON account.',
  welcomeTalkuserMessage: 'Thank you for linking your account! Please continue to use GO-E-MON.\nhttps://goemon.cloud?_talklogin={type}',
  welcomeTalkuserMessageWithTask: 'Thank you for linking your account! Please continue to configure the task.\nhttps://goemon.cloud/t/{taskId}?_talklogin={type}',
  healthRequestTitle: 'Connecting to a Sensor Service',
  healthRequestContent: 'This task retrieve your status via the sensor service {type}. Please click the button below to link your account with the sensor service.',
  healthRequestCancel: 'Not connecting now',
  healthAccessTitle: 'Start using the sensor service',
  healthAccessOK: 'OK',
  healthAccessAwareDescription: 'To link with AWARE Framework, please use the QR code or Study URL below.',
  healthAccessAwareStudyURL: 'Study URL:',
  healthAccessAwareCopied: 'Study URL copied.',
  healthLinkGarminConnect: 'Connect with Garmin Connect',
  disconnectHealthuserConfirmTitle: 'Disconnect sensor service',
  disconnectHealthuserConfirmDescription: 'Disconnect from the sensor service {type}. Are you sure?',
  disconnectHealthuserConfirmCancel: 'Cancel',
  disconnectHealthuserConfirmOK: 'Disconnect',
};
