import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const styles = (theme) => ({
  root: {
    maxWidth: '500px',
    margin: 'auto',
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
  },
  header: {
    fontSize: '1em',
    fontWeight: 'bold',
    padding: theme.spacing(1),
  },
  avatar: {
    marginLeft: 'auto !important',
    marginRight: theme.spacing(2),
  },
  nickname: {
    fontSize: '1.4em',
  },
  affiliation: {
    fontSize: '0.9em',
    color: '#555',
  },
  contact: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
});

export function validateContact(contact) {
  if (!contact) {
    return false;
  }
  if (contact.twitter && !validateTwitter(contact.twitter)) {
    return false;
  }
  if (contact.facebook && !validateFacebook(contact.facebook)) {
    return false;
  }
  if (contact.mail || contact.twitter || contact.facebook) {
    return true;
  }
  return false;
}

export function validateAffiliation(affiliation) {
  if (!affiliation) {
    return false;
  }
  return affiliation.en;
}

export function validateNickname(nickname) {
  if (!nickname) {
    return false;
  }
  return nickname.en;
}

export function validateTwitter(account) {
  if (!account) {
    return false;
  }
  return account.match(/^@\S+$/);
}

export function validateFacebook(url) {
  if (!url) {
    return false;
  }
  return url.match(/^https:\/\/(www\.)?facebook\.com\/\S+$/);
}

class Author extends Component {
  openEmail() {
    const { contact } = this.props.userInfo || {};
    if (!contact.mail) {
      return;
    }
    window.open(`mailto:${contact.mail}`);
  }

  openTwitter() {
    const { contact } = this.props.userInfo || {};
    if (!validateTwitter(contact.twitter)) {
      return;
    }
    const m = contact.twitter.match(/^@(\S+)$/);
    window.open(`https://twitter.com/${m[1]}/`, '_blank');
  }

  openFacebook() {
    const { contact } = this.props.userInfo || {};
    if (!validateFacebook(contact.facebook)) {
      return;
    }
    window.open(contact.facebook, '_blank');
  }

  render() {
    const { classes } = this.props;
    const lang = this.props.lang || 'en';
    const { avatar, nickname, affiliation, contact } = this.props.userInfo || {};
    return (
      <Container className={classes.root}>
        <Typography variant="h4" component="h4" className={classes.header}>
          <FormattedMessage id={this.props.headerMessageId || 'authorHeader'} />
        </Typography>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={2}>
              {avatar && avatar.photoURL &&
                <Avatar
                  alt={nickname && nickname.en}
                  src={avatar.photoURL}
                  className={classes.avatar}
                />}
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h4" component="h4" className={classes.nickname}>
                {nickname && (nickname[lang] || nickname.en || <FormattedMessage id='authorDefaultNickname' />)}
              </Typography>
              <Typography variant="h5" component="h5" className={classes.affiliation}>
                {affiliation && (affiliation[lang] || affiliation.en || <FormattedMessage id='authorDefaultAffiliation' />)}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.contact}>
              {contact && contact.mail &&
                <EmailIcon onClick={() => this.openEmail()}></EmailIcon>}
              {contact && validateTwitter(contact.twitter) &&
                <TwitterIcon onClick={() => this.openTwitter()}></TwitterIcon>}
              {contact && validateFacebook(contact.facebook) &&
                <FacebookIcon onClick={() => this.openFacebook()}></FacebookIcon>}
            </Grid>
          </Grid>
          {this.props.children}
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(Author));
