import { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
  root: {
    overflowY: 'clip',
  },
  content: {
    textAlign: 'center',
    color: '#000',
    marginTop: '1em',
  },
  description: {
    fontSize: '1em',
    marginTop: 0,
    marginBottom: '0.5em',
  },
  step: {
    marginTop: '0.5em',
    fontSize: '1.4em',
    color: '#000',
  },
  qrcode: {
    width: '40vmin',
    height: '40vmin',
  },
});

class HealthRequestingDialog extends Component {
  cancel() {
    if (!this.props.onCancel) {
      return;
    }
    this.props.onCancel();
  }

  renderGarmin() {
    const { classes, user } = this.props;
    return <>
      <p className={classes.description}>
        <FormattedMessage id='healthRequestContent' values={{ type: this.getDisplayableType() }} />
      </p>
      <Container className={classes.content}>
        <Button
          variant="contained"
          color='primary'
          href={`/health/garmin/auth?user=${user.uid}`}
          target='_blank'
          rel="noreferrer"
        >
          <FormattedMessage id='healthLinkGarminConnect' />
        </Button>
      </Container>
    </>;
  }

  getDisplayableType() {
    if (!this.props.requesting) {
      return null;
    }
    if (this.props.requesting.startsWith('garmin')) {
      return 'Garmin';
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={!!this.props.requesting}
        onClose={() => this.cancel()}
        className={classes.root}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          <FormattedMessage id='healthRequestTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.requesting === 'garmin' && this.renderGarmin()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.cancel()} color="primary">
            <FormattedMessage id='healthRequestCancel' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(HealthRequestingDialog)
