import { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReplayIcon from '@material-ui/icons/Replay';

import ClipLoader from "react-spinners/ClipLoader";

import TaskCard from "./TaskCard";

const styles = (theme) => ({
  tasks: {
    padding: 0,
    textAlign: 'left',
  },
  tasksContent: {
    padding: theme.spacing(2),
  },
  tasksOrder: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
});

const DEFAULT_TASKS = 4;

class TaskCardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moreTasks: false,
      expanding: false,
    };
  }

  openTask(task) {
    const { onTaskOpen } = this.props;
    if (!onTaskOpen) {
      return;
    }
    onTaskOpen(task);
  }

  expandTasks() {
    if (this.state.moreTasks) {
      this.setState({ moreTasks: !this.state.moreTasks });
      return;
    }
    if ((this.props.tasks || []).length === DEFAULT_TASKS && this.props.hasMoreTasks) {
      this.reloadTasks({
        moreTasks: !this.state.moreTasks,
      });
      return;
    }
    this.setState({ moreTasks: !this.state.moreTasks });
  }

  reloadTasks(state) {
    this.setState(Object.assign({
      expanding: true,
    }, state), () => {
      const { onTaskExpand } = this.props;
      if (!onTaskExpand) {
        this.setState({
          expanding: false,
        });
        return;
      }
      onTaskExpand(() => this.setState({
        expanding: false,
      }));
    });
  }

  render() {
    const {
      classes, tasks, children, noTasksMessage, hasMoreTasks, editable, selectable, selected,
      onTaskSelected,
    } = this.props;
    if (tasks === undefined) {
      return;
    }
    return <Container className={classes.tasks}>
      {tasks === null && <ClipLoader />}
      {tasks !== null && tasks.length === 0 && <>
        {children}
        <Container className={classes.tasksContent}>
          <FormattedMessage id={noTasksMessage} />
        </Container>
      </>}
      {tasks !== null && tasks.length > 0 && <>
        {children}
        <Grid container className={classes.tasksContent} spacing={1}>
          {tasks.slice(0, this.state.moreTasks ? tasks.length : DEFAULT_TASKS).map((task) =>
            <Grid key={task.id} item md={3} xs={12}>
              <TaskCard
                task={task}
                editable={editable}
                selectable={selectable}
                selected={selectable && selected === task.id}
                onOpen={(task) => this.openTask(task)}
                onSelect={onTaskSelected}
              />
            </Grid>)}
        </Grid>
        {this.state.expanding && <Container>
            <ClipLoader />
          </Container>}
        {(tasks.length > DEFAULT_TASKS || hasMoreTasks) &&
          <Container className={classes.moreTasks}>
            {!this.state.expanding && this.state.moreTasks && hasMoreTasks &&
              <Button size="small" color="primary" onClick={() => this.reloadTasks()}>
                <ReplayIcon />
                <FormattedMessage id='reloadTasks' />
              </Button>}
            <Button size="small" color="primary" onClick={() => this.expandTasks()}>
              {this.state.moreTasks ? <>
                  <ExpandLessIcon />
                  <FormattedMessage id='lessTasks' />
                </> : <>
                  <ExpandMoreIcon />
                  <FormattedMessage id='moreTasks' />
                </>}
            </Button>
          </Container>}
        </>}
    </Container>
  }
}

export default withStyles(styles)(TaskCardList)
