export class GarminHealthContext {
  constructor(firestore, user) {
    this.props = { firestore, user };
  }

  async getHealthuserId() {
    const { user, firestore } = this.props;
    const garminuserQuery = firestore
      .collection('user')
      .doc(user.uid);
    const userRef = await garminuserQuery.get();
    if (!userRef.exists || !userRef.data().garminUserId) {
      return {
        healthuserId: null,
        waitForUpdate: garminuserQuery,
        checkForUpdate: (snapshot) => snapshot.exists && snapshot.data().garminUserId,
      };
    }
    const garminuserRef = await firestore
      .collection('garminuser')
      .doc(userRef.data().garminUserId)
      .get();
    if (!garminuserRef.exists) {
      throw new Error(`Unexpected garmin user ID`);
    }
    const healthuserId = `garmin:${garminuserRef.id}`;
    return {
      healthuserId,
      waitForUpdate: null,
      checkForUpdate: null,
    };
  }

  async getLastData(healthuserId, query) {
    const { firestore } = this.props;
    const { target } = query;
    this.validateTarget(target);
    const garminUserId = healthuserId.match(/garmin:(.+)/)[1];
    const collections = Array.isArray(target) ? target.map((t) => `garmin${t}`) : [`garmin${target}`];
    const since = Date.now() - 24 * 60 * 60 * 1000; // since 24 hours ago
    const resultDocs = await Promise.all(collections
      .map((col) => firestore
      .collection('garminuser')
      .doc(garminUserId)
      .collection(col)
      .where('pulled', '>=', since)
      .orderBy('pulled', 'desc')
      .get()));
    const resultDocDatas = resultDocs
      .map((resultDoc) => resultDoc.docs.map((r) => JSON.parse(r.data().data)));
    return resultDocDatas.reduce((x, y) => x.concat(y), []);
  }

  validateOptions(options) {
    const { target } = options;
    this.validateTarget(target);
    return options;
  }

  validateTarget(target) {
    if (!target) {
      throw new Error('target missing');
    }
    const VALID_TARGETS = ['activity', 'daily', 'epoch', 'sleep', 'stress'];
    if (!Array.isArray(target) && !VALID_TARGETS.includes(target)) {
      throw new Error(`Invalid target: ${target}`);
    }
    if (Array.isArray(target) && !target.every((t) => VALID_TARGETS.includes(t))) {
      throw new Error(`Invalid target: ${target.filter((t) => !VALID_TARGETS.includes(t))}`);
    }
  }
}