import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Container, Dialog } from '@material-ui/core';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify'
import firebase from 'firebase/app'
import axios from "axios";
import queryString from 'query-string';

import moment from 'moment';
import 'moment/locale/ja';

import Avatar from '@material-ui/core/Avatar';

import { firebaseApp } from './firebase/'
import Main, { createTask } from './Main';
import AppMenu from './components/AppMenu';
import Anonymous from './Anonymous';
import Loading from './components/Loading';
import PLRPanel from './components/PLRPanel';
import InquiryForm from './components/InquiryForm';
import { loadTask } from './components/TaskPanel';

import { messages as messagesEn } from './translations/en.js'
import { messages as messagesJa } from './translations/ja.js'

const EXPIRE_PLR_GUIDE = 1000 * 60 * 60 * 24 * 7;

const messages = {
  en: messagesEn,
  ja: messagesJa,
}

const styles = (theme) =>({
  root: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
});

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: 'en',
      user: undefined,
      error: null,
      openPLR: false,
      inquiry: false,
      processing: null,
      appMenuHidden: false,
      allowPLR: false,
      reserveOpenPLR: false,
    }
  }

  auth() {
    return this.props.auth || firebase.auth();
  }

  componentDidMount() {
    this.applyLocale();
    this.applyCustomSignIn();
    this.applyCustomToken();
    const auth = this.auth();
    auth.onAuthStateChanged(user => {
      const onGoemonAuthStateChanged = window.onGoemonAuthStateChanged;
      if (onGoemonAuthStateChanged) {
        onGoemonAuthStateChanged(auth, user);
      }
      if (!user) {
        this.applyCustomLogin();
      }
      this.setState({
        user,
      }, () => {
        if (!user) {
          return;
        }
        this.loadUser()
          .then((ret) => {
            const { userdata, permission, avatar } = ret;
            if (userdata.plrConfigured || this.plrShowedRecently(userdata)) {
              this.setState({
                permission,
                avatar,
              });
              return;
            }
            if (!this.state.reserveOpenPLR) {
              this.setState({
                allowPLR: true,
                permission,
                avatar,
              });
              return;
            }
            this.setState({
              permission,
              avatar,
            }, () => this.openPLR());
          })
          .catch((error) => {
            this.showError('errorLoadUser', error)
          });
      })
    })
  }

  openPLR() {
    this.updateUser({ plrGuided: Date.now() })
      .then(() => {
        this.setState({
          openPLR: true,
        });
      })
      .catch((error) => {
        this.showError('errorUpdateUser', error)
      });
  }

  plrShowedRecently(userdata) {
    if (userdata.plrConfigured) {
      return false;
    }
    const { plrGuided } = userdata;
    if (!plrGuided) {
      return false;
    }
    return plrGuided + EXPIRE_PLR_GUIDE > Date.now();
  }

  componentDidUpdate(prevProps, prevState) {
    this.emitErrorToast(prevState)
    if (this.state.locale !== (prevState || {}).locale) {
      moment().locale(this.state.locale);
    }
  }

  applyCustomSignIn() {
    window.goemonSignInWithEmailAndPassword = (email, password) => {
      return this.performCustomSignIn(email, password);
    };
    window.goemonSignOut = () => this.performSignOut();
  }

  async performCustomSignIn(email, password) {
    const auth = this.auth();
    return await auth.signInWithEmailAndPassword(email, password);
  }

  applyCustomToken() {
    const query = queryString.parse(window.location.search);
    const token = query['_ct'];
    if (!token) {
      return;
    }
    const auth = this.auth();
    auth.signInWithCustomToken(token)
      .then(() => {
        console.log('Login with custom token');
        const url = new URL(window.location.href);
        const query = queryString.parse(url.search);
        delete query['_ct'];
        url.search = Object.keys(query).length > 0 ? ('?' + queryString.stringify(query)) : '';
        window.history.pushState(null, null, url.toString());
      })
      .catch((error) => {
        this.showError('errorSignInWithCustomToken', error);
      });
  }

  applyCustomLogin() {
    const query = queryString.parse(window.location.search);
    const talklogin = query['_talklogin'];
    if (!talklogin) {
      return;
    }
    delete query['_talklogin'];
    const search = queryString.stringify(query);
    const path = encodeURIComponent(window.location.pathname + (search.length > 0 ? '?' : '') + search);
    window.location.href = `/talk/${talklogin}/auth?path=${path}`;
  }

  async performSignOut() {
    const auth = this.auth();
    await auth.signOut();
    this.setState({
      user: null,
    });
  }

  emitErrorToast(prevState) {
    if (prevState.error === this.state.error || this.state.error === null) {
      return
    }
    const msg = this.state.error.message ? <FormattedMessage id={this.state.error.message} /> : `Error: ${this.state.error}`
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => this.setState({
        error: null
      })
    })
  }

  applyLocale() {
    const language = navigator.language.split(/[-_]/)[0]
    if (language === 'ja') {
      this.setState({
        locale: language,
      })
    }
  }

  signIn(provider) {
    this.auth().signInWithPopup(provider)
  }

  signOut() {
    this.auth().signOut()
      .then(() => {
        this.setState({
          user: null,
        })
      }).catch((error) => {
        this.showError('errorSignOut', error)
      })
  }

  reSignIn(provider) {
    this.auth().signOut()
      .then(() => {
        this.setState({
          user: null,
        }, () => {
          this.signIn(provider);
        })
      }).catch((error) => {
        this.showError('errorSignOut', error)
      });
  }

  showError(message, error) {
    console.error(message)
    console.error(error)
    this.setState({
      error: {
        message,
        detail: error,
      },
    })
  }

  friendAdd() {
    this.updateUser({ plrConfigured: true })
      .then((userdata) => {
        this.setState({
          openPLR: false,
        });
      }).catch((error) => {
        this.showError('errorUpdateUser', error)
      });
  }

  submitInquiry(content, callback) {
    this.performSubmitInquiry(content)
      .then(data => {
        if (!callback) {
          return;
        }
        callback(true);
      })
      .catch(error => {
        this.showError('errorSubmitInquiry', error)
        if (!callback) {
          return;
        }
        callback(false);
      });
  }

  async performSubmitInquiry(content) {
    const firestore = this.props.firestore || firebaseApp.firestore();
    await firestore
      .collection('user').doc(this.state.user.uid)
      .collection('mail').add(content);
    await axios.post(`/api/v1/users/${this.state.user.uid}/mails/`);
    return content;
  }

  async loadUser() {
    const firestore = this.props.firestore || firebaseApp.firestore();
    const docRef = firestore.collection('user').doc(this.state.user.uid);
    const userRef = await docRef.get();
    const permissionRef = await firestore
      .collection('permission')
      .doc(this.state.user.uid)
      .get();
    const userdata = userRef.exists ? userRef.data() : {};
    const permission = permissionRef.exists ? permissionRef.data() : {};
    const avatarURL = this.state.user.photoURL || null;
    const avatar = <Avatar alt={this.state.user.displayName} src={avatarURL} />
    return { userdata, permission, avatar };
  }

  async updateUser(params) {
    const firestore = this.props.firestore || firebaseApp.firestore();
    const docRef = firestore.collection('user').doc(this.state.user.uid);
    const userRef = await docRef.get();
    if (!userRef.exists) {
      await docRef.set(params);
      return;
    }
    await docRef.update(params);
  }

  getTaskFromPath() {
    const path = new URL(window.location.href).pathname || '';
    return path.match(/^\/t\/(\S+)$/);
  }

  getShareFromPath() {
    const path = new URL(window.location.href).pathname || '';
    return path.match(/^\/s\/(\S+)$/);
  }

  copyTask() {
    this.setState({
      processing: 'processingCopiedTask',
    }, () => {
      const taskIdMatch = this.getTaskFromPath();
      const shareIdMatch = this.getShareFromPath();
      const taskId = taskIdMatch ? taskIdMatch[1] : null;
      const shareId = shareIdMatch ? shareIdMatch[1] : null;
      this.performCopyTask(taskId, shareId)
        .then((task) => {
          window.location.href = `/t/${task.taskId}`;
        }).catch((error) => {
          this.setState({
            processing: null,
          }, () => {
            this.showError('errorAddTask', error);
          });
        });
    });
  }

  distributeTask() {
    this.setState({
      processing: 'processingDistributedTask',
    }, () => {
      const taskIdMatch = this.getTaskFromPath();
      const shareIdMatch = this.getShareFromPath();
      const taskId = taskIdMatch ? taskIdMatch[1] : null;
      const shareId = shareIdMatch ? shareIdMatch[1] : null;
      this.performDistributeTask(taskId, shareId)
        .then((task) => {
          window.location.href = `/t/${task.taskId}`;
        }).catch((error) => {
          this.setState({
            processing: null,
          }, () => {
            this.showError('errorAddTask', error);
          });
        });
    });
  }

  async performCopyTask(taskId, shareId) {
    const user = this.state.user;
    const firestore = this.props.firestore || firebaseApp.firestore();
    const baseTask = await loadTask(user, firestore, taskId, shareId);
    const intl = {
      newTitle: messages[this.state.locale].newTitle || 'newTitle',
      newTitlePrefix: messages[this.state.locale].newTitlePrefix || 'newTitlePrefix',
    };
    return await createTask(user, firestore, intl, baseTask.task);
  }

  async performDistributeTask(taskId, shareId) {
    const user = this.state.user;
    const firestore = this.props.firestore || firebaseApp.firestore();
    const baseTask = await loadTask(user, firestore, taskId, shareId);
    baseTask.task.data.script = '';
    baseTask.task.data.param = [];
    baseTask.task.data.base = taskId;
    baseTask.task.data.paramschema = null;
    const intl = {
      newTitle: messages[this.state.locale].newTitle || 'newTitle',
      newTitlePrefix: messages[this.state.locale].distributeTitlePrefix || 'distributeTitlePrefix',
    };
    return await createTask(user, firestore, intl, baseTask.task);
  }

  loadPermission() {
    const user = this.state.user;
    const firestore = this.props.firestore || firebaseApp.firestore();
    firestore
      .collection('permission')
      .doc(user.uid)
      .get()
      .then((permissionRef) => {
        const permission = permissionRef.exists ? permissionRef.data() : {};
        this.setState({
          permission,
        });
      }).catch((error) => {
        this.showError('errorLoadPermission', error);
      });
  }

  openDashboard() {
    window.location.href = '/';
  }

  openSettings() {
    window.location.href = '/settings';
  }

  render() {
    const { classes } = this.props;
    return (
      <IntlProvider
        messages={messages[this.state.locale]}
        locale={this.state.locale}
      >
        <AppMenu
          user={this.state.user}
          avatar={this.state.avatar}
          hasTask={this.getTaskFromPath()}
          importable={this.state.importable}
          distributable={this.state.distributable}
          urlTermsOfUse={'https://scrapbox.io/cogtask-me/Terms_of_Use'}
          urlUserManual={'https://scrapbox.io/cogtask-me/User_Manual'}
          onSignOut={() => this.signOut()}
          onPersonaryOpen={() => this.setState({ openPLR: true })}
          onTaskCopy={() => this.copyTask()}
          onTaskDistribute={() => this.distributeTask()}
          onDashboardOpen={() => this.openDashboard()}
          onSettingsOpen={() => this.openSettings()}
          onInquiryOpen={() => this.setState({ inquiry: true })}
          hidden={this.state.appMenuHidden}
        />
        <Container disableGutters maxWidth="lg" className={classes.root}>
          {this.state.user === undefined &&
            <Loading />}
          {this.state.user === null && <>
              <Anonymous
                onSignIn={(provider) => this.signIn(provider)}
                firestore={this.props.firestore || firebaseApp.firestore()}
                onError={(message, error) => this.showError(message, error)}
              />
            </>}
          {this.state.user &&
            <Main
              user={this.state.user}
              permission={this.state.permission}
              processing={this.state.processing}
              firestore={this.props.firestore || firebaseApp.firestore()}
              storage={this.props.storage || firebaseApp.storage()}
              onTaskLoad={(options) => this.setState({
                importable: options.importable || false,
                distributable: options.distributable || false,
                reserveOpenPLR: !options.noGuideForPersonary,
              }, () => {
                if (options.noGuideForPersonary) {
                  return;
                }
                if (!this.state.allowPLR) {
                  return;
                }
                this.openPLR();
              })}
              onTitleSet={(title) => document.title = `${title} - GO-E-MON`}
              onDashboardLoad={(options) => this.setState({
                reserveOpenPLR: true,
              }, () => {
                if (!this.state.allowPLR) {
                  return;
                }
                this.openPLR();
              })}
              onError={(message, error) => this.showError(message, error)}
              onPermissionChange={() => this.loadPermission()}
              onAppMenuShow={() => this.setState({ appMenuHidden: false })}
              onAppMenuHide={() => this.setState({ appMenuHidden: true })}
              onReSignIn={(provider) => this.reSignIn(provider)}
            />}
          <Dialog
            open={this.state.openPLR}
            onClose={() => this.setState({ openPLR: false })}
          >
            <PLRPanel
              user={this.state.user}
              firestore={this.props.firestore || firebaseApp.firestore()}
              onFriendAdd={() => this.friendAdd()}
              onError={(message, error) => this.showError(message, error)}
            />
          </Dialog>
          <Dialog
            open={this.state.inquiry}
            onClose={() => this.setState({ inquiry: false })}
          >
            <InquiryForm
              user={this.state.user}
              firestore={this.props.firestore || firebaseApp.firestore()}
              onSubmit={(content, callback) => this.submitInquiry(content, callback)}
            />
          </Dialog>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      </IntlProvider>
    );
  }
}

export default withStyles(styles)(App);
