import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClipLoader from "react-spinners/ClipLoader";

const styles = (theme) => ({
  container: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'sans-serif',
    '> p': {
      fontSize: '1rem',
    },
    '> em': {
      fontSize: '.8rem',
    },
  },
  tool: {
    display: 'flex',
    padding: '0.5em',
  },
  deleteButton: {
    marginLeft: '0.5em',
  },
  schemaButton: {
    marginLeft: 'auto',
  },
  importTypeEditor: {
    minWidth: 120,
  },
  nameEditor: {
    height: '100%',
  },
  nameEditorField: {
    marginTop: 0,
    marginBottom: 0,
  },
  schemaError: {
    fontSize: '.8rem',
    color: 'red',
  },
});

function validateSchemaFieldName(name) {
  if (!name.match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
    throw new Error(`The parameter name starts with an alphabetic character and can only contain letters, numbers, and underscores: ${name}`);
  }
}

function validateSchemaType(schemaType) {
  const types = ['string', 'integer', 'number', 'file', 'boolean', 'url'];
  if (!types.includes(schemaType)) {
    throw new Error(`The schema type is invalid(valid types=${types.join(', ')}): ${schemaType}`);
  }
}

function validateSchemaField(field) {
  const fieldText = JSON.stringify(field);
  if (!field.name) {
    throw new Error(`The field must have a name property: ${fieldText}`);
  }
  validateSchemaFieldName(field.name);
  if (!field.type) {
    throw new Error(`The field must have a type property: ${fieldText}`);
  }
  validateSchemaType(field.type);
}

function validateSchema(schema) {
  if (!Array.isArray(schema)) {
    throw new Error('The schema must be array.');
  }
  schema.forEach((s) => validateSchemaField(s));
  return true;
}

class ParamEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false,
      lastSelectedRowId: null,
    };
  }

  getDataGrid() {
    const { intl } = this.props;
    const rows = (this.props.value || []).map((param, index) => ({
      id: index,
      name: param.name,
      value: param.value,
    }));
    const columns = [
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'columnParamname' }),
        width: 300,
        editable: false,
      },
      {
        field: 'value',
        headerName: intl.formatMessage({ id: 'columnParamvalue' }),
        editable: false,
        flex: 1,
      },
    ];
    return { rows, columns };
  }

  convertVhToPx(height) {
    if (!height) {
      return undefined;
    }
    if (height.toString().match(/^[0-9]+$/)) {
      return parseInt(height);
    }
    const m = height.toString().match(/^([0-9]+)vh$/);
    if (m) {
      return parseInt(document.documentElement.clientHeight * parseInt(m[1]) / 100.0);
    }
    return undefined;
  }

  rowClick(params) {
    const { id, row } = params;
    if (id !== this.state.lastSelectedRowId) {
      this.setState({
        lastSelectedRowId: id,
      });
      return;
    }
    this.setState({
      add: true,
      editingId: id,
      editingName: row.name,
      editingValue: row.value,
    });
  }

  cellEdit(changes) {
    if (!this.props.onChange) {
      return;
    }
    const values = (this.props.value || []).map((p) => Object.assign({}, p));
    const data = values[changes.id];
    if (changes.field === 'enable' || changes.field === 'name' ||
      changes.field === 'value') {
      data[changes.field] = changes.props.value;
    }
    this.props.onChange(values);
  }

  delete(items) {
    if (!this.props.onChange) {
      return;
    }
    const sortedIndices = items.map((item) => item);
    sortedIndices.sort();
    sortedIndices.reverse();
    const param = (this.props.value || []).map((v) => v);
    sortedIndices.forEach((index) => {
      param.splice(index, 1);
    });
    this.props.onChange(param);
  }

  selectionChange(newSelection) {
    this.setState({
      selectedItems: newSelection.selectionModel,
    });
  }

  add() {
    if (!this.props.onChange) {
      return;
    }
    const value = {
      name: this.state.editingName,
      value: this.normalizeValue(this.state.editingName, this.state.editingValue),
    };
    let param = (this.props.value || []).map((v) => v);
    if (this.state.editingId !== null) {
      param = param.map((v, index) => index === this.state.editingId ? value : v)
    } else {
      param = param.concat([value]);
    }
    this.props.onChange(param);
    this.setState({
      add: false,
      editingId: null,
      editingName: null,
      editingValue: null,
    })
  }

  normalizeValue(name, value) {
    const schema = (this.props.schema || []).concat(this.props.systemSchema || []);
    const target = schema.filter((s) => s.name === name);
    if (target.length !== 1) {
      return value;
    }
    const targetType = target[0].type;
    const targetFormat = target[0].format || null;
    if (targetType === 'string' && targetFormat === 'json') {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
    return value;
  }

  editSchema() {
    if (!this.props.onSchemaChange) {
      return;
    }
    this.props.onSchemaChange(JSON.parse(this.state.paramschema || '[]'));
    this.setState({
      editSchema: false,
      paramschema: null,
    })
  }

  getNamesFromSchema() {
    const schema = (this.props.schema || []).concat(this.props.systemSchema || []);
    return [...new Set(schema.filter((s) => s.name).map((s) => s.name))];
  }

  renderToolButtons() {
    const { classes } = this.props;
    return <Container className={classes.tool}>
      {this.state.loading && <ClipLoader />}
      {this.props.editable && <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({
              add: true,
              editingId: null,
              editingName: null,
              editingValue: null,
            })}
          >
            <AddIcon />
            <FormattedMessage id='addParam' />
          </Button>
          <Button
            className={classes.deleteButton}
            variant="contained"
            color="secondary"
            disabled={(this.state.selectedItems || []).length === 0}
            onClick={() => this.delete(this.state.selectedItems)}
          >
            <DeleteIcon />
            <FormattedMessage id='deleteParam' />
          </Button>
        </>}
      {this.props.schemaEditable &&
        <Button
          className={classes.schemaButton}
          variant="contained"
          color="secondary"
          onClick={() => this.setState({ editSchema: true })}
        >
          <EditIcon />
          <FormattedMessage id='editSchema' />
        </Button>}
    </Container>
  }

  getEditingHelp() {
    const name = this.state.editingName;
    if (!name) {
      return null;
    }
    const schema = (this.props.schema || []).concat(this.props.systemSchema || []);
    const target = schema.filter((s) => s.name === name);
    if (target.length === 0) {
      return null;
    }
    return target[target.length - 1].help;
  }

  validateValue(valueType, valueFormat, value, help) {
    const { intl } = this.props;
    if (valueType === 'url') {
      if (help && !value) {
        return help;
      }
      try {
        const url = new URL(value);
        if (url.protocol === "http:" || url.protocol === "https:") {
          return null;
        }
        return intl.formatMessage({ id: 'paramInvalidURLSchemeError' });
      } catch (_) {
        return intl.formatMessage({ id: 'paramInvalidURLError' });
      }
    }
    if (valueType === 'string' && valueFormat === 'json') {
      if (help && !value) {
        return help;
      }
      try {
        JSON.parse(value);
        return null;
      } catch(error) {
        return intl.formatMessage(
          { id: 'paramInvalidJSONError' },
          {
            error: error.toString(),
          },
        );
      }
    }
    if (valueType === 'integer') {
      if (help && !value) {
        return help;
      }
      if (value && value.match(/^-?[0-9]+$/)) {
        return null;
      }
      return intl.formatMessage({ id: 'paramInvalidIntegerError' });
    }
    if (valueType === 'number') {
      if (help && !value) {
        return help;
      }
      if (value && value.match(/^-?[0-9]+(\.[0-9]+)?$/)) {
        return null;
      }
      return intl.formatMessage({ id: 'paramInvalidNumberError' });
    }
    if (valueType === 'boolean') {
      if (help && !value) {
        return help;
      }
      if (value && value.match(/^(true|false)$/)) {
        return null;
      }
      return intl.formatMessage({ id: 'paramInvalidBooleanError' });
    }
    return null;
  }

  validateEditing() {
    const { intl } = this.props;
    const name = this.state.editingName;
    if (!name) {
      return {
        editingNameError: null,
        editingValueError: null,
        editingMultiline: false,
      };
    }
    if (!name.match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
      return {
        editingNameError: intl.formatMessage({ id: 'paramInvalidNameError' }),
        editingValueError: null,
        editingMultiline: false,
      };
    }
    let param = (this.props.value || []).map((v) => v);
    if (this.state.editingId !== null) {
      param = param.filter((_, index) => index !== this.state.editingId)
    }
    if (param.filter((v) => v.name === name).length > 0) {
      return {
        editingNameError: intl.formatMessage({ id: 'paramDuplicatedNameError' }),
        editingValueError: null,
        editingMultiline: false,
      };
    }
    const schema = (this.props.schema || []).concat(this.props.systemSchema || []);
    const target = schema.filter((s) => s.name === name);
    if (target.length === 0) {
      return {
        editingNameError: null,
        editingValueError: null,
        editingMultiline: false,
      };
    }
    const targetType = target[target.length - 1].type;
    const targetFormat = target[target.length - 1].format || null;
    const help = target[target.length - 1].help;
    return {
      editingNameError: null,
      editingValueError: this.validateValue(
        targetType, targetFormat, this.state.editingValue, help,
      ),
      editingMultiline: this.isMultiline(targetType, targetFormat),
    };
  }

  isMultiline(type, format) {
    if (type !== 'string') {
      return false;
    }
    return ['json', 'multiline'].includes(format);
  }

  validateSchema() {
    const { paramschema } = this.state;
    if (!paramschema) {
      return {
        valid: false,
      };
    }
    let paramJson = null;
    try {
      paramJson = JSON.parse(paramschema);
    } catch(error) {
      return {
        valid: false,
        error: error.toString(),
      };
    }
    try {
      return {
        valid: validateSchema(paramJson),
      };
    } catch(error) {
      return {
        valid: false,
        error: error.toString(),
      };
    }
  }

  render() {
    const { classes, panelHeight, intl } = this.props;
    const { rows, columns } = this.getDataGrid();
    const panelHeightPx = this.convertVhToPx(panelHeight);
    const {
      editingNameError, editingValueError, editingMultiline,
    } = this.validateEditing();
    const editingHelp = this.getEditingHelp();
    const { valid: schemaValid, error: schemaError } = this.validateSchema();
    return (
      <Container className={classes.root}>
        {this.renderToolButtons()}
        <div style={{ height: `${(panelHeightPx || 400) - 50}px`, width: '100%' }}>
          <DataGrid
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => this.selectionChange(newSelection)}
            onEditCellChangeCommitted={(changes) => this.cellEdit(changes)}
            onRowClick={(params, event) => this.rowClick(params)}
            rows={rows}
            columns={columns}
          />
        </div>
        <Dialog
          fullWidth
          disableBackdropClick
          open={this.state.add}
          onClose={() => this.setState({ add: false })}
        >
          <DialogTitle>
            <FormattedMessage id='paramEditTitle' />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Autocomplete
                freeSolo
                fullWidth
                options={this.getNamesFromSchema()}
                defaultValue={this.state.editingName || ''}
                onChange={(event, value) => this.setState({
                  editingName: value,
                })}
                onInputChange={(event, value) => this.setState({
                  editingName: value,
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={editingNameError !== null}
                    helperText={editingNameError || undefined}
                    label={intl.formatMessage({ id: 'paramName' })}
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                required
                multiline={editingMultiline || ((this.state.editingValue || '').includes('\n'))}
                error={editingValueError !== null}
                helperText={editingValueError || editingHelp}
                label={intl.formatMessage({ id: 'paramValue' })}
                defaultValue={this.state.editingValue || ''}
                onChange={(event) => this.setState({
                  editingValue: event.target.value,
                })}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => this.setState({ add: false })}
            >
              <FormattedMessage id='paramEditCancel' />
            </Button>
            <Button
              color="primary"
              onClick={() => this.add()}
              disabled={!this.state.editingName || editingNameError || editingValueError}
            >
              <FormattedMessage id='paramEditOK' />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          disableBackdropClick
          open={this.state.editSchema}
          onClose={() => this.setState({ editSchema: false })}
        >
          <DialogTitle>
            <FormattedMessage id='paramEditSchemaTitle' />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                autoFocus
                fullWidth
                multiline
                label={intl.formatMessage({ id: 'paramSchema' })}
                defaultValue={this.props.schema ? JSON.stringify(this.props.schema, null, 2) : ''}
                onChange={(event) => this.setState({
                  paramschema: event.target.value,
                })}
              />
              {schemaError && <Typography variant="body1" component="div" className={classes.schemaError}>
                  <FormattedMessage
                      id='paramInvalidSchemaError'
                      values={{ error: schemaError, }}
                    />
                </Typography>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => this.setState({ editSchema: false })}
            >
              <FormattedMessage id='paramEditCancel' />
            </Button>
            <Button
              color="primary"
              disabled={!schemaValid}
              onClick={() => this.editSchema()}
            >
              <FormattedMessage id='paramEditOK' />
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  }
}

export default withStyles(styles)(injectIntl(ParamEditor))
