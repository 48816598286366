import { Component } from "react";
import { injectIntl } from "react-intl";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/styles";

import AnonymousTaskPanel from "./components/AnonymousTaskPanel";
import AnonymousPage from "./components/AnonymousPage";
import AnonymousPanel from "./components/AnonymousPanel";
import Footer from "./components/Footer";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(12),
  },
});

class Anonymous extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: null,
    }
  }

  showError(message, error) {
    if (!this.props.onError) {
      return;
    }
    this.props.onError(message, error);
  }

  taskLoaded(options) {
    if (!this.props.onTaskLoad) {
      return;
    }
    this.props.onTaskLoad(options);
  }

  permissionChanged() {
    if (!this.props.onPermissionChange) {
      return;
    }
    this.props.onPermissionChange();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/t/:taskId">
            <AnonymousTaskPanel
              firestore={this.props.firestore}
              onSignIn={this.props.onSignIn}
              onTaskLoad={(options) => this.taskLoaded(options)}
              onError={(message, error) => this.showError(message, error)}
            >
              <AnonymousPanel
                onSignIn={this.props.onSignIn}
              />
            </AnonymousTaskPanel>
          </Route>
          <Route path="/">
            <AnonymousPage
              onSignIn={this.props.onSignIn}
            />
            <Footer />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default withStyles(styles)(injectIntl(Anonymous));
