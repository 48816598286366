import React, { Component } from "react";
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Container from '@material-ui/core/Container';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import ReactJson from 'react-json-view';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
  },
  list: {
  },
  line: {
    display: 'flex',
    borderTop: '1px solid #ccc',
  },
  level: {
    fontWeight: 'bold',
    marginRight: '1em',
  },
  args: {
    marginLeft: '1em',
  },
  date: {
    marginLeft: 'auto',
  },
  info: {
    color: '#17a2b8',
  },
  debug: {
    color: '#818182',
  },
  error: {
    color: '#dc3545',
  },
  warning: {
    color: '#ffc107',
  },
  noMessages: {
    padding: theme.spacing(2),
  },
});

export const LogLevel = {
  DEBUG: 'Debug',
  INFO: 'Info',
  ERROR: 'Error',
  WARN: 'Warning',
  WARNING: 'Warning',
};

const cache = new CellMeasurerCache({
  defaultWidth: 100,
  minHeight: 20,
});

export function errorToObject(error) {
  return {
    name: error.name,
    message: error.message,
    stack: error.stack || null,
  };
}

export function errorToMessage(error) {
  if (!error.name && !error.message) {
    return '';
  }
  return `${error.name}: ${error.message}`;
}

class Debugger extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => this.scrollToBottom(), 10);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevLines = prevProps.lines || [];
    const lines = this.props.lines || [];
    if (prevLines.length === lines.length) {
      return;
    }
    if (this.listRef.current) {
      this.listRef.current.scrollToRow(lines.length - 1);
    }
    setTimeout(() => this.resizeRow());
  }

  scrollToBottom() {
    const lines = this.props.lines || [];
    if (this.listRef.current) {
      this.listRef.current.scrollToRow(lines.length - 1);
    }
  }

  resizeRow(index) {
    if (index === undefined) {
      cache.clear(index);
    } else {
      cache.clearAll();
    }
    if (this.listRef.current) {
      this.listRef.current.recomputeRowHeights();
    }
  }

  rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
    parent,
  }) {
    const { lines, classes } = this.props;
    const line = lines[index];
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div key={key} style={style} className={`${classes.line} ${classes[line.level.toLowerCase()]}`}>
          <div className={ classes.level }>
            <FormattedMessage id={`logLevel${line.level}`} />
          </div>
          <div>
            {line.message}
          </div>
          {(line.args || [])
            .map((arg, argIndex) =>
              <div
                key={`arg-${argIndex}`}
                onClick={(ev) => this.resizeRow(index)}
                className={ classes.args }
              >
                {typeof arg !== 'object' && arg}
                {typeof arg === 'object' && <ReactJson
                  src={arg}
                  collapsed={true}
                  displayDataTypes={false}
                  displayArrayKey={false}
                />}
              </div>)}
          <div className={ classes.date }>
            <FormattedRelativeTime
              value={parseInt((line.time - Date.now()) / 1000.0)}
            />
          </div>
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { lines, classes, height } = this.props
    return <Container style={{ height }}>
      {(lines || []).length === 0 && <Container className={ classes.noMessages }>
          <FormattedMessage id='noDebugMessages' />
        </Container>}
      {(lines || []).length > 0 && <AutoSizer className={ classes.list }>
        {({height, width}) => (
          <List
            ref={this.listRef}
            width={width}
            height={height}
            rowCount={(lines || []).length}
            deferredMeasurementCache={cache}
            rowHeight={({ index }) => cache.getHeight(index)}
            rowRenderer={(row) => this.rowRenderer(row)}
          />
        )}
      </AutoSizer>}
    </Container>;
  }
}

export default withStyles(styles)(Debugger);
