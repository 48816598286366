// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBOGJAqYf8h11Vzhvnm010UAjjVOdw_R_M",
  authDomain: "goemon.cloud",
  projectId: "cog-pds",
  storageBucket: "cog-pds.appspot.com",
  messagingSenderId: "791374808131",
  appId: "1:791374808131:web:650aa6533cc76ebd5540f6"
};
/*
export const firebaseConfig = {
  apiKey: "AIzaSyBOGJAqYf8h11Vzhvnm010UAjjVOdw_R_M",
  authDomain: "cog-pds.firebaseapp.com",
  projectId: "cog-pds",
  storageBucket: "cog-pds.appspot.com",
  messagingSenderId: "791374808131",
  appId: "1:791374808131:web:650aa6533cc76ebd5540f6"
};
*/