import React, { Component } from 'react'
import clsx from 'clsx'
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Container from '@material-ui/core/Container';

import InfoIcon from '@material-ui/icons/Info';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ReactMarkdown from 'react-markdown';
import { loadTask, mergeElements } from './TaskPanel.js';
import LoadingDialog from "./LoadingDialog";
import Loading from "./Loading";
import VideoAgreement from './agreement/VideoAgreement';
import PDFAgreement from './agreement/PDFAgreement'
import SignAgreement from './agreement/SignAgreement'
import { recordLog } from './datastore/Log.js';

const styles = (theme) => ({
  AgreementRoot: {
    marginTop: '3em',
  },
  TopContainer: {
    marginTop: '5em',
    '& > *': {
      margin: '0.5em',
    },
  },
  Buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      marginRight: '1em',
    },
  },
  AgreementStatus: {
    marginRight: '1em',
  },
  AgreementRequirements: {
    textAlign: 'left',
  },
  AgreementStep: {
    backgroundColor: '#f4f4f4',
    borderRadius: '0.25em',
    border: '1px solid #888',
    padding: '2px 2px 2px 4px',
    marginBottom: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  AgreementStepDescription: {
    marginBottom: '1em',
  },
  AgreementOpen: {
    marginRight: '0.5em',
  },
  AgreementStepDisabled: {
    color: '#ccc',
    borderColor: '#ccc',
  },
  AgreementStepActive: {
    color: '#2E86C1',
    borderColor: '#2E86C1',
    backgroundColor: '#D6EAF8',
  },
});

async function loadAgreement(user, firestore, taskId) {
  const r = await loadTask(user, firestore, taskId);
  const task = r.userTask || r.task;
  const param = mergeElements(task.data.param, r.baseParam, (elem) => elem.name);
  const agreementParam = param.filter((elem) => elem.name === 'GOEMON_AGREEMENT');
  let agreement = null;
  if (agreementParam.length > 0) {
    agreement = JSON.parse(agreementParam[0].value);
  }
  return Object.assign(r, {
    agreement,
  });
}

class Agreement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disagreeDetail: false,
      agreeResult: false,
      disagreeConfirm: false,
      details: {},
    }
  }

  componentDidMount() {
    this.ensureTaskLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureTaskLoaded();
  }

  ensureTaskLoaded() {
    if (this.state.task !== undefined) {
      return;
    }
    if (!this.props.match || !this.props.firestore || !this.props.user) {
      return;
    }
    setTimeout(() => {
      this.setState({
        task: null,
      }, () => {
        const { user, firestore } = this.props;
        loadAgreement(
          user,
          firestore,
          this.props.match.params.taskId,
        )
          .then((loaded) => {
            this.setState({
              task: loaded.task,
              userTask: loaded.userTask,
              baseTask: loaded.baseTask,
              agreement: loaded.agreement,
              userAgreement: loaded.userAgreement,
              needAgreement: !((loaded.userAgreement || {}).exists && loaded.userAgreement.data.agree),
            });
          }).catch((error) => {
            this.showError('errorLoadTask', error)
          });
      })
    }, 10);
  }

  agree() {
    this.saveUserAgreement({
      agree: true,
    }, (userAgreement) => {
      if (!userAgreement) {
        return
      }
      window.scrollTo(0, 0)
      this.setState({
        disagreeDetail: false,
        agreeResult: true,
      })
    })
  }

  disagree() {
    this.setState({
      disagreeing: true,
    }, () => {
      this.saveUserAgreement({
        agree: false,
      }, () => {
        this.setState({
          disagreeing: false,
          disagreeConfirm: false,
        })
      })
    })
  }

  confirmRequirement(requirement, value) {
    const requirements = this.state.agreement.requirements || []
    const oldRequirementInputs = ((this.state.userAgreement || { data: {} }).data || {}).requirements || {}
    const requirementInputs = Object.assign({}, oldRequirementInputs)
    requirementInputs[requirement.id] = value || { updated: Date.now(), }
    const agree = !this.isRequirementDisabled(requirements.length, requirementInputs)
    this.setState({
      confirming: true,
    }, () => {
      this.saveUserAgreement({
        requirements: requirementInputs,
        agree,
      }, (userAgreement) => {
        this.setState({
          confirming: false,
        }, () => {
          if (!userAgreement) {
            return
          }
          if (agree) {
            window.scrollTo(0, 0)
            this.setState({
              disagreeDetail: false,
              agreeResult: true,
            })
          }
        })
      })
    });
  }

  saveUserAgreement(userParams, callback) {
    this.performSaveUserAgreement(userParams)
      .then((userAgreement) => {
        this.setState({
          userAgreement,
          needAgreement: !((userAgreement || {}).exists && userAgreement.data.agree),
        }, () => {
          if (!callback) {
            return;
          }
          callback(userAgreement)
        });
      })
      .catch((error) => {
        this.showError(error)
        if (!callback) {
          return
        }
        callback(null)
      })
  }

  async performSaveUserAgreement(params) {
    const { firestore, intl } = this.props;
    const { agreement, userAgreement } = this.state;
    if (!agreement) {
      throw new Error('No agreements')
    }
    const newData = Object.assign({}, params, { updated: Date.now() })
    await firestore
      .collection('user').doc(userAgreement.userId)
      .collection('agreement').doc(userAgreement.taskId)
      .set(newData)
    if (!params.agree) {
      await Promise.all((agreement.requirements || []).map((requirement) =>
        this.removeAgreementFiles(requirement)))
    }
    const summary = intl.formatMessage({
      id: params.agree ? 'agreementLogSummaryAgreed' : 'agreementLogSummaryAgreementRevoked'
    });
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const task = this.state.userTask || this.state.task;
    const meta = {
      task: { id: taskId, title: task.data.title },
    };
    if ((params.agree || false) !== (((userAgreement || {}).data || {}).agree || false)) {
      await this.performRecordLog(
        `${task.data.title}: ${summary}`,
        {
          meta,
          data: newData,
        },
        'agreement',
      );
    }
    return {
      exists: true,
      userId: userAgreement.userId,
      taskId: userAgreement.taskId,
      data: newData,
    }
  }

  async performRecordLog(summary, log, type) {
    const { user, firestore, storage } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    await recordLog(firestore, storage, user.uid, taskId, summary, log, type);
    let resp = await axios.put(`/api/v1/users/${user.uid}/logs`);
    let count = 3;
    while (resp.status === 200 && (resp.data || { updated: 0 }).updated === 0 && count > 0) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Retrying...', count);
      resp = await axios.put(`/api/v1/users/${user.uid}/logs`);
      count --;
    }
  }

  async removeAgreementFiles(requirement) {
    const { storage } = this.props;
    const { userAgreement } = this.state;
    const path = `user/${userAgreement.userId}/agreement/${userAgreement.taskId}/${requirement.id}`
    const files = await storage.ref(path).listAll()
    await Promise.all(files.items.map((item) => storage.ref(item.fullPath).delete()))
  }

  isRequirementDisabled(requirementIndex, baseRequirementInputs) {
    const requirementInputs = baseRequirementInputs || ((this.state.userAgreement || { data: {} }).data || {}).requirements || {}
    const requirements = this.state.agreement.requirements || []
    return requirements
      .slice(0, requirementIndex)
      .some((requirement) => !requirementInputs[requirement.id])
  }

  toggleDetail(requirement) {
    const details = Object.assign({}, this.state.details);
    details[requirement.id] = !details[requirement.id];
    this.setState({ details, });
  }

  showError(error) {
    if (!this.props.onError) {
      console.error(error)
      return
    }
    this.props.onError(error)
  }

  returnToTask() {
    const { task } = this.state;
    const { history } = this.props;
    history.push(`/t/${task.id}`);
  }

  renderRequirementControl(requirement, confirmed) {
    if (requirement.type === 'video') {
      return (<VideoAgreement
        confirmed={confirmed}
        requirement={requirement}
        onAgree={() => this.confirmRequirement(requirement)}
      />)
    }
    if (requirement.type === 'pdf') {
      return (<PDFAgreement
        confirmed={confirmed}
        requirement={requirement}
        onAgree={() => this.confirmRequirement(requirement)}
      />)
    }
    if (requirement.type === 'sign') {
      return (<SignAgreement
        storage={this.props.storage}
        user={this.props.user}
        agreement={this.state.agreement}
        uploaded={confirmed}
        requirement={requirement}
        onAgree={(value) => this.confirmRequirement(requirement, value)}
        onError={(error) => this.showError(error)}
      />)
    }
    if (confirmed) {
      return null
    }
    return (<Button variant='contained' color='primary' onClick={() => this.confirmRequirement(requirement)}>
      {requirement.confirm || <FormattedMessage id='agreementRequirementConfirm'/>}
    </Button>)
  }

  renderRequirements() {
    const { classes } = this.props;
    const requirementInputs = ((this.state.userAgreement || { data: {} }).data || {}).requirements || {}
    const requirements = this.state.agreement.requirements || []
    return requirements.map((requirement, requirementIndex) => <Container key={requirement.id} className={classes.AgreementRequirements}>
        {!this.isRequirementDisabled(requirementIndex) ? <>
            <h4 className={!requirementInputs[requirement.id] ? clsx(classes.AgreementStepActive, classes.AgreementStep) : classes.AgreementStep}>
              {<span
                data-test-agreement-requirement-toggle={requirement.id}
                className={classes.AgreementOpen}
                onClick={() => this.toggleDetail(requirement)}>
                  {requirementInputs[requirement.id] ? (this.state.details[requirement.id] ? <ArrowDropDownIcon/> : <ArrowRightIcon/>) : <InfoIcon/>}
                </span>}
              {requirement.title}
            </h4>
            {(!requirementInputs[requirement.id] || this.state.details[requirement.id]) && <div className={classes.AgreementStepDescription}>
              <ReactMarkdown>
                {requirement.description}
              </ReactMarkdown>
              {this.renderRequirementControl(requirement, requirementInputs[requirement.id])}
            </div>}
          </> : <h4 className={clsx(classes.AgreementStep, classes.AgreementStepDisabled)}>{requirement.title}</h4>}
      </Container>)
  }

  renderAgree() {
    const { agreement } = this.state;
    const { classes } = this.props;
    return (<Container disableGutters maxWidth='lg' className={classes.TopContainer}>
      <Alert severity='info'>
        <AlertTitle>
          <FormattedMessage id='agreementNeedAgreeHeader' values={{title: agreement.title}}/>
        </AlertTitle>
        <FormattedMessage id='agreementNeedAgreeDescription'/>
      </Alert>
      <ReactMarkdown>
        {agreement.description}
      </ReactMarkdown>
      {agreement.requirements ?
        this.renderRequirements() :
        <Button
          data-test-agree
          variant="contained"
          color="primary"
          onClick={() => this.agree()}
        >
          <FormattedMessage id='agreementAgree'/>
        </Button>}
    </Container>)
  }

  renderDisagree() {
    const { classes } = this.props;
    return (<Container disableGutters maxWidth='lg' className={classes.TopContainer}>
      <Alert severity='success'>
        <AlertTitle>
          <FormattedMessage id='agreementAgreedHeader' values={{title: this.state.agreement.title}}/>
        </AlertTitle>
        <FormattedMessage id='agreementAgreedDescription'/>
      </Alert>
      {this.state.agreement.requirements && this.renderRequirements()}
      <Container className={classes.Buttons}>
        <Button
          data-test-return
          variant="contained"
          onClick={() => this.returnToTask()}
        >
          <FormattedMessage id='agreementReturn'/>
        </Button>
        <Button
          data-test-disagree
          variant="contained"
          color="primary"
          onClick={() => this.setState({ disagreeConfirm: true })}
        >
          <FormattedMessage id='agreementDisagree'/>
        </Button>
      </Container>
    </Container>)
  }

  renderModals() {
    const { intl } = this.props;
    return (<>
      <Dialog
        data-test-disagree-modal-confirm
        open={this.state.disagreeConfirm}
        onClose={e => this.setState({ disagreeConfirm: false })}>
        <DialogTitle>
          <FormattedMessage id='agreementDisagreeConfirmTitle'/>
        </DialogTitle>
        <DialogContent>
          <ReactMarkdown>
            {intl.formatMessage({ id: 'agreementDisagreeConfirmDescription' })}
          </ReactMarkdown>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            data-test-disagree-confirm-cancel
            onClick={e => this.setState({ disagreeConfirm: false })}>
            <FormattedMessage id='agreementDisagreeConfirmCancel'/>
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-test-disagree-confirm-ok
            disabled={this.state.disagreeing}
            onClick={e => this.disagree()}>
            <FormattedMessage id={this.state.disagreeing ? 'agreementDisagreeProcessing' : 'agreementDisagreeConfirmed'}/>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        data-test-agree-modal-confirm
        open={this.state.agreeResult}
        onClose={e => this.setState({ agreeResult: false })}>
        <DialogTitle>
          <FormattedMessage id='agreementAgreeResultTitle'/>
        </DialogTitle>
        <DialogContent>
          <ReactMarkdown>
            {intl.formatMessage({ id: 'agreementAgreeResultDescription' })}
          </ReactMarkdown>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color='primary'
            data-test-agree-confirm-cancel
            onClick={e => this.returnToTask()}>
            <FormattedMessage id='agreementAgreeResultClose'/>
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog processing={this.state.confirming ? 'agreementConfirmProgress' : null} />
    </>)
  }

  render() {
    const { classes } = this.props;
    const { needAgreement, task, agreement } = this.state;
    if (!task) {
      return <Loading/>;
    }
    if (!agreement) {
      return <Container disableGutters className={classes.AgreementRoot}>
        <Container>
          <FormattedMessage id='agreementNoAgreements'/>
        </Container>
        <Button
          data-test-return
          variant="contained"
          onClick={() => this.returnToTask()}
        >
          <FormattedMessage id='agreementReturn'/>
        </Button>
      </Container>
    }
    return <Container disableGutters className={classes.AgreementRoot}>
      {needAgreement && this.renderAgree()}
      {!needAgreement && this.renderDisagree()}
      {this.renderModals()}
    </Container>
  }
}

export default withStyles(styles)(withRouter(injectIntl(Agreement)));
