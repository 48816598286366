import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import MenuIcon from '@material-ui/icons/Menu';

const styles = (theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
    paddingLeft: '0.5em',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '30px',
  },
  avatar: {
    paddingLeft: '0.5em',
  },
  appBarVisible: {
    marginTop: 0,
  },
  appBarHidden: {
    marginTop: '-5em',
  },
});

class AppMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginMenuOpen: false,
    };
    this.loginRef = React.createRef();
  }

  signOut() {
    if (!this.props.onSignOut) {
      return;
    }
    this.props.onSignOut();
  }

  openPersonary() {
    this.setState({
      loginMenuOpen: false,
    });
    if (!this.props.onPersonaryOpen) {
      return;
    }
    this.props.onPersonaryOpen();
  }

  openInquiry() {
    this.setState({
      loginMenuOpen: false,
    });
    if (!this.props.onInquiryOpen) {
      return;
    }
    this.props.onInquiryOpen();
  }

  openDashboard() {
    if (!this.props.onDashboardOpen) {
      return;
    }
    this.props.onDashboardOpen();
  }

  openSettings() {
    if (!this.props.onSettingsOpen) {
      return;
    }
    this.props.onSettingsOpen();
  }

  openUserManual() {
    window.open(this.props.urlUserManual, '_blank');
  }

  openTermsOfUse() {
    window.open(this.props.urlTermsOfUse, '_blank');
  }

  copyTask() {
    this.setState({
      loginMenuOpen: false,
    }, () => {
      if (!this.props.onTaskCopy) {
        return;
      }
      this.props.onTaskCopy();
    });
  }

  distributeTask() {
    this.setState({
      loginMenuOpen: false,
    }, () => {
      if (!this.props.onTaskDistribute) {
        return;
      }
      this.props.onTaskDistribute();
    });
  }

  listKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        loginMenuOpen: false,
      })
    }
  }

  closeLoginMenu() {
    this.setState({
      loginMenuOpen: false,
    })
  }

  render() {
    const { classes, user, hidden } = this.props;
    const appBarMargin = hidden ? classes.appBarHidden : classes.appBarVisible;
    return (
      <AppBar position="absolute" className={clsx(classes.appBar, appBarMargin)}>
        <Toolbar className={classes.toolbar}>
          <Avatar
            alt='GO-E-MON'
            src='/images/icon.png'
            variant='square'
            onClick={() => this.openDashboard()}
          />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <span onClick={() => this.openDashboard()}>
              GO-E-MON
            </span>
          </Typography>
          {user === null && <Typography
            color="inherit"
            noWrap
          >
            <FormattedMessage id='notLoggedIn'/>
          </Typography>}
          {user && <>
              <Typography
                data-testid='appmenu'
                ref={this.loginRef}
                aria-controls={this.state.loginMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                noWrap
                onClick={() => this.setState({ loginMenuOpen: !this.state.loginMenuOpen })}
              >
                <MenuIcon />
              </Typography>
              <Popper open={this.state.loginMenuOpen} anchorEl={this.loginRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => this.closeLoginMenu()}>
                        <MenuList
                          autoFocusItem={this.state.loginMenuOpen}
                          id="menu-list-grow"
                          onKeyDown={(ev) => this.listKeyDown(ev)}
                        >
                          <MenuItem onClick={() => this.openSettings()}>
                            <FormattedMessage id='currentUser'/>
                            : {user.email}
                            {this.props.avatar && <span className={classes.avatar}>
                                {this.props.avatar}
                              </span>}
                          </MenuItem>
                          <MenuItem onClick={() => this.openDashboard()}>
                            <FormattedMessage id='openDashboard'/>
                          </MenuItem>
                          <MenuItem onClick={() => this.openPersonary()}>
                            <FormattedMessage id='openPersonary'/>
                          </MenuItem>
                          {this.props.hasTask && this.props.importable &&
                            <MenuItem onClick={() => this.copyTask()}>
                              <FormattedMessage id='copyTask'/>
                            </MenuItem>}
                          {this.props.hasTask && this.props.distributable &&
                            <MenuItem onClick={() => this.distributeTask()}>
                              <FormattedMessage id='distributeTask'/>
                            </MenuItem>}
                          {this.props.urlUserManual && <MenuItem
                              data-testid='user-manual'
                              onClick={() => this.openUserManual()}
                            >
                              <FormattedMessage id='openUserManual'/>
                            </MenuItem>}
                          {this.props.urlTermsOfUse && <MenuItem
                              data-testid='terms-of-use'
                              onClick={() => this.openTermsOfUse()}
                            >
                              <FormattedMessage id='openTermsOfUse'/>
                            </MenuItem>}
                          <MenuItem
                            data-testid='inquiry'
                            onClick={() => this.openInquiry()}
                          >
                            <FormattedMessage id='openInquiry'/>
                          </MenuItem>
                          <MenuItem onClick={() => this.signOut()}>
                            <FormattedMessage id='signout'/>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(AppMenu);
