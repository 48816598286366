import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  root: {
    '& > *': {
      padding: '0.5em',
    },
  },
  buttonPanel: {
    textAlign: 'right',
  },
})

class NewToken extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      expires: 0,
      creating: false,
      copied: false,
      bearer: null,
    }
  }

  showError(message, error) {
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  changeExpires(event) {
    const value = event.target.value
    this.setState({
      expires: parseInt(value),
    })
  }

  changeName(event) {
    const value = event.target.value
    this.setState({
      name: value,
    })
  }

  keyPress(event) {
    this.changeName(event)
  }

  isFilled() {
    return this.state.name.length > 0;
  }

  create() {
    const name = this.state.name;
    const expiresIn = this.state.expires * 1000 * 60 * 60;
    const scope = ['goemon.task_read', 'goemon.task_write'];
    const { onCreate } = this.props;
    if (!onCreate) {
      return;
    }
    this.setState({
      creating: true,
    }, () => {
      onCreate(
        {
          name,
          expiresIn,
          scope,
        },
        ({ bearer }) => this.setState({
          creating: false,
          bearer,
        }),
      );
    });
  }

  copyToken() {
    navigator.clipboard.writeText(this.state.bearer)
      .then(() => this.setState({
        copied: true
      }))
      .catch(() => {
        this.showError('errorCopyToken');
      });
  }

  render() {
    const { classes, intl } = this.props
    return (
      <Grid
        container
        className={classes.root}
      >
        <Grid item sm={5}>
          <TextField
            variant="outlined"
            label={intl.formatMessage({ id: 'settingsNewTokenLabel' })}
            placeholder={intl.formatMessage({ id: 'settingsNewTokenPlaceholder' })}
            onBlur={(event) => this.changeName(event)}
            onKeyPress={(event) => this.keyPress(event)}
            fullWidth
          />
        </Grid>
        <Grid item sm={5}>
          <FormControl>
            <InputLabel id='token-expiration-label'><FormattedMessage id='settingsNewTokenExpirationLabel'/></InputLabel>
            <Select
              labelId='token-expiration-label'
              value={this.state.expires}
              onChange={(event) => this.changeExpires(event)}
            >
              <MenuItem value={0}>
                <FormattedMessage id='settingsNewTokenExpirationNever' />
              </MenuItem>
              <MenuItem value={1}>
                <FormattedMessage id='settingsNewTokenExpiration1Hour' />
              </MenuItem>
              <MenuItem value={24}>
                <FormattedMessage id='settingsNewTokenExpiration1Day' />
              </MenuItem>
              <MenuItem value={24 * 7}>
                <FormattedMessage id='settingsNewTokenExpiration1Week' />
              </MenuItem>
              <MenuItem value={24 * 30}>
                <FormattedMessage id='settingsNewTokenExpiration30Days' />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={2} className={classes.buttonPanel}>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.isFilled() || this.state.creating}
            onClick={() => this.create()}
          >
            <FormattedMessage id='newToken'/>
          </Button>
        </Grid>
        {this.state.bearer && <>
          <Grid item sm={10}>
            <TextField
              readOnly={true}
              value={this.state.bearer}
              fullWidth
              helperText={intl.formatMessage({ id: this.state.copied ? 'tokenCopied' : 'cantReadToken' })}
            />
          </Grid>
          <Grid item sm={2} className={classes.buttonPanel}>
            <Button
              variant="contained"
              color="default"
              onClick={() => this.copyToken()}
            >
              <FormattedMessage id='copyToken'/>
            </Button>
          </Grid>
        </>}
      </Grid>
    )
  }
}

export default withStyles(styles)(injectIntl(NewToken))
