import { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

const styles = (theme) => ({
  root: {
    minWidth: 192,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  selectedroot: {
    minWidth: 192,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '1px solid #9c27b0',
  },
  cover: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  actions: {
    backgroundColor: '#fff',
  },
  title: {
    height: '1.5em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  description: {
    height: '5.8em',
    overflow: 'auto',
  },
  pos: {
    marginBottom: 12,
  },
  stats: {
    display: 'flex',
    color: '#888',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  statsItem: {
    display: 'flex',
    color: '#888',
    alignItems: 'center',
    padding: 0,
    whiteSpace: 'nowrap',
  },
});

class TaskCard extends Component {
  constructor(props) {
    super(props);

    this.state = { performing: false };
  }

  cancel() {
    if (!this.props.onCancel) {
      return;
    }
    this.props.onCancel();
  }

  perform() {
    if (!this.props.onPerform) {
      return;
    }
    this.setState({
      performing: true,
    }, () => {
      this.props.onPerform(() => {
        this.setState({
          performing: false,
        });
      });
    });
  }

  open() {
    if (this.props.selectable) {
      if (!this.props.onSelect) {
        return;
      }
      this.props.onSelect(this.props.task);
      return;
    }
    if (!this.props.onOpen) {
      return;
    }
    this.props.onOpen(this.props.task);
  }

  render() {
    const { task, classes, editable, selectable, selected } = this.props;
    const backgroundStyle = {};
    if (task.hasThumbnail) {
      backgroundStyle.backgroundImage = `url("${task.hasThumbnail}")`;
    }
    return (
      <Card className={selected ? classes.selectedroot : classes.root} style={backgroundStyle}>
        <CardContent className={classes.cover}>
          <Typography
            variant="h5"
            component="h2"
            className={classes.title}
            title={task.data.title}
            onClick={() => this.open()}
          >
            {task.data.title}
          </Typography>
          <ReactMarkdown className={classes.description}>
            {(task.data.description || '').split(/\r?\n/).filter((line) => line.length > 0)[0]}
          </ReactMarkdown>
          <Container className={classes.stats}>
            {task.data.stats && task.data.stats.users !== undefined &&
              <Container className={classes.statsItem}>
                <PeopleIcon />
                {task.data.stats.users || '-'}
              </Container>}
            {task.data.stats && task.data.stats.count !== undefined &&
              <Container className={classes.statsItem}>
                <EqualizerIcon />
                {task.data.stats.count || '-'}
              </Container>}
            {!editable && task.data.started &&
              <Container className={classes.statsItem}>
                <CheckIcon />
                {moment(new Date(task.data.started)).fromNow()}
              </Container>}
            {editable && !task.data.shared && task.data.updated &&
              <Container className={classes.statsItem}>
                <EditIcon />
                {moment(new Date(task.data.updated)).fromNow()}
              </Container>}
            {editable && task.data.shared &&
              <Container className={classes.statsItem}>
                <PeopleIcon />
                {moment(new Date(task.data.shared)).fromNow()}
              </Container>}
          </Container>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            size="small"
            color="primary"
            onClick={() => this.open()}
            disabled={selectable && selected}
          >
            {editable && <>
                <EditIcon />
                <FormattedMessage id="editTask" />
              </>}
            {selectable && <>
                <EditIcon />
                <FormattedMessage id="selectTask" />
              </>}
            {!editable && !selectable && <>
                <CheckIcon />
                <FormattedMessage id='openTask' />
              </>}
          </Button>
        </CardActions>
      </Card>
    )
  }
}

export default withStyles(styles)(TaskCard)
