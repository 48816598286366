import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  footer: {
    fontSize: '1em',
    borderTop: '#ccc solid 1px',
    textAlign: 'center',
    marginTop: '2em',
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  separator: {
    paddingLeft: '1em',
    paddingRight: '1em',
  }
});

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return <div className={classes.footer}>
      <div>
        Copyright(C) 2021-2024 Satoshi Yazawa / Hiraki Lab.
        <span className={classes.separator}>|</span>
        <a href='https://scrapbox.io/cogtask-me/About_GO-E-MON'>
          <FormattedMessage id='about'/>
        </a>
      </div>
    </div>
  }
}

export default withStyles(styles)(Footer);
