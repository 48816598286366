import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DataGrid } from '@material-ui/data-grid';
import Dropzone from 'react-dropzone';
import humanize from 'humanize';
import ClipLoader from "react-spinners/ClipLoader";

const DEFAULT_SIZE_CAPACITY = 1024 * 1024 * 10;

const styles = (theme) => ({
  container: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'sans-serif',
    '> p': {
      fontSize: '1rem',
    },
    '> em': {
      fontSize: '.8rem',
    },
  },
  tool: {
    display: 'flex',
    padding: '0.5em',
  },
  importTypeEditor: {
    minWidth: 120,
  },
});

const importTypes = [
  {
    id: 'javascript',
    message: 'importTypeJavaScript',
  },
  {
    id: 'css',
    message: 'importTypeCSS',
  },
  {
    id: 'image',
    message: 'importTypeImage',
  },
];

class FileListEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      loading: false,
      uploadNotPermitted: false,
    };
  }

  filesDropped(files) {
    if (!this.canUpload(files)) {
      this.setState({
        uploadNotPermitted: true,
      });
      return;
    }
    if (!this.props.onDrop) {
      return;
    }
    this.setState({
      loading: true,
    }, () => {
      this.props.onDrop(files, () => {
        this.setState({ loading: false });
      });
    });
  }

  renderImportTypeEditorCell(params) {
    const { classes } = this.props;
    if (!this.props.editable) {
      const filteredImportTypes = importTypes.filter((importType) => importType.id === params.value);
      if (filteredImportTypes.length === 0) {
        return <div><FormattedMessage id='importTypeGeneric' /></div>
      }
      return <div>
        <FormattedMessage id={filteredImportTypes[0].message} />
      </div>
    }
    return <div>
      <Select
        value={params.value || ''}
        displayEmpty
        onChange={(event) => {
          console.log('Change', params.id, event.target.value);
          const id = params.id;
          const field = 'importType';
          params.api.setCellMode(id, field, 'edit');
          params.api.commitCellChange({
            id,
            field,
            props: {
              value: event.target.value,
            },
          });
          params.api.setCellMode(id, field, 'view');
          this.cellEdit({
            id,
            field,
            props: {
              value: event.target.value,
            },
          });
        }}
        className={classes.importTypeEditor}
      >
        {importTypes.map((importType) => <MenuItem value={importType.id}>
            <FormattedMessage id={importType.message} />
          </MenuItem>)}
        <MenuItem value={''}>
          <FormattedMessage id='importTypeGeneric' />
        </MenuItem>
      </Select>
    </div>
  }

  getDataGrid() {
    const { intl } = this.props;
    const rows = (this.props.files || []).map((file) => Object.assign({
      id: file.id,
      priority: 100,
    }, file.data));
    const columns = [
      {
        field: 'preload',
        headerName: intl.formatMessage({ id: 'columnPreload' }),
        width: 64,
        type: 'boolean',
        editable: true,
      },
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'columnFilename' }),
        width: 300,
        editable: this.props.editable || false,
      },
      {
        field: 'size',
        headerName: intl.formatMessage({ id: 'columnSize' }),
        width: 150,
        valueFormatter: (params) => humanize.filesize(params.value),
      },
      {
        field: 'lastModifiedDateTime',
        headerName: intl.formatMessage({ id: 'columnModified' }),
        width: 150,
        type: 'dateTime',
        valueGetter: (params) => new Date(params.getValue('created')),
      },
      {
        field: 'priority',
        headerName: intl.formatMessage({ id: 'columnPriority' }),
        width: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'importType',
        headerName: intl.formatMessage({ id: 'columnImportType' }),
        width: 150,
        editable: this.props.editable || false,
        valueFormatter: (params) => params.value,
        renderCell: (params) => this.renderImportTypeEditorCell(params),
      },
      {
        field: 'download',
        headerName: intl.formatMessage({ id: 'columnDownload' }),
        width: 50,
        valueFormatter: (params) => params.value,
        renderCell: (params) => this.renderDownloadCell(params),
      },
    ];
    return { rows, columns };
  }

  renderDownloadCell(params) {
    return <GetAppIcon onClick={() => {
      window.location.assign(this.getFileURLFromFile(params));
    }}/>;
  }

  getFileURLFromFile(file) {
    const url = `${this.props.baseURL || ''}/api/v1/${this.props.baseFilePath}files/${file.id}`;
    return url;
  }

  convertVhToPx(height) {
    if (!height) {
      return undefined;
    }
    if (height.toString().match(/^[0-9]+$/)) {
      return parseInt(height);
    }
    const m = height.toString().match(/^([0-9]+)vh$/);
    if (m) {
      return parseInt(document.documentElement.clientHeight * parseInt(m[1]) / 100.0);
    }
    return undefined;
  }

  deleteFiles() {
    if (!this.props.onDelete) {
      return;
    }
    this.setState({
      loading: true,
    }, () => {
      this.props.onDelete(this.state.selectedFiles, () => {
        this.setState({ loading: false });
      });
    });
  }

  selectionChange(newSelection) {
    this.setState({
      selectedFiles: newSelection.selectionModel,
    });
  }

  cellEdit(changes) {
    const data = {};
    console.log('Changes', changes);
    if (changes.field === 'name' || changes.field === 'importType' ||
      changes.field === 'preload' || changes.field === 'priority') {
      data[changes.field] = changes.props.value;
    } else {
      return;
    }
    if (!this.props.onEdit) {
      return;
    }
    this.setState({
      loading: true,
    }, () => {
      this.props.onEdit({
        id: changes.id,
        data,
      }, () => {
        this.setState({ loading: false });
      });
    });
  }

  renderToolButtons() {
    const { classes } = this.props;
    return <Container className={classes.tool}>
      {this.state.loading && <ClipLoader />}
      {this.props.editable && <Button
          variant="contained"
          color="secondary"
          disabled={(this.state.selectedFiles || []).length === 0}
          onClick={() => this.deleteFiles()}
        >
          <DeleteIcon />
          <FormattedMessage id='fileDelete' />
        </Button>}
    </Container>
  }

  canUpload(files) {
    if (!this.props.sizeLimit) {
      return true;
    }
    const size = files.map((file) => file.size).reduce((x, y) => x + y, 0);
    const { current, capacity } = this.props.sizeLimit;
    if ((current || 0) + size >= (capacity || DEFAULT_SIZE_CAPACITY)) {
      return false;
    }
    return true;
  }

  closeNotPermittedDialog() {
    this.setState({
      uploadNotPermitted: false,
    });
  }

  render() {
    const { classes, panelHeight } = this.props;
    const { rows, columns } = this.getDataGrid();
    const panelHeightPx = this.convertVhToPx(panelHeight);
    return (
      <Container className={classes.root}>
        {this.props.editable && <Dropzone onDrop={(files) => this.filesDropped(files)}>
            {({getRootProps, getInputProps}) => (
              <section className={classes.container}>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <p><FormattedMessage id='dropZoneArea' /></p>
                </div>
              </section>
            )}
          </Dropzone>}
        {this.renderToolButtons()}
        <div style={{ height: `${(panelHeightPx || 400) - 200}px`, width: '100%' }}>
          <DataGrid
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => this.selectionChange(newSelection)}
            onEditCellChangeCommitted={(changes) => this.cellEdit(changes)}
            rows={rows}
            columns={columns}
          />
        </div>
        <Dialog
          open={this.state.uploadNotPermitted}
          onClose={() => this.closeNotPermittedDialog()}
        >
          <DialogTitle>
            <FormattedMessage id='uploadNotPermittedTitle' />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id='uploadNotPermittedContent' values={{
                current: humanize.filesize((this.props.sizeLimit || {}).current || 0),
                capacity: humanize.filesize((this.props.sizeLimit || {}).capacity || DEFAULT_SIZE_CAPACITY),
              }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeNotPermittedDialog()} color="secondary">
              <FormattedMessage id='uploadNotPermittedOK' />
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  }
}

export default withStyles(styles)(injectIntl(FileListEditor))
