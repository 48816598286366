import React, { Component } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImageUploading from 'react-images-uploading';
import { Document, Page } from 'react-pdf'
import SignatureCanvas from 'react-signature-canvas'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactMarkdown from 'react-markdown';
import { styles as pdfStyles } from './PDFAgreement';

const styles = (theme) => Object.assign({
  SignAgreementDocument: {
    '& > canvas': {
      border: '1px solid #444',
    },
  },
  SignInput: {
    justifyContent: 'center',
    padding: '1em',
  },
  SignAgreementControls: {
    padding: '1em',
  },
  SignAgreementAgreed: {
    margin: 'auto',
  },
  SignAgreementControlsRow: {
    justifyContent: 'center',
  },
  SignCanvasContainerCol: {
    borderBottom: '1px solid #555',
  },
  SignCanvasContainer: {
    position: 'relative',
    '& canvas': {
      maxWidth: 'none',
    },
  },
  SignCanvasModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 2000,
  },
  SignCanvasDescription: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  SignCanvasButtons: {
    textAlign: 'center',
  },
  PhotoUploader: {
    '& > button': {
      marginRight: '1em',
    },
  },
}, pdfStyles(theme));

const TAB_TOUCH = 0;
const TAB_PHOTO = 1;

class SignAgreement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pageNumber: null,
      numPages: null,
      mode: TAB_TOUCH,
      signature: null,
      signatureBuffers: {},
      uploaded: null,
      windowSize: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
    }
    this.rootRef = React.createRef()
    this.signatureCanvasRef = React.createRef()
    this.handleResize = () => this.setState({
      windowSize: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
    })
  }

  componentDidMount() {
    if (this.props.storage && this.state.uploaded === null) {
      this.loadFiles()
    }
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate() {
    if (this.props.storage && this.state.uploaded === null) {
      this.loadFiles()
    }
  }

  loadFiles() {
    this.performLoadFiles()
      .then((uploaded) => {
        this.setState({ uploaded, })
      })
      .catch((error) => this.showError(error))
  }

  isFilesUploaded() {
    const { uploaded } = this.state
    if (!uploaded) {
      return false
    }
    if (this.state.mode === TAB_PHOTO &&
      Object.keys(uploaded).filter((key) => key.startsWith('photo-')).length > 0) {
      return true
    }
    if (this.state.mode === TAB_TOUCH) {
      return (this.props.requirement.subjects ||[]).every((subject) => uploaded[`touch-${subject}.dat`])
    }
    return false
  }

  confirmRequirement() {
    if (!this.props.onAgree) {
      return;
    }
    const files = Object.keys(this.state.uploaded)
      .filter((key) => key.startsWith(this.state.mode))
      .map((key) => this.state.uploaded[key].path)
    this.props.onAgree({ files, mode: this.state.mode, updated: Date.now(), })
  }

  processFiles(pictureFiles, pictureDataURLs) {
    this.uploadFiles(pictureFiles)
      .then((files) => {
        const uploaded = {}
        Object.keys(this.state.uploaded || {})
          .filter((key) => !key.startsWith('photo-'))
          .forEach((key) => {
            uploaded[key] = this.state.uploaded[key]
          })
        files.forEach((file) => {
          const path = file.path.split('/')
          uploaded[path[path.length - 1]] = file
        })
        this.setState({ uploaded, })
      })
      .catch((error) => this.showError(error))
  }

  showError(error) {
    if (!this.props.onError) {
      console.error(error)
      return
    }
    this.props.onError(error)
  }

  async performLoadFiles() {
    const { storage, user, agreement, requirement } = this.props
    const path = `user/${user.uid}/agreement/${agreement.id}/${requirement.id}`
    const files = await storage.ref(path).listAll()
    const urls = await Promise.all(files.items.map((item) => storage.ref(item.fullPath).getDownloadURL()))
    const uploaded = {}
    files.items.forEach((item, index) => {
      uploaded[item.name] = { path: item.fullPath, url: urls[index] }
    })
    return uploaded
  }

  async uploadFiles(pictureFiles) {
    return await Promise.all(pictureFiles.map((pictureFile, index) => this.uploadFile(pictureFile, index)))
  }

  async uploadFile(pictureFile, index) {
    const { storage, user, agreement, requirement } = this.props
    const path = `user/${user.uid}/agreement/${agreement.id}/${requirement.id}/photo-${index}.dat`
    await storage
      .ref(path)
      .put(pictureFile, {
        contentType: pictureFile.type
      })
    const url = await storage.ref(path).getDownloadURL()
    return { path, url }
  }

  async uploadSignature(subject, dataURL) {
    const { storage, user, agreement, requirement } = this.props
    const path = `user/${user.uid}/agreement/${agreement.id}/${requirement.id}/touch-${subject}.dat`
    await storage
      .ref(path)
      .putString(dataURL, 'data_url')
    const url = await storage.ref(path).getDownloadURL()
    return { path, url }
  }

  finishSignature() {
    if (!this.signatureCanvasRef.current) {
      throw new Error('Invalid state')
    }
    const dataURL = this.signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
    this.uploadSignature(this.state.signature, dataURL)
      .then((file) => {
        const signatureBuffers = Object.assign({}, this.state.signatureBuffers)
        signatureBuffers[this.state.signature] = dataURL
        const uploaded = Object.assign({}, this.state.uploaded || {})
        const path = file.path.split('/')
        uploaded[path[path.length - 1]] = file
        this.setState({
          signature: null,
          signatureBuffers,
          uploaded,
        })
      })
      .catch((error) => this.showError(error))
  }

  getUploadedSignature(subject) {
    const { uploaded } = this.state
    if (!uploaded) {
      return null
    }
    const file = uploaded[`touch-${subject}.dat`]
    if (!file) {
      return null
    }
    return file.url
  }

  getCanvasProps() {
    const width = parseInt(this.state.windowSize.innerWidth * 0.95)
    const height = parseInt(this.state.windowSize.innerHeight * 0.95) - 36
    return { width, height, style: { width: `${width}px`, height: `${height}px` } }
  }

  renderSignatureCanvas() {
    const { requirement, classes, intl } = this.props
    return ((requirement.subjects || []).map((subject) => <Grid container className={classes.SignInput}>
      <Grid item sm='auto' className='align-self-end'>
        <h5>{subject}</h5>
      </Grid>
      <Grid item sm={8} className={classes.SignCanvasContainerCol}>
        {this.state.signature !== subject && (this.state.signatureBuffers[subject] || this.getUploadedSignature(subject)) &&
          <img src={this.state.signatureBuffers[subject] || this.getUploadedSignature(subject)} alt={intl.formatMessage({ id: 'agreementSignImage' })} />}
      </Grid>
      <Grid item sm='auto' className='align-self-end'>
        {!this.state.signature && !this.props.uploaded && <Button
            data-test-sign-agreement-input={subject}
            variant='contained'
            onClick={() => this.setState({
              signature: subject,
              signatureFilled: false,
            })}
          >
            <FormattedMessage id='agreementSignWriteByTouchpanel' />
          </Button>}
      </Grid>
      {this.state.signature === subject && this.renderSignatureModal(subject)}
    </Grid>))
  }

  renderSignatureModal(subject) {
    const { classes } = this.props;
    return (<div className={classes.SignCanvasModal}>
      <div className={classes.SignCanvasDescription}>
        <FormattedMessage id='agreementSignTouchInputDescription' values={{ subject, }}/>
      </div>
      <div className={classes.SignCanvasContainer}>
        <SignatureCanvas
          ref={this.signatureCanvasRef}
          penColor='black'
          canvasProps={this.getCanvasProps()}
          clearOnResize={false}
          onEnd={() => this.setState({
            signatureFilled: true,
          })}
        />
      </div>
      <div className={classes.SignCanvasButtons}>
        <Button
            data-test-sign-agreement-cancel={subject}
            variant='contained'
            onClick={() => this.setState({
              signature: null,
            })}
          >
          <FormattedMessage id='agreementSignTouchInputCancel'/>
        </Button>
        <Button
            data-test-sign-agreement-complete={subject}
            variant='contained'
            color='primary'
            disabled={!this.state.signatureFilled}
            onClick={() => this.finishSignature()}
          >
            <FormattedMessage id='agreementSignTouchInputComplete'/>
        </Button>
      </div>
    </div>)
  }

  renderUploadedPhoto() {
    const { uploaded } = this.state
    if (!uploaded) {
      return null
    }
    const keys = Object.keys(uploaded).filter((key) => key.startsWith('photo-'))
    if (keys.length === 0) {
      return null
    }
    return <img src={uploaded[keys[0]].url} alt='uploaded' width='300' />
  }

  getUploadedMode() {
    if (!this.props.uploaded) {
      return null
    }
    return this.props.uploaded.mode
  }

  renderImageUploader() {
    const { classes } = this.props;
    return <ImageUploading
      value={this.state.images}
      onChange={(images, imageIndex) => {
        this.setState({ images, }, () => {
          const pictureFiles = (images || []).map((image) => image.file);
          const pictureDataURLs = (images || []).map((image) => image.dataUrl);
          this.processFiles(pictureFiles, pictureDataURLs);
        });
      }}
      dataURLKey="dataUrl"
      maxFileSize={52428800}
      acceptType={["jpg", "jpeg", "gif", "png", "gif"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div className={classes.PhotoUploader}>
          <Button
            variant='contained'
            color='primary'
            style={isDragging ? { color: 'red' } : undefined}
            onClick={onImageUpload}
            {...dragProps}
          >
            <FormattedMessage id='agreementSignUploadButton' />
          </Button>
          <Button
            variant='contained'
            onClick={onImageRemoveAll}>
            <FormattedMessage id='agreementSignCancelButton' />
          </Button>
        </div>
      )}
    </ImageUploading>
  }

  render() {
    const { requirement, uploaded, classes, intl } = this.props
    return <div className={classes.PDFAgreementRoot} ref={this.rootRef}>
      <Tabs
        value={this.getUploadedMode() || this.state.mode}
        onChange={(event, key) => this.setState({ mode: key })}
      >
        <Tab
          label={intl.formatMessage({ id: 'agreementSignTabTouch' })}
        />
        <Tab
          label={intl.formatMessage({ id: 'agreementSignTabPhoto' })}
          disabled={this.getUploadedMode() && this.getUploadedMode() !== 'photo'}
        />
      </Tabs>
      {this.state.mode === 0 && <>
        <FormattedMessage id='agreementSignTouchDescription' />
        <Document
          file={requirement.src}
          onLoadSuccess={({ numPages }) => this.setState({
            numPages,
            pageNumber: 1,
            pageWidth: this.rootRef.current.clientWidth * 0.8,
          })}
          disabled={this.getUploadedMode() && this.getUploadedMode() !== 'touch'}
        >
          <Page
            className={classes.SignAgreementDocument}
            pageNumber={this.state.pageNumber}
            width={this.state.pageWidth}
          />
        </Document>
        {this.state.numPages && <div className={classes.PDFAgreementPages}>
            <ArrowBackIosIcon
              className={clsx(classes.PDFPageControl, classes.PDFPagePrevious)}
              onClick={() => this.setState({
                pageNumber: Math.max(this.state.pageNumber - 1, 1),
              })}
            />
            {this.state.pageNumber} / {this.state.numPages}
            <ArrowForwardIosIcon
              className={clsx(classes.PDFPageControl, classes.PDFPageNext)}
              onClick={() => this.setState({
                pageNumber: Math.min(this.state.pageNumber + 1, this.state.numPages),
              })}
            />
          </div>}
        {requirement.agreement && <ReactMarkdown>{requirement.agreement}</ReactMarkdown>}
        {this.renderSignatureCanvas()}
      </>}
      {this.state.mode === 1 && <>
        <ReactMarkdown>
          {intl.formatMessage({
            id: 'agreementSignPhotoDescription',
          }, {
            src: requirement.src,
            subjects: (requirement.subjects || []).join(', '),
          })}
        </ReactMarkdown>
        {this.renderImageUploader()}
        {this.renderUploadedPhoto()}
      </>}
      <div className={classes.SignAgreementControls}>
        {uploaded && <div className={classes.SignAgreementAgreed}>
            <FormattedMessage id='agreementRequirementConfirmed' />
          </div>}
        {!uploaded && this.isFilesUploaded() && !this.state.signature &&
          <Grid container className={classes.SignAgreementControlsRow}>
            <Grid item sm={10}>
              <FormattedMessage id='agreementSignDescription' />
            </Grid>
            <Grid item sm='auto'>
              <Button
                data-test-sign-agreement-confirm
                variant='contained'
                color='primary'
                onClick={() => this.confirmRequirement()}
              >
                <FormattedMessage id='agreementRequirementConfirm' />
              </Button>
            </Grid>
          </Grid>}
      </div>
    </div>
  }
}

export default withStyles(styles)(injectIntl(SignAgreement));
