import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge';
import ClipLoader from "react-spinners/ClipLoader";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from './Loading';
import Author, {
  validateContact, validateNickname, validateAffiliation,
  validateTwitter, validateFacebook,
} from './Author';
import Tokens from './Tokens';
import BeforeUnloadComponent from 'react-beforeunload-component';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    textAlign: 'left',
  },
  description: {
    padding: '1em',
  },
  sectionHeader: {
    paddingTop: '1.5em',
    fontSize: '1.5em',
  },
  sectionSubHeader: {
    paddingTop: '0.8em',
    fontSize: '1.25em',
  },
  header: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  valuePanel: {
    padding: '0.5em 0 0.5em 2em',
  },
  avatarLangs: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  talkusers: {
    padding: '1em',
  },
  talkuserInfo: {
    display: 'flex',
    paddingLeft: '1em',
  },
  talkuserButton: {
    textAlign: 'right',
  },
  talkuserName: {
    fontSize: '1.5em',
    paddingRight: '1em',
  },
});

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadStarted: false,
      garminEnabled: null,
      tokens: null,
      talkusers: null,
      activatedTalkuser: null,
      activatedHealthuser: null,
      disconnectingTalkuser: null,
      disconnectingHealthuser: null,
    }
  }

  componentDidMount() {
    this.ensureLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureLoaded();
  }

  showError(message, error) {
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  ensureLoaded() {
    if (this.state.loadStarted) {
      return;
    }
    if (!this.props.firestore || !this.props.user) {
      return;
    }
    setTimeout(() => {
      this.setState({
        loadStarted: true,
      }, () => {
        this.load()
          .then((loaded) => {
            const query = queryString.parse(window.location.search);
            this.setState({
              garminEnabled: query.garmin !== undefined || loaded.garminEnabled || false,
              garminUserId: loaded.garminUserId,
              userInfo: loaded.userInfo,
              tokens: loaded.tokens,
              talkusers: loaded.talkusers,
              activatedTalkuser: loaded.activatedTalkuser,
              activatedHealthuser: loaded.activatedHealthuser,
            });
          }).catch((error) => {
            this.showError('errorLoadSettings', error)
          });
      })
    }, 10);
  }

  async load() {
    const { firestore, user } = this.props;
    const query = queryString.parse(window.location.search);
    let activatedHealthuser = null;
    if (query['garmin-user']) {
      await firestore.collection('user').doc(user.uid).update({
        garminUserId: query['garmin-user'],
      });
      activatedHealthuser = {
        id: query['garmin-user'],
        data: {
          type: 'garmin',
        },
      };
    }
    let activatedTalkuser = null;
    if (query['talkuser']) {
      activatedTalkuser = await this.performActivateTalkuser(
        query['talkuser'],
        query['type'],
        query['t'],
        true,
      );
    }
    const url = new URL(window.location.href);
    delete query['garmin-user'];
    delete query['talkuser'];
    delete query['type'];
    delete query['t'];
    delete query['name'];
    url.search = Object.keys(query).length > 0 ? ('?' + queryString.stringify(query)) : '';
    window.history.pushState(null, null, url.toString());

    const permissionRef = await firestore.collection('permission').doc(user.uid).get();
    const userRef = await firestore.collection('user').doc(user.uid).get();
    const garminUserId = userRef.exists ? (userRef.data().garminUserId || null) : null;
    const userInfoRef = await firestore.collection('userinfo').doc(user.uid).get();
    const userInfo = userInfoRef.exists ? userInfoRef.data() : {
      avatar: { photoURL: user.photoURL },
      nickname: { en: user.displayName },
      affiliation: {},
    };
    const tokens = await this.loadTokens();
    const talkusers = await this.loadTalkusers();
    const baseResult = { userInfo, garminUserId, tokens, talkusers, activatedTalkuser, activatedHealthuser };
    if (!permissionRef.exists) {
      return Object.assign(baseResult, { garminEnabled: false });
    }
    return Object.assign(baseResult, permissionRef.data());
  }

  async loadTokens() {
    const { firestore, user } = this.props;
    const tokensRef = await firestore.collection('user').doc(user.uid).collection('token').get();
    const tokens = tokensRef.empty ? [] : tokensRef.docs
      .filter((tokenRef) => !tokenRef.data().deleted &&
        (tokenRef.data().expiresAt === null || tokenRef.data().expiresAt > Date.now()))
      .map((tokenRef) => ({
        id: tokenRef.id,
        data: tokenRef.data(),
      }));
    return tokens;
  }

  async loadTalkusers() {
    const { firestore, user } = this.props;
    const talkuserRef = await firestore.collection('user').doc(user.uid).collection('talkuser').get();
    const talkusers = talkuserRef.empty ? [] : talkuserRef.docs
      .filter((talkuserRef) => talkuserRef.data().name && talkuserRef.data().connected)
      .map((talkuserRef) => ({
        id: talkuserRef.id,
        data: talkuserRef.data(),
      }));
    return talkusers;
  }

  async performActivateTalkuser(talkuserId, type, token, connected) {
    const { firestore, user } = this.props;
    const talkuserDoc = firestore
      .collection('user').doc(user.uid)
      .collection('talkuser').doc(talkuserId);
    let talkuserRef = await talkuserDoc.get();
    if (!talkuserRef.exists) {
      if (!type || !token) {
        throw new Error('Type or token not set');
      }
      const created = Date.now();
      await talkuserDoc.set({
        created,
        updated: created,
        type,
        token: token,
        connected,
      });
    } else {
      await talkuserDoc.update({
        updated: Date.now(),
        connected,
      });
    }
    await axios.post(`/api/v1/users/${user.uid}/talkusers/${talkuserId}`);
    talkuserRef = await talkuserDoc.get();
    if (!talkuserRef.exists) {
      throw new Error(`Talkuser not exists: ${talkuserId}`)
    }
    if (connected) {
      await this.sendWelcomeMessage(talkuserId, type);
    }
    return {
      id: talkuserRef.id,
      data: talkuserRef.data(),
    };
  }

  async sendWelcomeMessage(talkuserId, type) {
    if (type !== 'line') {
      // unsupported
      return;
    }
    const { firestore, user, intl } = this.props;
    const lastContextRefs = await firestore
      .collection('user')
      .doc(user.uid)
      .collection('context')
      .where('talkuserRequest', '==', type)
      .orderBy('updated', 'desc')
      .limit(1)
      .get();
    let messageText = null;
    if (!lastContextRefs.empty && lastContextRefs.docs[0].data().talkuserId === null) {
      messageText = intl.formatMessage(
        { id: 'welcomeTalkuserMessageWithTask' },
        {
          taskId: lastContextRefs.docs[0].id,
          type,
        },
      );
    } else {
      messageText = intl.formatMessage(
        { id: 'welcomeTalkuserMessage' },
        {
          type,
        });
    }
    const message = {
      type: 'text',
      text: messageText,
    };
    const created = Date.now();
    const added = await firestore
      .collection('user').doc(user.uid)
      .collection('talkuser').doc(talkuserId)
      .collection('message').add({
        created,
        updated: created,
        sent: false,
        message: JSON.stringify(message),
      });
    await axios.post(`/api/v1/users/${user.uid}/talkusers/${talkuserId}/messages/${added.id}`);
  }

  startGarminAuth() {
    const { user } = this.props;
    this.prepareGarminAuth()
      .then(() => {
        window.location.href = `/health/garmin/auth?user=${user.uid}`;
      }).catch((error) => {
        this.showError('errorPrepareGarminAuth', error)
      });
  }

  disconnectHealthuser(healthuser) {
    const { type } = healthuser.data;
    if (type !== 'garmin') {
      throw new Error(`Unexpected type: ${type}`);
    }
    this.disconnectGarminAuth();
  }

  disconnectGarminAuth() {
    this.performDisconnectGarminAuth()
      .then(() => {
        this.setState({
          garminUserId: null,
          disconnecting: false,
          disconnectingHealthuser: null,
      });
      }).catch((error) => {
        this.showError('errorDisconnectGarminAuth', error)
      });
  }

  async performDisconnectGarminAuth() {
    const { firestore, user } = this.props;
    const docRef = firestore.collection('user').doc(user.uid);
    const userRef = await docRef.get();
    if (!userRef.exists) {
      return;
    }
    await docRef.update({
      healthUpdated: Date.now(),
      garminUserId: null,
    });
  }

  async prepareGarminAuth() {
    const { firestore, user } = this.props;
    const docRef = firestore.collection('user').doc(user.uid);
    const userRef = await docRef.get();
    const params = {
      healthUpdated: Date.now(),
    };
    if (!userRef.exists) {
      docRef.set(params);
      return;
    }
    await docRef.update(params);
  }

  getHealthuserMessageValues(healthuser) {
    if (!healthuser) {
      return {
        type: '',
        name: '',
      };
    }
    const TYPES = {
      garmin: 'Garmin Connect',
    };
    const { type, name } = healthuser.data;
    return {
      type: TYPES[type],
      name,
    };
  }

  hasNoChanges(oldValue, newValue) {
    if (oldValue === newValue) {
      return true;
    }
    if (!oldValue && !newValue) {
      return true;
    }
    return false;
  }

  changeUserProperty(property, lang, value) {
    const { userInfo } = this.state;
    const hasOldValue = userInfo && userInfo[property] && userInfo[property][lang];
    const oldValue = hasOldValue ? userInfo[property][lang] : null;
    const stateChanges = {};
    stateChanges[`${property}-${lang}-changed`] = this.hasNoChanges(oldValue, value) ? undefined : value;
    this.setState(stateChanges);
  }

  isDirty() {
    return Object.keys(this.state)
      .filter((key) => key.endsWith('-changed'))
      .filter((key) => this.state[key] !== undefined).length > 0;
  }

  getCurrentUserInfo() {
    const userInfo = Object.assign({}, this.state.userInfo || {});
    userInfo.nickname = Object.assign({}, userInfo.nickname || {});
    userInfo.affiliation = Object.assign({}, userInfo.affiliation || {});
    userInfo.contact = Object.assign({}, userInfo.contact || {});
    Object.keys(this.state).filter((key) => key.endsWith('-changed')).forEach((key) => {
      const [ property, lang ] = key.split('-');
      if (this.state[key] === undefined) {
        return;
      }
      userInfo[property][lang] = this.state[key];
    });
    return userInfo;
  }

  saveUserInfo() {
    this.performSaveUserInfo()
      .then((userInfo) => {
        const newState = { userInfo };
        Object.keys(this.state)
          .filter((key) => key.endsWith('-changed'))
          .forEach((key) => {
            newState[key] = undefined;
          });
        this.setState(newState);
      }).catch((error) => {
        this.showError('errorSaveUserInfo', error)
      });
  }

  createToken(params, callback) {
    this.performCreateToken(params)
      .then(({ token, tokens, bearer }) => {
        this.setState({
          tokens,
        }, () => {
          if (!callback) {
            return;
          }
          callback({
            token,
            bearer,
          });
        });
      }).catch((error) => {
        this.showError('errorCreateToken', error)
        if (!callback) {
          return;
        }
        callback(null);
      });
  }

  deleteToken(ids, callback) {
    this.performDeleteToken(ids)
      .then((tokens) => {
        this.setState({
          tokens,
        }, () => {
          if (!callback) {
            return;
          }
          callback(tokens);
        });
      }).catch((error) => {
        this.showError('errorDeleteToken', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performSaveUserInfo() {
    const userInfo = Object.assign({}, this.state.userInfo || {});
    userInfo.nickname = Object.assign({}, userInfo.nickname || {});
    userInfo.affiliation = Object.assign({}, userInfo.affiliation || {});
    userInfo.contact = Object.assign({}, userInfo.contact || {});
    Object.keys(this.state).filter((key) => key.endsWith('-changed')).forEach((key) => {
      const [ property, lang ] = key.split('-');
      if (this.state[key] === undefined) {
        return;
      }
      userInfo[property][lang] = this.state[key];
    });
    const { firestore, user } = this.props;

    const userInfoDocRef = firestore.collection('userinfo').doc(user.uid);
    const userInfoRef = await userInfoDocRef.get();
    if (userInfoRef.exists) {
      await userInfoDocRef.update(userInfo);
    } else {
      await userInfoDocRef.set(userInfo);
    }
    return userInfo;
  }

  async performCreateToken(params) {
    const { firestore, user } = this.props;
    const created = Date.now();
    const secret = uuidv4();
    const token = await firestore
      .collection('user')
      .doc(user.uid)
      .collection('token')
      .add({
        name: params.name,
        expiresAt: params.expiresIn === 0 ? null : (created + (params.expiresIn || 0)),
        created,
        scope: params.scope,
        deleted: null,
        secret,
      });
    await axios.post(`/api/v1/users/${user.uid}/tokens/${token.id}`);
    const newTokenRef = await firestore
      .collection('user')
      .doc(user.uid)
      .collection('token')
      .doc(token.id)
      .get();
    if (!newTokenRef.exists) {
      throw new Error('Illegal state');
    }
    const { tokenId } = newTokenRef.data();
    const tokens = await this.loadTokens();
    return {
      token,
      tokens,
      bearer: tokenId + ':' + secret,
    };
  }

  async performDeleteToken(ids) {
    const { firestore, user } = this.props;
    const deleted = Date.now();
    await Promise.all(ids.map((tokenId) => firestore
      .collection('user')
      .doc(user.uid)
      .collection('token')
      .doc(tokenId)
      .update({
        deleted,
      })));
    const tokens = await this.loadTokens();
    return tokens;
  }

  disconnectTalkuser(talkuser) {
    this.setState({
      disconnecting: true,
    }, () => {
      this.performDisconnectTalkuser(talkuser, null, null, false)
        .then((talkusers) => {
          this.setState({
            disconnecting: false,
            talkusers,
            disconnectingTalkuser: null,
          });
        })
        .catch((error) => {
          this.showError('errorDisconnectTalkuser', error)
        });
    });
  }

  async performDisconnectTalkuser(talkuser) {
    await this.performActivateTalkuser(talkuser.id, null, null, false);
    return await this.loadTalkusers();
  }

  renderWearable() {
    return (<>
      {this.state.garminEnabled === null && <Loading />}
      {this.state.garminEnabled === false && this.state.garminUserId === null && <p>
          <FormattedMessage id='noSettings'/>
        </p>}
      {(this.state.garminEnabled || this.state.garminUserId) && <Container>
          <p>
            {this.state.garminUserId && <FormattedMessage id='garminConnected' />}
            {!this.state.garminUserId && <FormattedMessage id='garminNotConnected' />}
          </p>
          {this.state.garminUserId && <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({
                disconnectingHealthuser: {
                  data: {
                    type: 'garmin',
                  },
                },
              })}
            >
              <FormattedMessage id='disconnectGarminAuth'/>
            </Button>}
          {!this.state.garminUserId && <Button
              variant="contained"
              color="primary"
              onClick={() => this.startGarminAuth()}
            >
              <FormattedMessage id='startGarminAuth'/>
            </Button>}
        </Container>}
    </>)
  }

  renderTokens() {
    return (<>
      {this.state.tokens === null && <Loading />}
      {this.state.tokens && <Tokens
        editable={true}
        tokens={this.state.tokens}
        onError={this.props.onError}
        onTokenCreate={(params, callback) => this.createToken(params, callback)}
        onTokenDelete={(ids, callback) => this.deleteToken(ids, callback)}
      />}
    </>);
  }

  renderTalkusers() {
    const { classes } = this.props;
    return (<>
      {this.state.talkusers === null && <Loading />}
      {this.state.talkusers && this.state.talkusers.length === 0 && <Container className={classes.talkusers}>
          <FormattedMessage id='settingsNoTalkusers' />
        </Container>}
      {this.state.talkusers && this.state.talkusers.length > 0 && <Container className={classes.talkusers}>
        {this.state.talkusers.map((talkuser) => <Grid key={talkuser.id} container>
          <Grid item sm={10} className={classes.talkuserInfo}>
            <Typography variant="h4" component="h4" className={classes.talkuserName}>
              {talkuser.data.name}
            </Typography>
            {talkuser.data.type === 'line' && <Badge badgeContent='LINE' color='secondary'>
                <QuestionAnswerIcon/>
              </Badge>}
          </Grid>
          <Grid item sm={2} className={classes.talkuserButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ disconnectingTalkuser: talkuser })}
            >
              <DeleteIcon/>
              <FormattedMessage id='settingsDisconnectTalkuser'/>
            </Button>
          </Grid>
        </Grid>)}
      </Container>}
    </>);
  }
  renderUserInfo() {
    const { classes, intl } = this.props;
    const { nickname, affiliation, contact } = this.getCurrentUserInfo();
    return <Grid container>
      <Grid item sm={12}>
        <Typography variant="h4" component="h4" className={classes.sectionHeader}>
          <FormattedMessage id='settingsUserHeader' />
        </Typography>
        <p className={classes.description}>
          <FormattedMessage id='settingsUserDescription' />
        </p>
      </Grid>
      {!validateNickname(nickname) &&
        <Grid item sm={12} className={classes.valuePanel}>
          <Alert severity='error'><FormattedMessage id='settingsUserNoNicknames'/></Alert>
        </Grid>}
      <Grid item sm={12} className={classes.valuePanel}>
        <TextField
          onChange={(event) => this.changeUserProperty('nickname', 'en', event.target.value)}
          defaultValue={(nickname || {}).en || ''}
          label={intl.formatMessage({ id: 'settingsUserNicknameEN' })}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <TextField
          onChange={(event) => this.changeUserProperty('nickname', 'ja', event.target.value)}
          defaultValue={(nickname || {}).ja || ''}
          label={intl.formatMessage({ id: 'settingsUserNicknameJA' })}
          fullWidth
          variant="outlined"
        />
      </Grid>
      {!validateAffiliation(affiliation) &&
        <Grid item sm={12} className={classes.valuePanel}>
          <Alert severity='error'><FormattedMessage id='settingsUserNoAffiliations'/></Alert>
        </Grid>}
      <Grid item sm={12} className={classes.valuePanel}>
        <TextField
          onChange={(event) => this.changeUserProperty('affiliation', 'en', event.target.value)}
          defaultValue={(affiliation || {}).en || ''}
          label={intl.formatMessage({ id: 'settingsUserAffiliationEN' })}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <TextField
          onChange={(event) => this.changeUserProperty('affiliation', 'ja', event.target.value)}
          defaultValue={(affiliation || {}).ja || ''}
          label={intl.formatMessage({ id: 'settingsUserAffiliationJA' })}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <Typography variant="h5" component="h5" className={classes.sectionSubHeader}>
          <FormattedMessage id='settingsUserContact' />
        </Typography>
        {!validateContact(contact) &&
          <Alert severity='error'><FormattedMessage id='settingsUserNoContacts'/></Alert>}
        <FormControlLabel
          control={
            <Checkbox
              checked={(contact || {}).mail}
              onChange={(event) => this.changeUserProperty('contact', 'mail', event.target.checked ? this.props.user.email : null)}
              color="primary"
            />
          }
          label={intl.formatMessage({ id: 'settingsUserContactMail' }) + (this.props.user || {}).email}
        />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <TextField
          error={(contact || {}).twitter && !validateTwitter((contact || {}).twitter || '')}
          onChange={(event) => this.changeUserProperty('contact', 'twitter', event.target.value)}
          defaultValue={(contact || {}).twitter || ''}
          label={intl.formatMessage({ id: 'settingsUserContactTwitter' })}
          placeholder={intl.formatMessage({ id: 'placeholderUserContactTwitter' })}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <TextField
          error={(contact || {}).facebook && !validateFacebook((contact || {}).facebook || '')}
          onChange={(event) => this.changeUserProperty('contact', 'facebook', event.target.value)}
          defaultValue={(contact || {}).facebook || ''}
          label={intl.formatMessage({ id: 'settingsUserContactFacebook' })}
          placeholder={intl.formatMessage({ id: 'placeholderUserContactFacebook' })}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <Typography variant="h5" component="h5" className={classes.sectionSubHeader}>
          <FormattedMessage id='settingsAuthorExample' />
        </Typography>
        <RadioGroup row aria-label="position" name="position" defaultValue="en"
          className={classes.avatarLangs}
          onChange={(event) => this.setState({ avatarLang: event.target.value })}>
          <FormControlLabel
            value="en"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'langEN' })}
          />
          <FormControlLabel
            value="ja"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'langJA' })}
          />
        </RadioGroup>
      </Grid>
      <Grid item sm={12}>
        <Author lang={this.state.avatarLang || 'en'} userInfo={this.getCurrentUserInfo()} />
      </Grid>
      <Grid item sm={12} className={classes.valuePanel}>
        <Button
          variant="contained"
          color="primary"
          disabled={!this.isDirty() || !validateNickname(nickname) || !validateAffiliation(affiliation) || !validateContact(contact)}
          onClick={() => this.saveUserInfo()}
        >
          <FormattedMessage id='saveUserInfo'/>
        </Button>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h4" component="h4" className={classes.sectionHeader}>
          <FormattedMessage id='settingsTalkuserHeader' />
        </Typography>
      </Grid>
      <Grid item sm={12}>
        {this.renderTalkusers()}
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h4" component="h4" className={classes.sectionHeader}>
          <FormattedMessage id='settingsTokenHeader' />
        </Typography>
      </Grid>
      <Grid item sm={12}>
        {this.renderTokens()}
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h4" component="h4" className={classes.sectionHeader}>
          <FormattedMessage id='settingsOptionalHeader' />
        </Typography>
      </Grid>
      <Grid item sm={12}>
        {this.renderWearable()}
      </Grid>
    </Grid>
  }

  getTalkuserMessageValues(talkuser) {
    if (!talkuser) {
      return {
        type: '',
        name: '',
      };
    }
    const TYPES = {
      line: 'LINE',
    };
    const { type, name } = talkuser.data;
    return {
      type: TYPES[type],
      name,
    };
  }

  renderDialogs() {
    const {
      activatedTalkuser, activatedHealthuser, disconnectingTalkuser, disconnectingHealthuser,
    } = this.state;
    return <>
      <Dialog
        open={activatedTalkuser !== null}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle>
          <FormattedMessage id='activatedTalkuserTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='activatedTalkuserDescription' values={this.getTalkuserMessageValues(activatedTalkuser)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ activatedTalkuser: null })} color="secondary">
            <FormattedMessage id='activatedTalkuserConfirm' />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={activatedHealthuser !== null}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle>
          <FormattedMessage id='activatedHealthuserTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='activatedHealthuserDescription' values={this.getHealthuserMessageValues(activatedHealthuser)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ activatedHealthuser: null })} color="secondary">
            <FormattedMessage id='activatedHealthuserConfirm' />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={disconnectingTalkuser !== null}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle>
          <FormattedMessage id='disconnectTalkuserConfirmTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='disconnectTalkuserConfirmDescription' values={this.getTalkuserMessageValues(disconnectingTalkuser)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ disconnectingTalkuser: null })} color="primary" disabled={this.state.disconnecting}>
            <FormattedMessage id='disconnectTalkuserConfirmCancel' />
          </Button>
          <Button onClick={() => this.disconnectTalkuser(disconnectingTalkuser)} color="secondary" disabled={this.state.disconnecting}>
            {this.state.disconnecting && <ClipLoader />}
            <FormattedMessage id='disconnectTalkuserConfirmOK' />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={disconnectingHealthuser !== null}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle>
          <FormattedMessage id='disconnectHealthuserConfirmTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='disconnectHealthuserConfirmDescription' values={this.getHealthuserMessageValues(disconnectingHealthuser)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ disconnectingHealthuser: null })} color="primary" disabled={this.state.disconnecting}>
            <FormattedMessage id='disconnectHealthuserConfirmCancel' />
          </Button>
          <Button onClick={() => this.disconnectHealthuser(disconnectingHealthuser)} color="secondary" disabled={this.state.disconnecting}>
            {this.state.disconnecting && <ClipLoader />}
            <FormattedMessage id='disconnectHealthuserConfirmOK' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  }

  render() {
    const { classes } = this.props;
    return <Container className={classes.root}>
      {!this.state.userInfo && <Loading />}
      <BeforeUnloadComponent
        blockRoute={this.isDirty()}
      >
        {this.state.userInfo && this.renderUserInfo()}
      </BeforeUnloadComponent>
      {this.renderDialogs()}
    </Container>;
  }
}

export default withStyles(styles)(injectIntl(Settings));
