import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import firebase from 'firebase/app';
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';

import Task from "./Task";
import { loadTask, mergeElements } from './TaskPanel';
import Loading from "./Loading";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
  },
  deleted: {
    marginTop: theme.spacing(10),
    padding: '1em',
  },
});

class AnonymousTaskPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: undefined,
      forceLogin: null,
    };
  }

  componentDidMount() {
    this.ensureTaskLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureTaskLoaded();
    if (prevState.forceLogin === this.state.forceLogin) {
      return;
    }
    if (this.state.forceLogin) {
      this.signIn(this.state.forceLogin);
    }
  }

  signIn(providerName) {
    if (providerName !== 'google') {
      throw new Error(`Unknown auth provider: ${providerName}`);
    }
    const auth = this.props.auth || firebase.auth;
    const provider = new auth.GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    if (!this.props.onSignIn) {
      return
    }
    this.props.onSignIn(provider)
  }

  showError(message, error) {
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  getForceLogin(task, baseTask) {
    if (this.props.disableForceLogin) {
      return null;
    }
    if (!task) {
      return null;
    }
    const param = mergeElements(task.data.param, baseTask ? baseTask.data.param : null, (elem) => elem.name);
    const forceLoginParam = param.filter((elem) => elem.name === 'GOEMON_FORCE_LOGIN');
    if (forceLoginParam.length === 0) {
      return null;
    }
    const { value } = forceLoginParam[0];
    if (!value) {
      return null;
    }
    const nvalue = value.trim();
    if (nvalue.length === 0) {
      return null;
    }
    return nvalue;
  }

  ensureTaskLoaded() {
    if (this.state.task !== undefined) {
      return;
    }
    if (!this.props.match || !this.props.firestore) {
      return;
    }
    setTimeout(() => {
      this.setState({
        task: null,
      }, () => {
        const { firestore } = this.props;
        loadTask(null, firestore, this.props.match.params.taskId)
          .then((loaded) => {
            this.setState({
              task: loaded.task,
              taskContext: loaded.taskContext,
              userTask: loaded.userTask,
              originalUserTask: loaded.userTask,
              taskFiles: loaded.taskFiles,
              creatorUserInfo: loaded.creatorUserInfo,
              forceLogin: this.getForceLogin(loaded.task, loaded.baseTask),
            });
            if (!this.props.onTaskLoad) {
              return;
            }
            this.props.onTaskLoad({
              importable: false,
            });
          }).catch((error) => {
            this.showError('errorLoadTask', error)
          });
      })
    }, 10);
  }

  render() {
    const { classes, children } = this.props;
    const { task, forceLogin } = this.state;
    if (task && task.data.trash) {
      return <Paper className={classes.deleted}>
        <FormattedMessage id='alreadyDeleted' />
      </Paper>;
    }
    if (forceLogin) {
      return <Loading/>;
    }
    return (
      <div className={classes.root}>
        {!task && <Loading />}
        {task &&
          <Task
            context={this.state.taskContext}
            title={task.data.title}
            script={task.data.script}
            description={task.data.description}
            files={this.state.taskFiles}
            editable={false}
            creatorLogType={this.state.creatorLogType === undefined ? task.data.creatorLogType : this.state.creatorLogType}
            creatorUserInfo={(this.state.showCreator === undefined ? task.data.creatorId : this.state.showCreator) ? this.state.creatorUserInfo : null}
          >
            {children}
          </Task>}
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(injectIntl(AnonymousTaskPanel)))
