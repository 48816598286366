import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Loading from './Loading';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stepheader: {
    fontSize: '1.5em',
  },
  systemFriendRequest: {
    display: 'block',
    fontWeight: 'bold',
  },
  buttons: {
    textAlign: 'right',
  },
  image: {
    textAlign: 'center',
  },
  appstore: {
    display: 'flex',
    '& > *': {
      margin: 'auto',
    },
  },
  friendRequestError: {
    color: 'red',
  },
});

class PLRPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phase: null,
      friendRequest: null,
      systemFriendRequest: undefined,
    }
  }

  componentDidMount() {
    this.ensurePLRLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensurePLRLoaded();
  }

  showError(message, error) {
    if (!this.props.onError) {
      return;
    }
    this.props.onError(message, error);
  }

  ensurePLRLoaded() {
    if (this.state.systemFriendRequest !== undefined) {
      return;
    }
    if (!this.props.firestore || !this.props.user) {
      return;
    }
    setTimeout(() => {
      this.setState({
        systemFriendRequest: null,
      }, () => {
        this.loadPLR()
          .then((loaded) => {
            this.setState({
              phase: 'intro',
              systemFriendRequest: loaded.systemFriendRequest,
              systemFriendRequestHttp: loaded.systemFriendRequestHttp,
            });
          }).catch((error) => {
            this.showError('errorLoadPLR', error)
          });
      })
    }, 10);
  }

  async loadPLR() {
    const { firestore } = this.props;
    const plrData = await firestore.collection('plr').doc('everyone').get();
    if (!plrData.exists) {
      throw new Error('PLR data not found');
    }
    const friendRequest = plrData.data().friendRequest;
    const systemFriendRequest = friendRequest ?
      (friendRequest.rawFriendRequest || JSON.parse(friendRequest).rawFriendRequest) : null;
    const systemFriendRequestHttp = friendRequest ?
      (friendRequest.httpWrappedURI || JSON.parse(friendRequest).httpWrappedURI) : null;
    return { systemFriendRequest, systemFriendRequestHttp };
  }

  friendRequestChange(event) {
    this.setState({
      friendRequest: event.target.value,
    })
  }

  addFriend() {
    if (!this.props.onFriendAdd) {
      return;
    }
    this.props.onFriendAdd()
  }

  renderIntroduction() {
    const { classes } = this.props;
    return <>
        <Typography variant="h4" component="h4" className={classes.stepheader}>
          <FormattedMessage id='plrStartIntroduction' />
        </Typography>
        <Container>
          <p>
            <FormattedMessage id='plrStartNeedPLR' />
          </p>
          <div className={classes.image}>
            <img src='/images/plr-intro.jpg' width='80%' alt='Introduction'/>
          </div>
          <p>
            <FormattedMessage id='plrStartInstallPLR' />
          </p>
        </Container>
      </>
  }

  renderInstallPersonary() {
    const { classes } = this.props;
    return <>
        <Typography variant="h4" component="h4" className={classes.stepheader}>
          <FormattedMessage id='plrStep0InstallPLR' />
        </Typography>
        <Container>
          <p>
            <FormattedMessage id='plrStep0InstallPersonary' />
          </p>
          <div className={classes.appstore}>
            <a href='https://apps.apple.com/jp/app/personary-2021/id1551318386'>
              <img alt='App Store' src='/images/appstore.svg' />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.assemblogue.plr.app.personary'>
              <img alt='Google Play' src='/images/playstore.png' width='135' height='40' />
            </a>
          </div>
          <p>
            <FormattedMessage id='plrStep0SettingPersonary' values={{
              email: <strong>{this.props.user.email}</strong>
            }} />
          </p>
          <p>
            <FormattedMessage id='plrStep0AboutPersonary' />
          </p>
          <p>
            <a
              href='https://scrapbox.io/cogtask-me/%E6%97%A7Personary%E3%81%8B%E3%82%89%E6%96%B0Personary%E3%81%B8%E3%81%AE%E7%A7%BB%E8%A1%8C'
              target='_blank'
              rel="noreferrer"
            >
              <FormattedMessage id='plrStep0CautionForOldVersion' />
            </a>
          </p>
        </Container>
      </>
  }

  renderSystemFriendRequest() {
    const { classes } = this.props;
    return <>
        <Typography variant="h4" component="h4" className={classes.stepheader}>
          <FormattedMessage id='plrStep1RegisterFriend' />
        </Typography>
        <Container>
          <p>
            <FormattedMessage id='plrStep1QRCode' />
          </p>
          <div>
            <QRCode value={this.state.systemFriendRequestHttp} />
          </div>
          <p>
            <FormattedMessage id='plrStep1RequestString' />
            <Typography variant="caption" component="caption" className={classes.systemFriendRequest}>
              {this.state.systemFriendRequest}
              <CopyToClipboard
                text={this.state.systemFriendRequest}
                onCopy={() => this.setState({ frCopied: true })}
              >
                <FileCopyIcon />
              </CopyToClipboard>
            </Typography>
            {this.state.frCopied && <div className={classes.friendRequestCopied}>
                <FormattedMessage id='plrFriendRequestCopied' />
              </div>}
          </p>
          <p>
            <FormattedMessage id='plrStep1PleaseRegister' />
          </p>
        </Container>
      </>
  }

  render() {
    const { classes } = this.props;
    return <Container className={classes.root}>
      {this.state.phase === null &&
        <Loading />}
      {this.state.phase === 'intro' &&
        this.renderIntroduction()}
      {this.state.phase === 'install' &&
        this.renderInstallPersonary()}
      {this.state.phase === 'system_fr' &&
        this.renderSystemFriendRequest()}
      <Container className={classes.buttons}>
        {this.state.phase === 'intro' && <Button
            variant="contained"
            color="secondary"
            data-test-plr-intro-next
            onClick={e => this.setState({
              phase: 'install',
            })}>
            <FormattedMessage id='next' />
          </Button>}
        {this.state.phase === 'install' && <Button
            variant="contained"
            color="secondary"
            data-test-plr-intro-next
            onClick={e => this.setState({
              phase: 'system_fr',
            })}>
            <FormattedMessage id='next' />
          </Button>}
        {this.state.phase === 'system_fr' && <Button
            variant="contained"
            color="secondary"
            data-test-plr-next
            onClick={e => this.addFriend()}>
            <FormattedMessage id='register' />
          </Button>}
      </Container>
    </Container>;
  }
}

export default withStyles(styles)(injectIntl(PLRPanel));
