import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import ClipLoader from "react-spinners/ClipLoader";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = (theme) => ({
  urlCopied: {
    fontSize: '0.9em',
    marginTop: '-0.2em',
  },
});


class ShareDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      performing: false,
      shares: null,
      lastShares: null,
    };
  }

  close() {
    if (!this.props.onClose) {
      return;
    }
    this.props.onClose();
  }

  isDirty() {
    if (!this.props.userTask.data.shareId) {
      return true;
    }
    const shareType = this.state.type || this.getShareType(this.props.userTask) || 'nothing';
    if (shareType === 'sharesWithLink') {
      return !this.props.userTask.data.sharesWithLink;
    }
    if (shareType === 'sharesWithMembers') {
      const lastShares = this.props.userTask.data.shares || [];
      const shares = this.state.shares === null ? (this.props.userTask.data.shares || []) : this.state.shares;
      if (lastShares.length !== shares.length) {
        return true;
      }
      return !lastShares.every((lastShare, index) => lastShare === shares[index]);
    }
    const lastShares = this.props.userTask.data.shares;
    if (lastShares && lastShares.length > 0) {
      return true;
    }
    return this.props.userTask.data.sharesWithLink;
  }

  getNewProperties() {
    const shareType = this.state.type || this.getShareType(this.props.userTask) || 'nothing';
    const lastShares = this.state.lastShares === null ? (this.props.userTask.data.lastShares || []) : this.state.lastShares;
    if (shareType === 'sharesWithLink') {
      return {
        sharesWithLink: true,
        shares: null,
        lastShares,
      };
    }
    if (shareType === 'sharesWithMembers') {
      const shares = (this.state.shares === null ? (this.props.userTask.data.shares || []) : this.state.shares)
        .map((share) => share.trim());
      return {
        sharesWithLink: false,
        shares,
        lastShares,
      };
    }
    return {
      sharesWithLink: false,
      shares: null,
      lastShares,
    };
  }

  ensureShareId() {
    if (this.state.performing) {
      setTimeout(() => this.ensureShareId(), 1000);
      return;
    }
    if (!this.isDirty()) {
      return;
    }
    if (!this.props.onSharesUpdate) {
      return;
    }
    this.setState({
      performing: true,
    }, () => {
      this.props.onSharesUpdate(this.getNewProperties(), () => {
        this.setState({
          performing: false,
        });
      });
    });
  }

  getShareType(userTask) {
    if (userTask.data.sharesWithLink) {
      return 'sharesWithLink';
    }
    if (userTask.data.shares !== null && userTask.data.shares !== undefined)  {
      return 'sharesWithMembers';
    }
    return 'nothing';
  }

  getShareUrl() {
    const { shareId } = this.props.userTask.data;
    return `${window.location.origin}/s/${shareId}`;
  }

  setShareType(value) {
    const props = { type: value };
    const shares = this.state.shares === null ? (this.props.userTask.data.shares || []) : this.state.shares;
    const lastShares = this.state.lastShares === null ? (this.props.userTask.data.lastShares || []) : this.state.lastShares;
    if (value === 'sharesWithMembers') {
      props.shares = lastShares || [];
    } else {
      props.lastShares = shares || [];
    }
    this.setState(props, () => this.ensureShareId())
  }

  renderContent() {
    const { intl, classes } = this.props;
    if (!this.props.userTask || !this.props.userTask.data) {
      return <ClipLoader />
    }
    const shares = this.state.shares === null ? (this.props.userTask.data.shares || []) : this.state.shares;
    const shareType = this.state.type || this.getShareType(this.props.userTask) || 'nothing';
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel htmlFor="share-type">
          <FormattedMessage id='shareType' />
        </InputLabel>
        <Select
          native
          value={shareType}
          onChange={(event) => this.setShareType(event.target.value)}
          inputProps={{
            id: 'share-type',
          }}
        >
          <option value='nothing'>{intl.formatMessage({ id: 'sharesDisabled' })}</option>
          <option value={'sharesWithMembers'}>{intl.formatMessage({ id: 'sharesWithMembers' })}</option>
          <option value={'sharesWithLink'}>{intl.formatMessage({ id: 'sharesWithLink' })}</option>
        </Select>
      </Grid>
      {shareType === 'sharesWithMembers' && <Grid item xs={12}>
        <Autocomplete
           multiple
           freeSolo={true}
           size='medium'
           options={this.props.emailCandidates || []}
           getOptionLabel={(option) => option}
           defaultValue={shares}
           onChange={(event, value) => this.setState({ shares: value }, () => this.ensureShareId())}
           renderInput={(params) => (
             <TextField
               {...params}
               variant='standard'
               label={intl.formatMessage({ id: 'shareMembers' })}
               placeholder={intl.formatMessage({ id: 'shareMembers' })}
             />
           )}
         />
      </Grid>}
      {shareType !== 'nothing' && (this.props.userTask.data.shareId || this.state.performing) && <>
        <Grid item xs={9}>
          {this.state.performing && <>
              <ClipLoader />
              <FormattedMessage id='requestingShareId' />
            </>}
          {this.props.userTask.data.shareId && <TextField
            readOnly
            placeholder={intl.formatMessage({ id: 'shareURL' })}
            defaultValue={this.getShareUrl()}
            fullWidth
          />}
        </Grid>
        <Grid item xs={3}>
          <CopyToClipboard text={this.getShareUrl()} onCopy={() => this.setState({ copied: true })}>
            <FileCopyIcon />
          </CopyToClipboard>
        </Grid>
        {this.state.copied && <Grid item xs={12}>
          <div className={classes.urlCopied}>
            <FormattedMessage id='urlCopied' />
          </div>
        </Grid>}
      </>}
    </Grid>;
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.close()}
        disableBackdropClick={this.state.performing}
        disableEscapeKeyDown={this.state.performing}
      >
        <DialogTitle>
          <FormattedMessage id='shareTitle' />
        </DialogTitle>
        <DialogContent>
          {this.renderContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.close()} color="primary" disabled={this.state.performing}>
            <FormattedMessage id='shareClose' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(injectIntl(ShareDialog))
