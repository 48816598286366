import { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import ClipLoader from "react-spinners/ClipLoader";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({});

class DeleteConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = { performing: false };
  }

  cancel() {
    if (!this.props.onCancel) {
      return;
    }
    this.props.onCancel();
  }

  perform() {
    if (!this.props.onPerform) {
      return;
    }
    this.setState({
      performing: true,
    }, () => {
      this.props.onPerform(() => {
        this.setState({
          performing: false,
        });
      });
    });
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.cancel()}
        disableBackdropClick={this.state.performing}
        disableEscapeKeyDown={this.state.performing}
      >
        <DialogTitle>
          <FormattedMessage id='deleteConfirmTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='deleteConfirmContent' values={{ title: this.props.title }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.cancel()} color="primary" disabled={this.state.performing}>
            <FormattedMessage id='deleteConfirmCancel' />
          </Button>
          <Button onClick={() => this.perform()} color="secondary" disabled={this.state.performing}>
            {this.state.performing && <ClipLoader />}
            <FormattedMessage id='deleteConfirmOK' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(DeleteConfirmDialog)
