import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from 'react-player'

const styles = (theme) => ({
  VideoAgreementRoot: {
    '& > div': {
      margin: 'auto',
    },
  },
  VideoAgreementPlayerContainer: {
    position: 'relative',
    paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
  },
  VideoAgreementPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: '1px solid #444',
  },
  VideoAgreementAgreed: {
    margin: 'auto',
  },
  VideoAgreementControls: {
    padding: '1em',
  },
  VideoAgreementControlsRow: {
    justifyContent: 'center',
  },
});

class VideoAgreement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      played: false,
    }
  }

  confirmRequirement() {
    if (!this.props.onAgree) {
      return;
    }
    this.props.onAgree()
  }

  render() {
    const { requirement, confirmed, classes } = this.props
    return <div className={classes.VideoAgreementRoot}>
      <div className={classes.VideoAgreementPlayerContainer}>
        <ReactPlayer
          data-test-video-agreement-player
          className={classes.VideoAgreementPlayer}
          url={requirement.src}
          controls={true}
          onPlay={() => this.setState({ played: true, })}
          width='100%'
          height='100%'
        />
      </div>
      <div className={classes.VideoAgreementControls}>
        {confirmed && <div className={classes.VideoAgreementAgreed}>
            <FormattedMessage id='agreementRequirementConfirmed' />
          </div>}
        {!confirmed && <Grid container className={classes.VideoAgreementControlsRow}>
            <Grid item sm={10}>
              <FormattedMessage id='agreementVideoDescription' />
            </Grid>
            <Grid item sm='auto'>
              <Button
                data-test-video-agreement-confirm
                variant='contained'
                color='primary'
                disabled={!this.state.played}
                onClick={() => this.confirmRequirement()}
              >
                <FormattedMessage id='agreementRequirementConfirm' />
              </Button>
            </Grid>
          </Grid>}
      </div>
    </div>
  }
}

export default withStyles(styles)(VideoAgreement);
