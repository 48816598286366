import React, { Component } from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Document, Page } from 'react-pdf'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const styles = (theme) => ({
  PDFAgreementRoot: {
    '& canvas': {
      margin: 'auto',
    },
  },
  PDFAgreementDocument: {
    '& > canvas': {
      border: '1px solid #444',
    },
  },
  PDFAgreementAgreed: {
    margin: 'auto',
  },
  PDFAgreementControls: {
    padding: '1em',
  },
  PDFAgreementControlsRow: {
    justifyContent: 'center',
  },
  PDFPageControl: {
    margin: '0.2em',
    fontSize: '1.5em',
  },
  PDFAgreementPages: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2em',
  },
  PDFPagePrevious: {
    marginLeft: 'auto',
  },
  PDFPageNext: {
    marginRight: 'auto',
  },
});

class PDFAgreement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pageNumber: null,
      numPages: null,
      pageWidth: 100,
    }
    this.rootRef = React.createRef()
  }

  confirmRequirement() {
    if (!this.props.onAgree) {
      return;
    }
    this.props.onAgree()
  }

  render() {
    const { requirement, confirmed, classes } = this.props
    return <div className={classes.PDFAgreementRoot} ref={this.rootRef}>
      <Document
        file={requirement.src}
        onLoadSuccess={({ numPages }) => this.setState({
          numPages,
          pageNumber: 1,
          pageWidth: this.rootRef.current.clientWidth,
        })}
      >
        <Page
          className={classes.PDFAgreementDocument}
          pageNumber={this.state.pageNumber}
          width={this.state.pageWidth}
        />
      </Document>
      {this.state.numPages && <div className={classes.PDFAgreementPages}>
          <ArrowBackIosIcon
            className={clsx(classes.PDFPageControl, classes.PDFPagePrevious)}
            onClick={() => this.setState({
              pageNumber: Math.max(this.state.pageNumber - 1, 1),
            })}
          />
          {this.state.pageNumber} / {this.state.numPages}
          <ArrowForwardIosIcon
            className={clsx(classes.PDFPageControl, classes.PDFPageNext)}
            onClick={() => this.setState({
              pageNumber: Math.min(this.state.pageNumber + 1, this.state.numPages),
            })}
          />
        </div>}
      <div className={classes.PDFAgreementControls}>
        {confirmed && <div className={classes.PDFAgreementAgreed}>
            <FormattedMessage id='agreementRequirementConfirmed' />
          </div>}
        {!confirmed && <Grid container className={classes.PDFAgreementControlsRow}>
            <Grid item sm={10}>
              <FormattedMessage id='agreementPDFDescription' />
            </Grid>
            <Grid item sm='auto'>
              <Button
                data-test-pdf-agreement-confirm
                variant='contained'
                color='primary'
                onClick={() => this.confirmRequirement()}
              >
                <FormattedMessage id='agreementRequirementConfirm' />
              </Button>
            </Grid>
          </Grid>}
      </div>
    </div>
  }
}

export default withStyles(styles)(PDFAgreement);
