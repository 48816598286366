import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
  root: {
    overflowY: 'clip',
  },
  content: {
    textAlign: 'center',
    color: '#000',
    marginTop: '1em',
  },
  description: {
    fontSize: '1em',
    marginTop: 0,
    marginBottom: '0.5em',
  },
  step: {
    marginTop: '0.5em',
    fontSize: '1.4em',
    color: '#000',
  },
  qrcode: {
    width: '40vmin',
    height: '40vmin',
  },
  code: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  codeButton: {
    margin: '8px',
  },
});

class MessagingRequestingDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      code: null,
      sending: false,
      codeNotFound: false,
    }
  }

  cancel() {
    if (!this.props.onCancel) {
      return;
    }
    this.props.onCancel();
  }

  renderLineBase() {
    const { classes } = this.props;
    return <>
      <p className={classes.description}>
        <FormattedMessage id='messagingRequestContent' values={{ type: this.getDisplayableType() }} />
        <FormattedMessage id='messagingLineRequest' />
      </p>
      <Container className={classes.content}>
        <Button
          variant="contained"
          color='primary'
          href='https://line.me/R/ti/p/@051ejcoi'
          target='_blank'
          rel="noreferrer"
        >
          <FormattedMessage id='messagingAddFriendLink' />
        </Button>
      </Container>
      <Container className={classes.content}>
        <FormattedMessage id='messagingAddFriendOr' /><br/>
        <img src='/images/line-qr.png' alt='GO-E-MON bot' className={classes.qrcode} />
      </Container>
    </>;
  }

  changeCode(event) {
    const value = event.target.value
    this.setState({
      code: value,
    })
  }

  keyPressForCode(event) {
    this.changeCode(event)
    if(event.key === 'Enter' && this.isCodeFilled()){
      this.sendCode();
    }
  }

  isCodeFilled() {
    if (!this.state.code) {
      return false;
    }
    const removed = this.state.code.replace(/[-\s]/g, '');
    return removed.match(/^[0-9a-zA-Z]{10}$/);
  }

  sendCode() {
    this.setState({
      sending: true,
    });
    const code = this.state.code.replace(/[-\s]/g, '').toUpperCase();
    if (!this.props.onInputCode) {
      return;
    }
    this.props.onInputCode(code, (talkuser, error) => {
      if (error) {
        this.showError('errorInputTalkuserCode', error);
      } else if (talkuser === null) {
        this.setState({
          sending: false,
          codeNotFound: true,
        });
        return;
      }
      this.setState({
        sending: false,
        codeNotFound: false,
      });
    });
  }

  showError(message, error) {
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  renderLine() {
    const { classes, intl } = this.props;
    return <>
      {this.state.page === 0 && <>
        <Typography variant="h4" component="h4" className={classes.step}>
          <FormattedMessage id='messagingLineRequest1' />
        </Typography>
        {this.renderLineBase()}
      </>}
      {this.state.page === 1 && <>
        <Typography variant="h4" component="h4" className={classes.step}>
          <FormattedMessage id='messagingLineRequest2' />
        </Typography>
        <p>
          <FormattedMessage id='messagingLineRequest2Description1' />
        </p>
        <Grid
          container
          className={classes.code}
        >
          <Grid item sm={9}>
            <TextField
              variant="outlined"
              id="code-text"
              label={intl.formatMessage({ id: 'messagingLineCodeLabel' })}
              placeholder={intl.formatMessage({ id: 'messagingLineCodePlaceholder' })}
              onBlur={(event) => this.changeCode(event)}
              onChange={(event) => this.changeCode(event)}
              onKeyPress={(event) => this.keyPressForCode(event)}
              error={this.state.codeNotFound}
              helperText={this.state.codeNotFound ? intl.formatMessage({ id: 'messagingMissingCode' }) : null}
              fullWidth
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              variant="contained"
              data-testid="code-submit"
              color="primary"
              className={classes.codeButton}
              disabled={this.state.sending || !this.isCodeFilled()}
              onClick={() => this.sendCode()}
            >
              <FormattedMessage id='submit'/>
            </Button>
          </Grid>
        </Grid>
        <p>
          <FormattedMessage id='messagingLineRequest2Description2' />
        </p>
      </>}
    </>;
  }

  renderLineGroup() {
    const { classes } = this.props;
    return <>
      {this.state.page === 0 && <>
        <Typography variant="h4" component="h4" className={classes.step}>
          <FormattedMessage id='messagingLineGroupRequest1' />
        </Typography>
        {this.renderLineBase()}
      </>}
      {this.state.page === 1 && <>
        <Typography variant="h4" component="h4" className={classes.step}>
          <FormattedMessage id='messagingLineGroupRequest2' />
        </Typography>
        <p>
          <FormattedMessage id='messagingLineGroupRequest2Description' />
        </p>
      </>}
    </>;
  }

  getDisplayableType() {
    if (!this.props.requesting) {
      return null;
    }
    if (this.props.requesting.startsWith('line')) {
      return 'LINE';
    }
    return null;
  }

  renderPageButtons() {
    return <>
      {this.state.page > 0 &&
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.setState({
            page: this.state.page - 1,
          })}
        >
          <FormattedMessage id='previous' />
        </Button>}
      {this.state.page < this.pages() - 1 &&
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.setState({
            page: this.state.page + 1,
          })}
        >
          <FormattedMessage id='next' />
        </Button>}
    </>;
  }

  pages() {
    if (this.props.requesting === 'line' || this.props.requesting === 'linegroup') {
      return 2;
    }
    return 1;
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={!!this.props.requesting}
        onClose={() => this.cancel()}
        className={classes.root}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          <FormattedMessage id='messagingRequestTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.requesting === 'line' && this.renderLine()}
            {this.props.requesting === 'linegroup' && this.renderLineGroup()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.cancel()} color="primary">
            <FormattedMessage id='messagingRequestCancel' />
          </Button>
          {this.pages() > 1 && this.renderPageButtons()}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(injectIntl(MessagingRequestingDialog));
