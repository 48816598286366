import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/styles';
import queryString from 'query-string';
import axios from "axios";

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Loading from './Loading';
import TaskCardList from "./TaskCardList";


const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    textAlign: 'left',
  },
  description: {
    padding: '1em',
  },
  sectionHeader: {
    paddingTop: '1.5em',
    fontSize: '1.5em',
  },
  sectionSubHeader: {
    paddingTop: '0.8em',
    fontSize: '1.25em',
  },
  header: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  valuePanel: {
    padding: '0.5em 0 0.5em 2em',
  },
  avatarLangs: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class TalkgroupSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadStarted: false,
      talkgroup: null,
      token: null,
      activated: false,
      tasks: null,
      selected: null,
    }
  }

  componentDidMount() {
    this.ensureLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureLoaded();
  }

  showError(message, error) {
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  ensureLoaded() {
    if (this.state.loadStarted) {
      return;
    }
    if (!this.props.firestore || !this.props.user || !this.props.match) {
      return;
    }
    setTimeout(() => {
      this.setState({
        loadStarted: true,
      }, () => {
        this.load()
          .then((loaded) => {
            this.setState(loaded);
          }).catch((error) => {
            this.showError('errorLoadTalkgroupSettings', error)
          });
      })
    }, 10);
  }

  async load() {
    const { firestore, user, match } = this.props;
    const query = queryString.parse(window.location.search);
    let token = query['token'] || null;
    let name = query['name'] || null;
    let type = query['type'] || null;
    const talkgroupDoc = firestore
      .collection('user').doc(user.uid)
      .collection('talkgroup').doc(match.params.talkgroupId);
    const talkgroupRef = await talkgroupDoc.get();
    let data = null;
    if (talkgroupRef.exists) {
      data = talkgroupRef.data();
      token = data.token;
      name = data.name;
      type = data.type;
    } else {
      if (!token) {
        throw new Error('No token');
      }
      const created = Date.now();
      data = {
        created,
        updated: created,
        token,
        name,
        type,
      };
      talkgroupDoc.set(data);
    }
    await axios.post(
      `/api/v1/users/${user.uid}/talkgroups/${match.params.talkgroupId}`,
    );
    let tasks = [];
    if (!data.taskId) {
      const contextRefs = await firestore
        .collection('user')
        .doc(user.uid)
        .collection('context')
        .where('talkgroupRequest', '==', type)
        .orderBy('updated', 'desc')
        .get();
      tasks = await this.loadTasks(firestore, contextRefs);
    } else {
      const contextRef = await firestore
        .collection('user')
        .doc(user.uid)
        .collection('context')
        .doc(data.taskId)
        .get();
      tasks = await this.loadTasks(firestore, { docs: [contextRef] });
    }
    const selected = tasks.length > 0 && data.taskId;
    return {
      talkgroup: {
        id: match.params.talkgroupId,
        data,
      },
      tasks,
      selected,
    }
  }

  async loadTasks(firestore, contextRefs) {
    const taskRefs = await Promise.all(contextRefs.docs.map((contextRef) => firestore
      .collection('task')
      .doc(contextRef.id)
      .get()));
    const hasThumbnails = await Promise.all(taskRefs
      .map((taskRef) => this.taskHasThumbnail(firestore, taskRef.id)));
    return taskRefs
      .map((taskRef, taskIndex) => (taskRef.exists ? {
        id: taskRef.id,
        data: Object.assign({
          started: contextRefs.docs[taskIndex].data().started,
        }, taskRef.data()),
        hasThumbnail: hasThumbnails[taskIndex],
      } : null))
      .filter((task) => task !== null)
      .filter((task) => !task.data.trash);
  }

  async taskHasThumbnail(firestore, taskId) {
    if (!taskId) {
      return null;
    }
    const fileRefs = await firestore
      .collection('task')
      .doc(taskId)
      .collection('file')
      .where('name', 'in', ['thumbnail.png', 'thumbnail.jpeg', 'thumbnail.jpg'])
      .get();
    if (fileRefs.empty) {
      return null;
    }
    return `/t/${taskId}/thumbnail.jpg?w=192`;
  }

  activateTalkgroup() {
    this.performActivateTalkgroup()
      .then(() => {
        this.setState({
          activated: true,
        });
      }).catch((error) => {
        this.showError('errorActivateTalkgroup', error)
      });
  }

  async performActivateTalkgroup() {
    const { firestore, user } = this.props;
    const { talkgroup, selected } = this.state;
    await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(selected)
      .update({
        updated: Date.now(),
        talkgroupRequest: null,
        talkgroupId: talkgroup.id,
      });
    await firestore
      .collection('user').doc(user.uid)
      .collection('talkgroup').doc(talkgroup.id)
      .update({
        updated: Date.now(),
        taskId: selected,
      });
    await axios.post(`/api/v1/users/${user.uid}/talkgroups/${this.state.talkgroup.id}`);
  }

  selectTask(task) {
    this.setState({
      selected: task.id,
    });
  }

  renderSettings() {
    const { classes } = this.props;
    const { talkgroup, tasks, selected } = this.state;
    return <Grid container>
      <Grid item sm={12}>
        <Typography variant="h4" component="h4" className={classes.sectionHeader}>
          <FormattedMessage id='talkgroupSettings' />
        </Typography>
        <p className={classes.description}>
          <FormattedMessage id='settingsTalkgroupDescription' values={{ name: talkgroup.data.name }} />
        </p>
        {tasks !== null && tasks.length > 0 && <>
            <TaskCardList
              selectable={true}
              selected={selected}
              tasks={tasks}
              onTaskSelected={(task) => this.selectTask(task)}
              noTasksMessage='noMyTasks'
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.activateTalkgroup()}
                disabled={!selected || talkgroup.data.taskId === selected}
              >
                <FormattedMessage id='activateTalkgroup'/>
            </Button>
          </>}
      </Grid>
    </Grid>
  }

  render() {
    const { classes } = this.props;
    return <Container className={classes.root}>
      {!this.state.talkgroup && <Loading />}
      {this.state.talkgroup && this.renderSettings()}
    </Container>;
  }
}

export default withStyles(styles)(withRouter(injectIntl(TalkgroupSettings)));
