import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
  root: {
    overflowY: 'clip',
  },
  content: {
    textAlign: 'center',
    color: '#000',
    marginTop: '1em',
  },
  description: {
    fontSize: '1em',
    marginTop: 0,
    marginBottom: '0.5em',
  },
  step: {
    marginTop: '0.5em',
    fontSize: '1.4em',
    color: '#000',
  },
  qrcode: {
    width: '40vmin',
    height: '40vmin',
  },
});

class HealthAccessDialog extends Component {
  close() {
    if (!this.props.onClose) {
      return;
    }
    this.props.onClose();
  }

  render() {
    const { classes, showAccess, intl } = this.props;
    return (
      <Dialog
        onClose={() => this.close()}
        open={!!showAccess}
        className={classes.root}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          <FormattedMessage id='healthAccessTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {showAccess && showAccess(intl)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.close()} color="primary">
            <FormattedMessage id='healthAccessOK' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(injectIntl(HealthAccessDialog))
