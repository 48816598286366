
class UserStorage {
  constructor(props) {
    this.onUserStorageSave = props.onUserStorageSave;
    const { storage } = props.context || null;
    this.values = storage ? JSON.parse(storage) : {};
  }

  get length() {
    return Object.keys(this.values).length;
  }

  key(index) {
    const keys = Object.keys(this.values);
    keys.sort();
    return index < keys.length ? keys[index] : null;
  }

  setItem(keyName, keyValue, callback) {
    this.values[keyName] = keyValue;
    this.save(callback);
  }

  getItem(keyName) {
    const keys = Object.keys(this.values);
    if (!keys.includes(keyName)) {
      return null;
    }
    return this.values[keyName];
  }

  removeItem(keyName, callback) {
    const keys = Object.keys(this.values);
    if (!keys.includes(keyName)) {
      return;
    }
    delete this.values[keyName];
    this.save(callback);
  }

  clear(callback) {
    this.values = {};
    this.save(callback);
  }

  save(callback) {
    if (!this.onUserStorageSave) {
      throw new Error('UserStorage is not supported')
    }
    this.onUserStorageSave(JSON.stringify(this.values), callback);
  }
}

export default UserStorage;